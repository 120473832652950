* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

body {
  background: #f5f5f5 !important;
}

header.header_main_Mobile {
  display: none;
}

header.header_main .container,
header.header_main .row>* {
  padding: 0;
}

section.KABC-ah {
  width: 100%;
  height: 295px;
  background-image: url(../img/BG.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 90px;
}

h1,
h2,
h3,
h4,
h5,
p,
a {
  margin: 0px !important;
}

.container-ah {
  width: 100%;
  max-width: 1270px;
  margin: 0 auto;
}

.main-KABC-ah {
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 55px 40px 50px 60px;
}


/* .hello-row.row {
  width: 100%;
}

.hello-col-1.col-lg-12 {
  width: 100%;
}

form.hello-form-1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  height: 375px;
  justify-content: center;
  padding: 0px 70px;
}

.StripeElement.StripeElement--empty {
  width: 100%;
} */

form.hello-form-1 button {}

form.hello-form-1 button {
  padding: 10px 100px;
  background-color: #a6d736;
  border: 1px solid #99c536;
  border-radius: 10px;
  color: white;
}

form.hello-form-1 input {}



.form-control {
  border: none;
}



input.InputElement.is-empty.Input.Input--empty {
  color: #000;
}

input.InputElement.is-empty.Input.Input--empty {
  padding: 2px;
  border-bottom: 1px solid #bdb0b0;
  width: 100%;
}


/* .strpe {
  width: 70%;
  height: 300px;
  background-color: white;
  padding: 40px;
  position: absolute;
  z-index: 999999;
  border: 1px solid green;
  border-radius: 20px;
  top: 25%;
} */

.notapproved {
  font-weight: bold;
  width: 80%;
  display: flex;
  justify-content: center;
  background: #89c400;
  padding: 30px;
  border-radius: 10px;
  color: white;
}

.assistant-news-director-box.hello-balu {
  max-height: 100%;
  min-height: 100%;
  width: 96%;
}

.KABC-box-ah {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.KABC-part-1-ah {
  width: 43%;
  display: flex;
  align-items: center;
  gap: 50px;
}

.KABC-logo-ah {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.KABC-logo-ah img {
  width: 100%;
}

.KABC-tital-ah span {
  display: flex;
  align-items: center;
  gap: 10px;
}

span.See select {
  padding: 10px;
  border: 1px solid hsl(207.5deg 65.75% 28.63%);
  border-radius: 10px;
  background: #194d79;
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  /* 206.25% */
}

.KABC-tital-ah span h2 {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  /* 101.667% */
}

.KABC-tital-ah p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 118.182% */
  display: flex;
  align-items: center;
  gap: 14px;
}

.KABC-tital-ah span p {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.KABC-tital-ah {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.employer-inputfield.employerregister-field select {
  background: var(--Gray-6, #f2f2f2);
  border-radius: 10px;
  border: none;
  width: 100%;
  padding: 16px 20px;
}

.KABC-btn-box-ah {
  width: 18%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 14px;
}

span.See {
  width: 100%;
}

span.See button {
  padding: 10px;
  border: 1px solid hsl(207.5deg 65.75% 28.63%);
  border-radius: 10px;
  background: #194d79;
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  /* 206.25% */
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: none !important;
  pointer-events: auto;
  background-color: none !important;
  background-clip: none !important;
  border: none !important;
  border-radius: none !important;
  outline: 0;
}

span.Info {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

span.Info button {
  background: transparent;
  border: none;
  padding: 5px 0px;
}

button.Contact-ah {
  color: #194d79;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border: 2px solid #194d79 !important;
  line-height: 33px;
  /* 206.25% */
  border-radius: 8px;
  padding: 5px 10px !important;
}

span.Info button svg {
  width: 100%;
  height: 100%;
}

section.About-ah {
  width: 100%;
  padding: 99px 0px 29px 0px;
  margin: 15px 0px 0px 0px;
}

.About-main-ah {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.About-main-box-1-ah {
  width: 68%;

  display: flex;

  flex-direction: column;

  gap: 38px;
}

.About-main-box-2-tital-ah {
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.About-main-box-2-ah {
  width: 30%;
}

.About-main-box-2-tital-ah h2 {
  color: var(--000000, #000);
  font-family: "Public Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.About-main-box-2-tital-ah a {
  color: var(--194-d-79, #194d79);
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
}

.max--width {
  max-width: 1000px;
  min-width: 1000px;
}

.About-main-card-1-ah {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 30px 50px 50px 30px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
}

.About-main-card-1-ah h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  /* 127.083% */
}

.About-main-card-1-ah p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  padding: 20px 0px 15px 0px;
  line-height: 26px;
  /* 162.5% */
}

.About-main-card-1-ah hr {
  height: 2px;
  background-color: #e0e0e0;
  border: none;
}

.Industry-ah {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 100px;
  padding-top: 25px;
}

.Industry-ah span {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.Industry-ah span h2 {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.5px;
  /* 190.625% */
}

.Industry-ah span p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 162.5% */
  padding: 0px;
}

.Brooklyn-Simmons-box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 42px;
  padding-top: 30px;
}

.Brooklyn-Simmons-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Brooklyn-Simmons-logo-div {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 30px;
}

.Brooklyn-Simmons-logo {
  width: 18%;
}

.Brooklyn-Simmons-logo img {
  width: 100%;
  object-fit: cover;
}

.Brooklyn-Simmons-tital {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Brooklyn-Simmons-tital span p {
  padding: 0px;
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.Brooklyn-Simmons-tital span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Brooklyn-Simmons-tital h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.Brooklyn-Simmons-tital span a {
  border-radius: 8px;
  width: 80px;
  background: var(--Gray-6, #f2f2f2);
  padding: 8px;
  color: var(--Gray-3, #828282);
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  transition: 0.3s;
}

.Brooklyn-Simmons-card button {
  border-radius: 10px;
  background: transparent;
  border: 2px solid #194d79;
  color: #194d79;
  text-align: center;
  padding: 5px 50px;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  transition: all .6s ease;
  height: 47px;
}

.Brooklyn-Simmons-card button:hover {
  background: #194d79;
  color: #fff;
}

.latest-comment-txt-input-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.latest-comment-txt-input-box input {
  width: 100%;
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  border: none;
  padding: 15px 44px 15px 20px;
  outline: none;
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 162.5% */
}

.newsletter-detail-comment img {
  width: 48.076px;
  height: 48.076px;
  border-radius: 10px;
}

.latest-comment-txt-input-box button {
  background: transparent;
  border: none;
  position: absolute;
  right: 20px;
}

.Brooklyn-Simmons-tital span a:hover {
  color: #fff;
  background-color: #89c400;
}

.Award-box-ah {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;
}

.Award-card-ah {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.Award-box-logo-ah {
  width: 15%;
}

.Award-box-logo-ah img {
  width: 100%;
  object-fit: cover;
}

.Award-box-tital-ah {
  width: 80%;
  overflow: hidden;
}

.Award-box-tital-ah h2 {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 144.444% */
}

.Award-box-tital-ah p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 162.5% */
  padding: 10px 0px;
}

.Award-a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}

.Award-a a {
  color: var(--194-d-79, #194d79);
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.Job-ah {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 33px;
}

.Job-box-ah {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 2px solid #f2f2f2;
  margin: 10px 0px;
}

.Job-logo-ah {
  width: 20%;
}

.Job-card-ah {
  width: 70%;
}

.Job-logo-ah img {
  width: 100%;
}

.Job-list-1-ah {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.Job-list-1-ah span {
  display: flex;
  flex-direction: column;
}

.Job-list-1-ah span h2 {
  color: var(--Gray-1, #333);
  font-family: "Public Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  /* 108.929% */
}

.Job-list-1-ah span p {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 130% */
  padding: 4px 0px;
}

.Job-list-2-ah {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  padding: 10px 30px;
}

.Job-list-2-ah span {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Job-list-2-ah span p {
  padding: 0px;
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 162.5% */
}

.Job-list-2-ah span svg {
  width: 18px;
  height: 18px;
}

.Job-list-3-ah {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
}

.Job-list-3-ah button {
  border-radius: 8px;
  background: var(--9-bdb-08, #9bdb08);
  border: 1px solid #9bdb08;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 8px 20px;
}

.Job-list-3-ah a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.About-main-card-1-ah h2 span {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30.5px;
}

.Recommend-box-ah {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  padding-top: 35px;
}

.Recommend-card-ah {
  width: 92%;
  border-radius: 20px;
  background-color: #fff;
}

.Recommend-card-img-ah {
  width: 100%;
}

.Recommend-card-img-ah img {
  width: 100%;
}

.Recommend-card-tital-ah {
  width: 100%;
  padding: 20px 25px;
}

.Recommend-card-tital-ah h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  /* 169.444% */
  text-transform: capitalize;
}

.Recommend-card-tital-ah input {
  width: 100%;
  background: #f2f2f2;
  border: none;
  outline: none;
  padding: 16px;
  border-radius: 15px;
  margin: 5px 0px 15px 0px;
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 162.5% */
}

.Recommend-card-tital-ah button {
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  background: #194d79;
  border: 1px solid #194d79;
  border-radius: 15px;
  padding: 16px 0px;
}

.Recommend-card-tital-ah span {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 10px;
}

.padding-ah {
  padding: 45px 0px 24px 0px;
  margin: 0px 14px;
}

.more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
}

/* tabs-code-html-css-code tabs-code-html-css-code-tabs-code-html-css-code tabs-code-html-css-code   */
/* tabs-code-html-css-code tabs-code-html-css-code-tabs-code-html-css-code tabs-code-html-css-code   */
/* tabs-code-html-css-code tabs-code-html-css-code-tabs-code-html-css-code tabs-code-html-css-code   */

.navtabs {
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 30px;
  border-top: 1px solid #e0e0e0;
  margin-top: 30px;
  gap: 70px;
}

.navtab {
  cursor: pointer;
  transition: color 0.3s;
  padding-bottom: 24px;
  /* color: var(--000000, #000); */
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 130% */
}

/* .navtab:hover {
  color: #89c400;
} */


.underline {
  position: absolute;
  bottom: 0px;
  height: 3px;
  background: #89c400;
  transition: left 0.3s ease, width 0.3s ease;
}

.content {
  display: none;
  width: 100%;
}

.content.active {
  display: block;
}

.footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 14px;
  color: #000;
  transition: color 0.3s ease;
  font-family: "Public Sans", sans-serif;
}

.footer a:hover {
  color: linear-gradient(145deg, #89c400, #89c400);
}

.ah-spas {
  padding-bottom: 0px;
}

.main-tabs-ah {
  width: 100%;
  padding-top: 60px;
  background-color: #f5f5f5;
}

span.edite {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span.edite svg {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

span.edite-icon {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* tabs-code-html-css-code tabs-code-html-css-code-tabs-code-html-css-code tabs-code-html-css-code   */
/* tabs-code-html-css-code tabs-code-html-css-code-tabs-code-html-css-code tabs-code-html-css-code   */
/* tabs-code-html-css-code tabs-code-html-css-code-tabs-code-html-css-code tabs-code-html-css-code   */

section.Statistic-ah {
  width: 100%;
  padding: 100px 0px;
}

.main-Statistic-ah {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.Statistic-card-ah {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 35px 30px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
}

.Overall-Statistic-ah {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Overall-Statistic-ah h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  /* 127.083% */
}

.Overall-Statistic-ah ul {
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Overall-Statistic-ah ul li {
  color: var(--Gray-3, #828282);
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  list-style: none;
}

.ratings-ah-1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
}

.ratings-ah-1-TITAL {
  width: 18%;
}

.ratings-ah-1-TITAL span {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.ratings-ah-1-TITAL span h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ratings-ah-1-TITAL span p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30.5px;
  /* 127.083% */
  padding-bottom: 20px;
}

.ratings-ah-1-TITAL h3 {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.ratings-ah-1-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  padding: 10px 20px;
}

.ratings-ah-1-bar {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.ratings-ah-1-box {
  width: 14%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

span.\34 5 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

span.\34 5 h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* 108.333% */
}

span.\34 5 p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

span.persent {
  display: flex;
  align-items: center;
  gap: 10px;
}

span.persent svg {
  width: 12px;
  height: 12px;
}

span.persent h3 {
  color: var(--000000, #000);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.w3-light-grey.w3-tiny {
  width: 90%;
  background-color: #f2f2f2;
  border-radius: 50px;
  height: 14px;
}

.w3-container.w3-green {
  background-color: #89c400;
  border-radius: 50px;
  height: 100%;
}

.scrllo-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.scrllo-bar p {
  color: var(--000000, #000);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 33px;
  /* 206.25% */
  padding: 0px;
}

.bar-pogres-box-ah {
  width: 100%;
  padding-top: 30px;
}

.bar-pogres-box-cards-ah {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 30px;
}

.pogras-card-ah {
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 60px;
}

.pogras-card-ah span {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.pogras-card-ah span h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* 108.333% */
}

.pogras-card-ah span p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

span.parsent-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

span.parsent-icon p {
  color: var(--000000, #000);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.bar-pogres-box-imger-ah {
  width: 100%;
}

.bar-pogres-box-imger-ah img {
  width: 100%;
  object-fit: cover;
}

.gol-bar-ah {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gol-bar-head {
  width: 13%;
}

.gol-bar-head h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.gol-bar-head p {
  color: var(--000000, #000);
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 130% */
  text-align: center;
}

.gol-bar-box {
  width: 32%;
}

.gol-bar-box img {
  width: 100%;
}

.gol-bar-tital {
  width: 24%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.gol-bar-tital span {
  display: flex;
  align-items: center;
  gap: 15px;
}

.gol-bar-tital span p {
  color: var(--000000, #000);
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 130% */
}

.Draft-ah {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 35px;
  padding: 22px 0px 10px 0px;
}

.Draft-ah button {
  border-radius: 8px;
  border: 1px solid var(--Gray-3, #828282);
  padding: 10px 38px;
  background: transparent;
  color: var(--Gray-3, #828282);
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Draft-ah span {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2px;
}

.Draft-ah span h3 {
  color: var(--000000, #000);
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  /* 127.083% */
}

.Draft-ah span p {
  padding: 0px;
  color: #777;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 162.5% */
}

.About-main-card-1-hello-ah {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

.Hire-ah-box {
  width: 100%;
  background-image: url(../img/Hire-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 60px 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Hire-ah-box h2 {
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Hire-ah-box p {
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.5px;
  /* 157.5% */
  padding: 30px 35px;
}

.Hire-ah-box button {
  width: 70%;
  padding: 14px;
  background-color: #fff;
  border-radius: 14px;
  border: 1px solid #fff;
  color: var(--000000, #000);
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 37.5px;
  /* 187.5% */
}

.job-ah-post {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-ah-post h2 {
  color: var(--000000, #000);
  font-family: "Public Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.job-ah-post h2 span {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.job-ah-Filter {
  width: 55%;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}

.job-ah-Filter-card {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
}

.job-ah-Filter-card p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 144.444% */
}

.job-ah-Filter-card select {
  border-radius: 10px;
  border: 1px solid var(--Gray-3, #828282);
  background: transparent;
  outline: none;
  padding: 10px 15px;
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 144.444% */
  width: 100%;
}

.Website-ah-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding-top: 50px;
}

.Website-ah-card {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.Website-ah-card button {
  padding: 14px;
  border-radius: 9px;
  background: var(--Gray-6, #f2f2f2);
  border: none;
}

.Website-ah-card button svg {
  width: 25px;
  height: 25px;
}

.Website-ah-card span {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.Website-ah-card span h3 {
  color: #9bdb08;
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.Website-ah-card span h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  /* 138.636% */
}

.width-give {
  width: 25%;
}

.width-hell {
  width: 65%;
}

.Popular-ti-ah {
  width: 100%;
  padding: 30px 0px 10px 0px;
}

.Popular-ti-ah h2 {
  color: var(--000000, #000);
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 144.444% */
}

.Popular-ti-ah {
  width: 100%;
}

.Popular-ti-ah-btn-box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 18px;
  gap: 15px;
}

.Popular-ti-ah-btn-box button {
  color: var(--Gray-3, #828282);
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 162.5% */
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  border: none;
  padding: 5px 15px;
}

.job-post-btn-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-post-btn-box button {
  border-radius: 40px;
  background: var(--194-d-79, #194d79);
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  /* 183.333% */
  border: 1px solid #194d79;
  padding: 5px 45px;
}

.KABC-logo-ah button {
  border-radius: 8px;
  background: var(--89-c-400, #89c400);
  padding: 6px 20px;
  border: 1px solid #89c400;
  color: #fff;
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Angeles-ah-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
}

.Angeles-logo-side-box {
  width: 44%;
  display: flex;
  align-items: center;
  gap: 35px;
}

.Angeles-logo-ah {
  width: 22%;
}

.Angeles-logo-ah img {
  width: 100%;
}

.Angeles-logo-side-box span h2 {
  color: var(--000000, #000);
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  /* 127.083% */
}

.Angeles-logo-side-box span p {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 162.5% */
}

.Angeles-ah-icon-side {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Angeles-my-box-ah {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
}

.Jacob-card-ah-box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px 0px;
}

.Jacob-card-ah {
  width: 49%;
  background-color: #ffff;
  border-radius: 20px;
  padding: 30px;
}

.Director-ah-boxing-logo {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.Director-ah-boxing {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.span-logo-Director {
  width: 26%;
}

.span-logo-Director img {
  width: 100%;
}

.Director-ah-boxing-logo span h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* 100% */
}

.Director-ah-boxing-logo span p {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 162.5% */
}

.search-stations-input span::after {
  display: none;
}

.Director-ah-boxing p {
  color: #89c400;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Director-ah-boxing-2 {
  width: 100%;
  padding: 18px 0px 25px 0px;
}

.Director-ah-boxing-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Director-ah-boxing-list-logo {
  width: 40%;
  display: flex;
  gap: 60px;
}

.Director-ah-boxing-list-logo img {
  width: 4%;
}

.Director-ah-boxing-list-logo span p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 185.714% */
}

.Director-ah-boxing-list-logo span h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 162.5% */
}

.Director-ah-boxing-list-span h2 {
  color: var(--Gray-2, #4f4f4f);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 162.5% */
}

.Director-ah-boxing-list-span p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 185.714% */
  text-align: end;
}

.Director-ah-boxing-3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Director-ah-boxing-3 button {
  width: 48%;
  border-radius: 10px;
  background: transparent;
  border: 2px solid #194d79;
  padding: 12px;
  color: #194d79;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  /* 206.25% */
}

img.width-hegit-ah {
  width: 10px;
  height: 10px;
}

button.interview-ah {
  background: #194d79;
  color: #fff;
}

.KABC-tital-ah p span {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 118.182% */
}

.Office-ah-box {
  width: 100%;
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 22px 0px;
}

.Office-ah-logo {
  width: 6%;
}

.Office-ah-logo img {
  width: 100%;
}

.Office-ah-span h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 144.444% */
}

.Office-ah-span p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 185.714% */
  padding: 0px;
}

.Current-ah-job {
  width: 100%;
  background-image: url(../img/two-line.png);
  padding-left: 40px;
  background-position-y: top;
  background-position-x: left;
  background-repeat: no-repeat;
}

.Current-ah-job-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Current-ah-job-card span h2 {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 162.5% */
}

.Current-ah-job-card span h3 {
  color: var(--89-c-400, #89c400);
  text-align: right;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 162.5% */
}

.Current-ah-job-card span p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 185.714% */
  padding: 0px;
}

.Current-ah-job p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 162.5% */
  padding: 12px 0px;
}

.Skills-ah-box-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-top: 24px;
}

.Skills-ah-box {
  width: 100%;
}

.Skills-ah-box h2 {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;

}

.Skills-ah-box p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  padding: 10px 0px 0px 0px;
}

.Education-ah-boxing {
  padding-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.Education-card-ah {
  width: 100%;
}

.Education-logo-side {
  width: 100%;
  display: flex;
  gap: 10px;
}

.Education-logo {
  width: 15%;
}

.Education-logo img {
  width: 100%;
}

.Education-side-ah h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 144.444% */
}

.Education-side-ah h3 {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;

}

.Education-side-ah p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;

  padding: 0px;
}

.Education-side-ah h4 {
  color: #000;
  padding-top: 10px;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;

}

.Job-Openings-card {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  margin: 0px 0px 30px 0px;
  padding: 30px 25px;
}

.Job-Openings-list-1 {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 14px;
  padding-bottom: 20px;
}

.Job-Openings-logo {
  width: 14%;
}

.Job-Openings-logo img {
  width: 101%;
}

.Job-Openings-list-1 span h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;

}

.Job-Openings-list-1 span p {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;

}

.Job-Openings-list-2 {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
  background: var(--Gray-6, #f2f2f2);
}

.Job-Openings-list-2 span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Job-Openings-list-2 span p {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;

}

.Job-Openings-list-2 span svg {
  width: 14px;
  height: 14px;
}

.Job-Openings-list-3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.Job-Openings-list-3 button {
  border-radius: 5px;
  background: var(--89-c-400, #89c400);
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid #89c400;
  padding: 8px 12px;
  color: var(--ffffff, #fff);
  font-family: "Public Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.Job-Openings-list-3 a {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
}

.button-box {
  padding-bottom: 40px;
}

.Applied-ah-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.Applied-ah-card {
  width: 100%;
  background-color: #fff;
  border-radius: 30px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  padding: 30px 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Applied-ah-logo {
  width: 21%;
}

.Applied-ah-tital {
  width: 70%;
}

.Applied-ah-logo img {
  width: 100%;
}

.Applied-ah-list-1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
}

.Applied-ah-list-1 span h2 {
  color: var(--Gray-1, #333);
  font-family: "Public Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;

}

.Applied-ah-list-1 span p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  padding-top: 4px;
}

.Applied-ah-list-1 svg {
  width: 24px;
  height: 24px;
}

.Applied-ah-list-2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  padding: 10px 30px;
}

.Applied-ah-list-2 span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Applied-ah-list-2 span svg {
  width: 16px;
  height: 16px;
}

.Applied-ah-list-2 span p {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;

}

.Applied-ah-list-3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
}

.Applied-ah-list-3 button {
  border-radius: 8px;
  border: 1px solid var(--89-c-400, #89c400);
  background: transparent;
  padding: 10px 30px;
  color: var(--89-c-400, #89c400);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.Applied-ah-list-3 a {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 10px;
}

.Applied-ah-list-3 a svg {
  width: 20px;
  height: 20px;
}

button.changhe-blue {
  color: #194d79;
  border: 1px solid #194d79;
}

button.change-red {
  color: #eb5757;
  border: 1px solid #eb5757;
}

.Schedule-ah-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 20px;
  align-items: center;
}

.Schedule-ah-imger {
  width: 30%;
}

.Schedule-ah-card-box {
  width: 69%;
  padding: 40px;
}

.Schedule-ah-imger img {
  width: 100%;
  border-radius: 15px;
}

.Schedule-ah-list-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Schedule-ah-list-1 span {
  display: flex;
  align-items: center;
  gap: 12px;
}

.Schedule-ah-list-1 span svg {
  width: 18px;
  height: 18px;
}

.Schedule-ah-list-1 span p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;

  padding: 0px;
}

.Schedule-ah-card-box h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px;

  width: 90%;
  padding: 20px 0px 30px 0px;
}

.Schedule-ah-list-3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Soul-icon-box {
  display: flex;
  align-items: center;
  gap: 34px;
}

.Soul-icon-box span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Soul-icon-box span svg {
  width: 20px;
  height: 20px;
}

.Soul-icon-box span p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;

}

.Schedule-ah-list-3 a {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;

  text-decoration: none;
}

.Schedule-ah-list-3 a span {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.more-class {
  padding: 40px 0px 40px 40px;
}

.satisfied {
  width: 100%;
}

.satisfied h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.satisfied h3 {
  color: var(--194-d-79, #194d79);
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  padding: 5px 0px 10px 0px;
}

.satisfied button {
  color: #194d79;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;

  background: transparent;
  border-radius: 10px;
  border: 2px solid #194d79;
  padding: 12px;
  width: 100%;
}

.satisfied p {
  color: var(--Gray-3, #828282);
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;

  padding: 0px;
}

.my-ah-class {
  padding: 50px 0px;
}

.Cooper-ah-box {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.Cooper-ah-imger {
  width: 6.2%;
}

.Cooper-ah-imger img {
  width: 100%;
}

.Cooper-ah-tital {
  width: 90%;
  padding: 20px 25px;
  border-radius: 0px 10px 10px 10px;
  background: var(--Gray-6, #f2f2f2);
}

.Cooper-ah-tital-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Cooper-ah-tital-head h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;

}

.Cooper-ah-tital-head span {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.Cooper-ah-tital h3 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;

  padding: 10px 0px 2px 0px;
}

.Cooper-ah-tital p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;

  padding: 0px;
}

.Cooper-ah-box-main {
  width: 100%;
  padding-top: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
}

.America-Sweatshirt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.America-ah {
  width: 50%;
}

.Sweatshirt-ah {
  width: 52%;
}

.America-ah-part-1 img {
  width: 100%;
  padding: 0px 30px 0px 0px;
}

.America-ah-part-1 {
  width: 100%;
}

.America-ah-part-2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
}

.America-ah-part-2 img {
  width: 24%;
  object-fit: cover;
}

.Sweatshirt-ah h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  text-transform: capitalize;

}

.Sweatshirt-ah-select-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 35px;
}

.Sweatshirt-ah-select-card {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 35%;
}

.Sweatshirt-ah-select-card p {
  color: #000;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.Sweatshirt-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px 60px 0px;
}

.Sweatshirt-part-1 {
  width: 67%;
}

.Sweatshirt-part-2 {
  width: 30%;
}

.Sweatshirt-ah-select-card select {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  outline: none;
  padding: 10px;
  color: #000;
  width: 100%;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

}

.Sweatshirt-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px 60px 0px;
}

.Sweatshirt--box--alt {
  align-items: flex-start;
}

.Sweatshirt-part-1 {
  width: 67%;
}

.Sweatshirt-part-2 {
  width: 30%;
}

section.Sweatshirt-sec {
  width: 100%;
  padding-top: 60px;
}

.Sweatshirt {
  width: 100%;
}

.Sweatshirt-ah-select-card select option {
  color: #000;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

}

.Description-box-tital {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 34px;
}

.Description-box-tital h2 {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;

}

.Description-box-tital p {
  color: #000;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  padding: 0px 0px 29px 0px;
}

.Description-box-tital a {
  color: #89c400;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.Sweatshirt-ah-select-card input {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  outline: none;
  padding: 10px;
  color: #000;
  width: 100%;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.Sweatshirt-ah-select-card button {
  border-radius: 10px;
  border: 2px solid #194d79;
  width: 100%;
  background: transparent;
  color: #194d79;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  padding: 5px;
}

.Sweatshirt-ah-select-card h2 {
  color: #000;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;

}

/******************* Shahdin Pages CSS Start *******************/

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

a {
  text-decoration-line: none !important;
}

/*********** Header Main CSS Start ***********/

header.header_main {
  padding: 27px 0px 13px 0px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
}

.header-logo {
  width: 100%;
  cursor: pointer;
  padding: 0px 45px 0px 0px;
}

.header-logo img {
  width: 100%;
}

.header_nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px;
  gap: 48px;
  width: 94%;
}

.contact-follow-like-btn ul li svg {
  width: 30px;
  height: 30px;
}

.header_nav ul li {
  color: #000;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  position: relative;
  cursor: pointer;
}


.contact-follow-like-btn ul li {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header_nav ul li::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 0%;
  bottom: -5px;
  border-radius: 5px;
  background: #89c400;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.6s ease;
}

.header_nav ul li:hover::before,
.header_nav ul li.active::before {
  width: 100%;
}

.header_end {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 21px;
  margin-top: -5px;
}

.header_end a:hover {
  cursor: pointer;
}

.header--end--logo {
  display: flex;
  align-items: start;
}

.login-btn {
  color: #194d79;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  border: 2px solid #194d79;
  padding: 4px 51px;
  transition: all 0.6s ease;
}

.login-btn {
  background: transparent;
  color: #194d79 !important;
}

.login-btn:hover {
  transition: all 0.6s ease;
  background: #194d79;
  color: #fff !important;
}

.header_end a {
  position: relative;
}

.header_end a span {
  position: absolute;
  top: -6px;
  right: 4px;
  background: #89c400;
  padding: 0px 4px;
  border-radius: 100%;
  color: #fff;
  font-family: "Public Sans";
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.7;
}

.header_end a svg {
  padding: 5px 7px 5px 5px;
  border-radius: 10px;
  height: 36px;
  transition: all 0.6s ease;
  width: 42px;
}

.header_end a svg:hover {
  background: #d7d7d7;
}

/*********** Header Main CSS ENd ***********/

.rms--header--row {
  align-items: center;
}

.rms_header_logo {
  display: flex;
  justify-content: flex-start;
  gap: 27px;
}

.rms--header--row>* {
  padding: 0 !important;
}

.rms_header_logo--cont {
  width: 91px;
}

.rms_header_logo a {
  align-self: center;
}

section.rms_header {
  padding: 15px 0px;
  background-color: white;
  box-shadow: 0px 4px 12px 0px #00000026;
  position: relative;
}

.rms--header--slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  gap: 98px;
  background: #e0e0e0;
}

/* 
.toggle.clicked .bars:nth-child(1) {
  transform: rotate(45deg);
  position: relative;
  top: 10px;
}

.toggle.clicked .bars:nth-child(2) {
  transform: rotate(-45deg);
}

.toggle.clicked .bars:nth-child(3) {
  transform: scaleX(0);
} */

/*********** First Banner Section CSS Start ***********/

.first-banner-sec {
  background: url(../img/first-banner-img.png) no-repeat top center;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 87px 0px;
  margin: 17px 0px 0px 0px;
}

.first-banner-txt h3 {
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 58px;
  width: 60%;
  font-weight: 700;
  color: #fff;
  margin: 0px 0px 54px 0px !important;
}

.select-station-drop select option {
  font-size: 14px;
}

.first-banner-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-station-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 963.215px;
  margin: 0 auto;
}

.search-stations-input input {
  padding: 24px 20px;
  width: 707px;
  border-radius: 0px 15px 15px 0px;
  background: #fff;
  box-shadow: 6px 0px 13px 0px rgba(8, 42, 83, 0.5);
  border: none;
  font-size: 18px;
}

.search-stations-input--rms input {
  padding: 10px 20px;
  box-shadow: none;
  background-color: #f2f2f2;
  font-size: 16px !important;
}

.search-stations-input--rms--alt input {
  border-radius: 15px;
  padding: 17px 28px;
  width: 100%;
}

.search-stations-input--rms input::placeholder {
  font-size: 16px !important;
}

.search-stations-input--rms .search-stations-input svg {
  width: 30px;
}

.search-stations-input.full-width input {
  width: 963.215px;
  border-radius: 20px;
  height: 82.832px;
}

.search-stations-input input::placeholder {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}

.search-stations-input input:focus {
  outline: none;
}

.select-station-drop select:focus {
  outline: none;
}

.select-station-drop {
  display: flex;
  align-items: center;
}

.select-container {
  display: flex;
  align-items: center;
  padding: 20.92px 30px 20.92px 30px;
  background-color: #89c400;
  position: relative;
  border-radius: 25px 0px 0px 25px;
  box-shadow: -6px 0px 13px 0px rgba(8, 42, 83, 0.5);
  width: 100%;
  max-width: 400px;
  cursor: pointer;
}

.search-stations-input--rms .select-container {
  padding: 12px 30px 12px 30px;
  box-shadow: none;
  border-radius: 15px 0px 0px 15px;
}

.select-container:hover {
  background-color: #75a111;
}

.select-icon {
  margin-right: 18px;
  width: 40px;
  height: 40px;
  color: #fff;
}

.select-box {
  border: none;
  outline: none;
  padding: 5px 25px 5px 5px;
  background: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  font-family: "Public Sans";
  font-size: 22px !important;
  width: 100%;
  /* Ensure the select-box takes the full width */
}

.select-box option {
  padding: 10px;
  font-size: 22px !important;
  color: #000;
}

.select-tv-icon svg {
  color: #fff;
}

.first-banner-txt a {
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  display: block;
  margin: 0px 0px 0px 0px !important;
  color: #FFF;
  text-decoration-line: underline !important;
  
}


.search-stations-input {
  position: relative;
  display: flex;
}

.search-stations-input span {
  position: absolute;
  right: 34px;
  top: 8px;
  font-size: 40px;
  color: #194d79;
  cursor: pointer;
}

.search-stations-input {
  position: relative;
}

.search-stations-input--rms span {
  top: -2px;
}

.search-stations-input--rms--alt span {
  top: -4px;
}

.search-stations-input--rms span svg {
  width: 24px;
}

.search-stations-input--rms .select-box,
.search-stations-input--rms option {
  font-size: 16px !important;
}







/*********** Select Option Section CSS Start ***********/



.select-station-drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 256.12px;
}



.select-container {
  display: flex;
  align-items: center;
  padding: 20.92px 30px;
  background-color: #89c400;
  border-radius: 25px 0px 0px 25px;
  box-shadow: -6px 0px 13px 0px rgba(8, 42, 83, 0.5);
  width: 100%;
  max-width: 400px;
  cursor: pointer;
  justify-content: space-between;
}

.selected-option {
  flex-grow: 1;
  color: #fff !important;
  font-family: "Public Sans";
  font-size: 19px !important;
  margin-top: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  text-transform: capitalize;
  left: 50%;
  transform: translate(-50%);
}


.select-station-drop .select-container svg {
  color: #fff;
}

.options-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: -6px 0px 13px 0px rgba(8, 42, 83, 0.5);
  z-index: 1000;
}

.options-list .select-option svg {
  color: #000;
  width: 35px;
  height: 35px;
}

.options-list li.select-option {
  font-family: 'Public Sans';
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 15px 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.options-list li.select-option::before {
  position: absolute;
  content: "";
  background: #89c400;
  height: 50px;
  width: 0px;
  left: 0px;
}

.options-list li.select-option:hover {
  font-weight: bold;
}

.options-list li.select-option:hover:before {
  width: 5px;
}

.select-icon {
  margin-right: 18px;
  width: 40px;
  height: 40px;
  color: #fff;
}

.select-tv-icon svg {
  color: #fff;
}


/*********** First Banner Section CSS End ***********/

/*********** Latest Rating Section CSS Start ***********/

section.latest-rating-sec {
  padding: 36px 0px 0px 0px;
}

.latest-rating-txt h3 {
  font-family: "Public Sans", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin: 0px 0px 36px 0px !important;
  color: #000;
}

section.ratemystation-shop-sec.more-x {
  padding-top: 30px;
}


.latest-rating-box {
  margin: 0px 0px 30px 0px;
  padding: 29px 29px 32px 32px;
  background: #fff;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));

}

.latesthomepg-line {
  margin: 55px 0px 0px 0px;
}

.latesthomepg-line hr {
  color: #bdbdbd;
  opacity: 1.25;
}

.rating-user-box {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.reporter-topline hr {
  color: #f2f2f2;
}

.rating-user-box img {
  width: 52px;
  height: 52px;
}

.latest-rating-user-txt h5 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin: 0px;
  padding: 4px 0px;
}

.latest-rating-user-txt select {
  color: #194d79;
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border: none;
}

.latest-rating-user-verified {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.verified-rating-box {
  display: flex;
  align-items: center;
  gap: 7px;
}

.karc-txt-logo {
  display: flex;
  align-items: center;
}

.verified-rating-box h6 {
  color: #89c400;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 0px;
}

.rating-of {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #828282;
}

.karc-txt-logo h4 {
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: #000;
}

.review-news-director {
  margin: 50px 0px 30px 0px;
  border-radius: 8px;
  background: #f2f2f2;
  padding: 23px 9px 18px 20px;
  position: relative;
}

.review-news-director-second {
  margin: 50px 0px 0px 0px;
}

.reporter-topline {
  position: absolute;
  top: -40px;
  z-index: 999;
  width: 100%;
  left: 0px;
}

.director-urer {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.director-urer p{
  color: #828282; 
  font-weight: 400;
  font-family: Public Sans;
}

.director-urer span{
  color: #000000; 
  font-weight: 600;
  font-family: Public Sans;
}


.director-urer img {
  width: 51.991px;
  padding: 0px 0px 25px 0px;
}

.news-director-current {
  display: flex;
  align-items: center;
  gap: 20px;
}

.news-director-current h5 {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #828282;
  margin: 0px;
}

.news-director-current a {
  border-radius: 6px;
  background: #89c400;
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  padding: 2px 6px;
  text-decoration-line: none;
}

.latest-rating-user-txt select:focus {
  outline: none;
}

.director-name-txt h3 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.director-name-txt {
  margin: 10px 0px 0px 0px;
}

.review-director-para h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.review-director-para h5 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.review-director-para p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.latest-rating-user-txt select option {
  color: #000;
  font-size: 14px;
}

.racial-discrimination h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  color: #828282;
  margin: 0px 0px 3px 0px;
}

.racial-discrimination p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin: 0px;
}

.racial-discrimination {
  margin: 25px 0px 25px 0px;
}

.racial-discrimination h5 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.newroom-coworker-txt h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #828282;
  margin: 0px 0px 20px 0px;
}

.newroom-coworker-txt svg {
  margin: 0px 0px 10px 0px;
}

.newroom-coworker-txt h5 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.four-select-tab {
  display: flex;
  align-items: flex-start;
  gap: 22px;
  margin: 30px 0px 0px 0px;
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 0px 25px 0px;
}

.four-select-tab h5 {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  color: #828282;
  margin: 4px 0px 0px 0px;
}

.four-select-tab ul {
  display: flex;
  gap: 20px 10px;
  align-items: center;
  margin: 0px;
  padding: 0px;
  flex-wrap: wrap;

}

.four-select-tab ul li {
  list-style: none;
}

.four-select-tab ul li a {
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
  text-decoration-line: none;
  border-radius: 10px;
  background: #f2f2f2;
  padding: 8px 10px;
}

.like-report-submitted {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.like-report-txt ul li {
  color: #89c400;
  font-family: "Public Sans", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  background: #f2f2f2;
  padding: 6px 12px 6px 12px;
}

.like-report-txt ul {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0px;
  padding: 0px;
}

.like-report-txt ul li:nth-child(2) {
  color: #828282;
}

.latest-rating-comment-box {
  margin: 27px 0px 0px 0px;
  display: flex;
  gap: 17px;
  align-items: start;
}

.latest-comment-txt-box {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 15px 28px 20px 21px;
}

.latest-comment-txt-box p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 0px 0px 5px 0px;
}

.latest-comment-txt-box span {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.like-report-submitted p {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  color: #828282;
}

.latest-rating-user-txt button {
  padding: 0px;
  color: #194d79;
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.latest-rating-user-txt .accordion-button::after {
  margin-left: 0px;
  font-size: 12px;
  background-size: 0.9rem;
  position: relative;
  top: 3px;
  left: 3px;
}

.latest-rating-user-txt .accordion-button:not(.collapsed) {
  background: transparent;
  box-shadow: none;
  color: #194d79;
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  position: relative;
}

.latest-rating-user-txt .accordion-button:focus {
  border: none;
  box-shadow: none;
}

.job-detail-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f2;
  border-radius: 8px;
  position: absolute;
  padding: 10px 20px 0px 20px;
  gap: 40px;
  top: 80px;
  left: 90px;
}

.job-detail-dropdown ul li {
  font-family: "Public Sans", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  color: #828282;
}

.job-detail-dropdown ul li span {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.job-detail-dropdown ul {
  padding: 0px;
}

.latest-rating-loadmore-btn {
  text-align: center;
  /* margin-top: -25px; */
}

.latest-rating-loadmore-btn a {
  border-radius: 40px;
  background: #194d79;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  padding: 12px 40px;
  border: 1px solid #194d79;
  transition: all 0.6s ease;
  text-decoration: none;
}

.latest-rating-loadmore-btn a:hover {
  background: transparent;
  color: #194d79;
}

.newsletter-side-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 36px 12px;
  width: 93%;
}

.newsletter-side-head h3 {
  font-family: "Public Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  color: #000;
  cursor: pointer;
}

.newsletter-side-head-alt h3 {
  cursor: default;
}

.newsletter-side-head a {
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  color: #194d79 !important;
  cursor: pointer;
}

.newsletter-side-head a:hover {
  text-decoration: underline !important;
}

.side-newsletter-box {
  margin: 10px 0px 30px 0px;
  background: #fff;
  border-radius: 20px;
  cursor: pointer;
}

.side-newsletter-txt {
  padding: 18px 25px 20px 25px;
}

.side-newsletter-txt h5 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
}

.side-newsletter-box img {
  width: 100%;
  border-radius: 20px 20px 0px 0px;
}

.side-subscribe-box {
  background: #fff;
  padding: 20px 25px 24px 25px;
  border-radius: 20px;  
  margin: 0px 14px;
}

.side-subscribe-box h5 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  margin: 0px 0px 15px 0px !important;
}

.side-subscribe-box input {
  background: #f2f2f2;
  width: 100%;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 0px 0px 20px 0px;
  border: none;
}

.side-subscribe-box input:focus {
  outline: none;
}

.side-subscribe-box input::placeholder {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  color: #828282;
}

.side-subscribe-box button {
  background: #194d79;
  padding: 12px 20px;
  width: 100%;
  border-radius: 15px;
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  border: 1px solid #194d79;
  transition: all 0.6s ease;
}

.side-subscribe-box button:hover {
  background: transparent;
  color: #194d79;
}

.follow-us-rate {
  background: #fff;
  padding: 26px 20px 26px 29px;
  border-radius: 20px;
}

.follow-us-rate ul {
  padding: 0px;
  margin: 0px;
}

.follow-us-rate ul li a {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.follow-us-rate ul li a i {
  margin-right: 10px;
  font-size: 22px;
}

.follow-us-rate ul li a svg {
  width: 30px;
  height: 28px;
  margin: 0px 12px 0px 0px;
}

.follow-us-rate ul li {
  padding: 6px 0px 15px 0px;
}

.newsletter-follow-box h4 {
  font-family: "Public Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  color: #000;
  margin: 0px 0px 20px 0px !important;
}

.newsletter-follow-box {
  margin: 30px 14px 0px 14px;
}

/*********** Latest Rating Section CSS End ***********/

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.profile-avatar {
  width: 72px;
  height: 72px;
  background-color: #ccc;
  border-radius: 50%;
}

.profile-details {
  flex: 1;
  margin-left: 20px;
}

.profile-details h1 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.job-details {
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  width: 700px;
  z-index: 999;
  margin: 10px 0px 0px 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.jobdetail-click-btn h6 {
  color: #194d79;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px 0px 0px;
  cursor: pointer;
}

.job-detail {
  text-align: center;
  flex: 1;
}

.job-detail span {
  display: block;
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.job-detail p {
  margin: 5px 0 0;
  color: #000;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.rating {
  display: flex;
  align-items: center;
}

.rating p {
  margin: 0;
  font-weight: bold;
}

.rating img {
  margin-left: 5px;
  width: 50px;
}

h4.h4-box-in {
  padding-top: 70px !important;
  border-top: 1px solid #BDBDBD;
}

/*********** RateMyStation's Shop Section CSS Start ***********/

section.ratemystation-shop-sec {
  padding: 0px;
}

.about-creator-txt {
  width: 100%;
  padding-right: 30px;
}

h4.RateMyStation-h4-box {
  padding-top: 30px;
}

section.ratemystation-shop-sec h4 {
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  color: #000;
  margin: 30px 0px 35px 0px !important;
}

.ratemystation-shop-prod {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
  margin: 20px 0px 20px 0px;
  position: relative;
}

.ratemystation-prod-txt {
  padding: 17px 18px 18px 18px;
}

.ratemystation-prod-txt h3 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  margin: 0px 0px 10px 0px !important;
  height: 60px;
}

.ratemystation-prod-txt h6 {
  color: #89c400;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 0px 20px 0px;
}

.ratemystation-prod-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ratemystation-shop-prod img {
  width: 100%;
  height: 35vh;

}

.prod-light-btn {
  border-radius: 5px;
  border: 2px solid #194d79;
  color: #194d79;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  padding: 5px 14px;
  cursor: pointer;
  transition: all 0.6s ease;
  background: transparent;
}

.prod-light-btn:hover {
  background: #194d79;
  color: #fff;
}

.prod-dark-btn {
  border-radius: 5px;
  border: 2px solid #194d79;
  color: #fff;
  background: #194d79;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  padding: 5px 18px;
  cursor: pointer;
  transition: all 0.6s ease;
}

.prod-dark-btn:hover {
  background: transparent;
  color: #194d79;
}

.ratemystation-allprod-btn {
  margin: 48px 0px 0px 0px;
  text-align: center;
  cursor: pointer;
}

.ratemystation-allprod-btn a {
  color: #fff !important;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 40px;
  background: #194d79;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
  padding: 10px 30px;
  transition: all 0.6s ease;
  border: 1px solid #194d79;
}

.ratemystation-allprod-btn a:hover {
  background: transparent;
  color: #194d79 !important;
}

.prod-abslt-ratems {
  position: absolute;
  left: 20px;
  width: 50px !important;
  height: 70px !important;
  top: 0px;
}

h4.newslrter-box-h4 {
  padding-top: 70px;
}


/*********** RateMyStation's Shop Section CSS ENd ***********/

/*********** Looking for work Section CSS Start ***********/

.looking-for-work-sec {
  padding: 55px 0px 10px 0px;
}

.looking-for-worktxt {
  background: url(../img/looking-work-bg-img.png) no-repeat top center;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding: 61px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsletter-pagination-slide .pagination span {
  padding: 15px 0px;
}

.looking-for-worktxt h4 {
  font-family: "Public Sans", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
}

.looking-for-worktxt p {
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  width: 50%;
  line-height: 31.5px;
  color: #fff;
  margin: 23px 0px 38px 0px !important;
}

.looking-for-worktxt a {
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 37.5px;
  color: #000;
  background: #fff;
  padding: 10px 55px;
  cursor: pointer;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
  border: 1px solid #fff;
  transition: all 0.6s ease;
}

.looking-for-worktxt a:hover {
  background: transparent;
  color: #fff !important;
}

/*********** Looking for work Section CSS End ***********/

/*********** About Us Page CSS Start ***********/

.ratemystation-about-sec {
  background: url(../img/first-banner-img.png) no-repeat top center;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding: 63px 0px 50px 0px;
}

.ratemystation-about-txt h3 {
  font-family: "Public Sans", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 63px;
  color: #fff;
}

.ratemystation-about-txt {
  padding: 0px 0px 150px 0px;
}

.about-us-txt {
  padding: 30px 30px 10px 30px;
  background: #fff;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  border-radius: 12px;
  margin: -150px 0px 0px 0px;
}

.about-us-txt h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  text-transform: uppercase;
  color: #194d79;
  margin: 0px 0px 10px 0px;
}

.about-us-txt h4 {
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  margin: 0px 0px 28px 0px !important;
}

section.from-the-creator-sec {
  padding: 30px 0px 0px 0px;
}

.from-the-creator-box {
  padding: 31px 29px 37px 30px;
  background: #fff;
  border-radius: 12px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
}

.from-the-creator-box h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  text-transform: uppercase;
  color: #194d79;
  margin: 0px 0px 12px 0px;
}

.about-creator-txt h5 {
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  color: #000;
  margin: 0px 0px 37px 0px !important;
  width: 96% !important;
}

.about-creator-para p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 0px 0px 30px 0px !important;
}

.about-creator-img {
  position: relative;
}

.about-creator-img-name {
  border-radius: 0px 15px 15px 15px;
  background: #fff;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  content: "";
  padding: 10px 20px 10px 30px;
  bottom: 30px;
  right: 5px;
}

.about-creator-img-name h4 {
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  color: #000;
  margin: 0px 0px 2px 0px !important;
}

.about-creator-img-name h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
}

.articles-writer-sec .newsletter-article-search {
  padding: 51px 0px 42px 0px;
}

.about-creator-img-name::before {
  position: absolute;
  content: "";
  background: #89c400;
  height: 93px;
  width: 16.129px;
  left: -1px;
  top: 0px;
  border-radius: 2px 0px 0px 15px;
}

.about-creator-img img {
  border-radius: 20px;
  width: 410.489px;
}

.inverted-commma-icon {
  text-align: center;
  margin: 60px 0px 0px 0px;
}

.creator-messages {
  margin: 60px 0px 0px 0px;
  background: #f2f2f2;
  border-radius: 0px 10px 10px 10px;
  padding: 36px 53px 36px 52px;
}

.creator-messages h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  text-transform: uppercase;
  color: #194d79;
}

.creator-messages h4 {
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  color: #000;
}

/*********** About Us Page CSS End ***********/

/*********** Newsletter Page CSS Start ***********/

section.your-journalism-sec {
  position: relative;
  padding: 6% 0px 0px 0px;
}

.your-journalism-sec::before {
  background: url(../img/first-banner-img.png) no-repeat top center;
  background-size: cover;
  position: absolute;
  content: "";
  width: 100%;
  height: 400px;
  z-index: -1;
  top: 0px;
}

.your-journalism-sec button.slick-arrow.slick-prev {
  visibility: hidden;
}

.your-journalism-sec button.slick-arrow.slick-next {
  visibility: hidden;
}

.your-journalism-img img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 50px 0px 0px 0px;
}

.journalism-img-txt {
  display: flex;
  align-items: center;
  height: 50vh;
}

.your-journalism-img {
  width: 60%;
  border-radius: 20px 0px 0px 20px;
  overflow: hidden;
  height: 50vh;
}

.your-journalism-txt {
  width: 40%;
  background: #fff;
  padding: 50px 50px;
  border-radius: 0px 10px 10px 0px;
  height: 100%;
}

.your-journalism-img img {
  height: 100% !important;
  min-height: 100%;
  max-height: 100%;
}

.your-journalism-txt h3 {
  font-family: "Public Sans", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  color: #000;
  padding: 0px 20px 0px 0px;
}

.your-journalism-sec ul.slick-dots {
  position: absolute;
  bottom: 30px;
  right: 31%;
  width: auto;
}

.cnter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px !important;
  height: 10px !important;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  background-color: #2b6ca2;
  border-radius: 50px;
}


.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: none !important;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.journal-date-written {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
}

.journal-date-written p {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
}

.journal-date-written p span {
  color: #194d79;
  font-weight: 600;
}

.your-journalism-txt h5 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  height: 170px;
}

.journalism-slider .slick-dots {
  text-align: left;
  bottom: -45px;
}

.newsletter-slide-article img {
  width: 264.901px;
  object-fit: cover;
  border-radius: 20px 0px 0px 20px;
}

.newsletter-article-search input {
  border-radius: 10px;
  padding: 12px 20px;
  width: 70%;
  border: 1px solid #828282;
  background: transparent;
}

.newsletter-article-search input::placeholder {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 33px;
}

.newsletter-article-search {
  position: relative;
  padding: 42px 0px;
}

.newsletter-article-search input:focus {
  outline: none;
}

.articles-writer-sec .filter-short-box {
  padding: 51px 0px 42px 0px;
}

.newsletter-article-search span {
  position: absolute;
  right: 32%;
  top: 48px;
  font-size: 22px;
  color: #828282;
}

.filter-short-box {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  padding: 42px 0px 28px 0px;
}

.filter-box {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  width: 100%;

}

.filter-box-cont-drop {
  display: flex;
  align-items: center;
  gap: 5px;
}

.filter-box p {
  margin: 0px;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: #828282;
}

.filter-box select {
  border: 1px solid #828282;
  padding: 12px 48px 12px 14px;
  border-radius: 10px;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #828282;

}

.filter-box select:focus {
  outline: none;
}

section.latest-article-recommend-sec {
  margin: 0px 0px 0px 0px;
}

.newsletter-latest-article-box h3 {
  font-family: "Public Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #000;
  margin: 0px 0px 36px 0px !important;
}

.latest-article-box img {
  width: 100%;
  border-radius: 24px 24px 0px 0px;
}

.latest-article-box img {
  width: 100%;
  height: 35vh;
}

.newsletter-article-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.latest-article-box img {
  width: 100%;
}

.newsletter-article-text {
  background: #fff;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  padding: 35px 27px 31px 40px;
  border-radius: 0px 0px 20px 20px;
}

.newsletter-article-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 18px 0px;
}

.newsletter-article-date p {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
}

.newsletter-article-date span {
  color: #828282;
}

.newsletter-article-text h4 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  padding: 0px 0px 16px 0px;
}

.newsletter-writtinsoul-txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newsletter-writtinsoul-txt p {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
  margin: 0px;
}

.newsletter-writtinsoul-txt p span {
  color: #194d79;
  font-weight: 600;
}

.newsletter-likecomment-share {
  display: flex;
  gap: 27px;
}

.newsletter-likecomment-share span {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: #828282;
}

.latest-article-box {
  margin: 0px 0px 28px 0px;
  cursor: pointer;
}

.newsletter-slide-article {
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  display: flex;
  margin: 0px 0px 28px 0px;
  cursor: pointer;
}

.newsletter-slide-article .newsletter-article-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0px 20px 20px 0px;
  filter: none;
}

.newsletter-pagination-slide .pagination {
  background: #fff;
  padding: 10px 0px;
  border-radius: 20px;
}

.newsletter-pagination-slide .pagination a {
  color: black;
  float: left;
  padding: 15px 30px;
  text-decoration: none;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 33px;
}

.newsletter-pagination-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 55px 0px 0px 0px;
}

.newsletter-pagination-slide .pagination a:hover {
  background: #f2f2f2;
  padding: 15px 30px;
  border-radius: 10px;
  color: #194d79;
}

.newsletter-pagination-slide .pagination a i {
  color: #194d79;
}

.latest-article-recommend-sec .latesthomepg-line {
  margin: 56px 0px 0px 0px;
}

.newsletter-article-detail .latest-article-box {
  margin: 0px;
}

/*********** Newsletter Page CSS End ***********/

/*********** View Job  Page CSS Start ***********/

.job-start-matter-sec {
  background: url(../img/view-job-bg-img.png) no-repeat top center;
  background-size: cover;
  padding: 63px 50px 67px 50px;
  width: 100%;
  height: 100%;
}

.find-job-matter-txt h4 {
  font-family: "Public Sans", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  margin: 0px 0px 52px 0px !important;
}

.find-job-matter {
  margin: 20px 0px 10px 0px;
}

.find-job-select-box input {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 13px 0px rgba(8, 42, 83, 0.5);
  padding: 20px 40px 20px 50px;
  width: 100%;
  border: none;
}

.find-job-select-box input::placeholder {
  color: #777;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.find-job-select-box input:focus {
  outline: none;
}

.find-job-search-btn a {
  border-radius: 15px;
  background: #89c400;
  box-shadow: 0px 4px 13px 0px rgba(8, 42, 83, 0.5);
  color: #fff;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  padding: 15px 50px;
  transition: all 0.6s ease;
  border: 1px solid #89c400;
}

.find-job-search-btn a i {
  margin-right: 10px;
}

.find-job-btn button {
  color: #fff !important;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  border-radius: 15px;
  background: #89c400 !important;
  box-shadow: 0px 4px 13px 0px rgba(8, 42, 83, 0.5);
  padding: 18px 30px;
  border: 2px solid #89c400 !important;
  gap: 20px;
  transition: all 0.6s ease;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.find-job-btn {}

button.btn svg {
  width: 25px;
  height: 25px;
}

.find-job-btn button:hover {
  background: transparent !important;
  color: #89c400 !important;
}

.find-job-search-btn {
  margin: 15px 0px 0px 0px;
}

.find-job-search-btn a:hover {
  background: transparent;
  color: #89c400;
}

section.search-job-sec {
  padding: 0px;
}

.newsletter-article-search h3 {
  font-family: "Public Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #000;
  margin: 0px !important;
}

.assistant-news-director-box {
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  padding: 42px 30px 27px 32px;
  background: #fff;
  border-radius: 20px;
  margin: 0px 0px 28px 0px;
  cursor: pointer;
  max-height: 500px;
  min-height: 500px;
}

.assistant-logo-txt-more {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.assistant-logo-txt {
  display: flex;
  align-items: center;
  gap: 29px;
}

.assistant-director-txt h4 {
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  color: #000;
  margin: 0px;
}

.assistant-director-txt h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.assistant-director-logo img {
  width: 90.199px;
}

.assistant-more-icon span {
  font-size: 25px;
  color: #828282;
}

.assistant-city-dollar-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 27px 14px 29px;
  margin: 23px 0px 0px 0px;
  border-radius: 10px;
  background: #f2f2f2;
}

.assistant-city-dollar-time p {
  margin: 0px;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #194d79;
}

.assistant-news-director-box ul li {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px 0px 10px 0px;
  list-style: circle;
}

.assistant-news-director-box ul {
  padding: 0px;
  margin: 25px 0px 0px 20px;
}

.actively-hiring-updated {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px 0px 0px;
}

.actively-hiring-updated p {
  margin: 0px;
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: #828282;
}

.actively-hiring-updated a {
  border-radius: 8px;
  background: #9bdb08;
  padding: 10px 20px;
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border: 1px solid #9bdb08;
  transition: all 0.6s ease;
}

.actively-hiring-updated a:hover {
  color: #9bdb08;
  background: transparent;
}

.asistant-pagination {
  margin: 28px 0px 50px 0px;
}

.find-job-select-box {
  position: relative;
  margin: 0px 0px 10px 0px;
}


.find-job-select-box span {
  position: absolute;
  left: 15px;
  top: 17px;
}

body {
  background-color: #f5f5f5;
}

/*********** View Job  Page CSS ENd ***********/

/*********** Shop Page CSS Start ***********/

.shop-first-banner-sec {
  background: url(../img/first-banner-img.png) no-repeat top center;
  background-size: cover;
  padding: 20px 0px 35px 0px;
  width: 100%;
  height: 100%;
}

.shop-new-arrival {
  background: #fff;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  padding: 50px 40px;
  position: relative;
  border-radius: 20px;
  height: 520px;
}

.shop-new-arrival h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30.5px;
  color: #89c400;
  margin: 0px 0px 14px 0px;
}

.shop-new-arrival h3 {
  font-family: "Public Sans", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 63px;
  color: #000;
  width: 460px;
  padding: 0px 0px 14px 0px;
}

.shop-new-arrival p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  width: 350px;
  margin: 10px 0px 80px 0px !important;
}

.shop-new-arrival a {
  color: #194d79;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border: 1px solid #194d79;
  padding: 12px 25px;
  border-radius: 10px;
  transition: all 0.6s ease;
}

.shop-new-arrival a:hover {
  background: #194d79;
  color: #fff;
}

.shop-new-arrival-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.shop-new-arrival-img img {
  width: 290px;
  border-radius: 0px 0px 20px 0px;
}

.shop-friend-themed {
  padding: 24px 30px 28px 24px;
  background: #fff;
  margin: 0px 0px 20px 0px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  height: 250px;
}

.shop-friend-themed h4 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  margin: 0px 0px 70px 0px !important;
}

.shop-friend-themed a {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: #194d79;
  text-decoration-line: underline;
}

.themed-abslt-img {
  position: absolute;
  top: 20px;
  right: 20px;
}

.themed-abslt-img img {
  width: 230px;
}

.themed-cap {
  right: 0px;
  top: 30px;
}

.themed-cap img {
  width: 280px;
}

section.shop-product-search-sec {
  padding: 0px;
}

.heading-see-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading-see-all h3 {
  font-family: "Public Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #000;
  padding-bottom: 45px;
}

.heading-see-all a {
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: #194d79;
}

.shop-category {
  display: flex;
  gap: 70px;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 20px 0px;
}

.shop-category ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px;
  flex-wrap: wrap;
  gap: 20px;
}

.shop-category h3 {
  font-family: "Public Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #000;
}

.shop-category ul li button {
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: #828282;
  transition: all 0.6s ease;
  border: none;
}

.shop-category ul li button:hover {
  color: #194d79;
}

/*********** Shop Page CSS End ***********/

/*********** Newsletter Article Page CSS Start ***********/

.newsletter-article-detail .newsletter-article-text {
  background: transparent;
  filter: none;
}

.newsletter-article-detail {
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  background: #fff;
  border-radius: 20px;
}

.newsletter-article-decided h4 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin: 0px 0px 20px 0px !important;
}

.newsletter-article-decided p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  padding: 0px 0px 20px 0px;
}

.newsletter-article-decided {
  padding: 34px 0px 30px 0px;
}

.article-reactions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px 30px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.article-reactions ul {
  display: flex;
  align-items: center;
  gap: 15px;
}

.article-reactions ul li a {
  color: #828282;
  font-size: 22px;
}

/*********** Newsletter Article Page CSS End ***********/

/*********** Contact Us Page CSS Start ***********/

.ratemystation-contact-sec {
  position: relative;
  padding: 80px 0px 10px 0px;
}

.ratemystation-contact-sec::before {
  position: absolute;
  content: "";
  background: url(../img/first-banner-img.png) no-repeat top center;
  background-size: cover;
  height: 456.9px;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.ratemystaion-contact-form h3 {
  font-family: "Public Sans", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 63px;
  color: #000;
  padding: 0px 0px 16px 0px;
}

.ratemystaion-contact-form p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px 0px 181px 0px !important;
}

.ratemystaion-contact-form ul li {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin: 0px 0px 28px 0px;
}

.contact-form-station {
  padding: 20px 20px 22px 20px;
  background: #fff;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
}

.ratemystaion-contact-form ul {
  padding: 0px;
  margin: 0px;
}

.ratemystaion-contact-form ul li svg {
  margin-right: 10px;
  color: #194d79;
  font-size: 25px;
}

.ratemystaion-contact-form {
  padding: 15px 50px 50px 40px;
}

.contact-form-fields {
  padding: 30px 40px 38px 50px;
}

.input-field label {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin: 0px 0px 10px 0px;
}

.input-field input {
  border-radius: 10px;
  background: #f2f2f2;
  display: block;
  padding: 14px 20px;
  width: 100%;
  border: none;
}

.input-field input:focus {
  outline: none;
}

.input-field input::placeholder {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
}

.input-field {
  margin: 0px 0px 20px 0px;
}

.input-field textarea {
  display: block;
  width: 100%;
  border-radius: 10px;
  padding: 10px 20px;
  height: 200px;
  border: none;
  background: #f2f2f2;
}

.input-field textarea:focus {
  outline: none;
}

.input-field textarea::placeholder {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
}

.contact-form-btn button {
  color: #194d79;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  border: 2px solid #194d79;
  padding: 5px 70px;
  transition: all 0.6s ease;
  background: transparent;
}

.contact-form-btn {
  margin: 30px 0px 0px 0px;
}

.contact-form-btn button:hover {
  background: #194d79;
  color: #fff;
}

.contact-form-btn button:focus {
  outline: none;
}

.ratemystaion-contact-form ul li:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-follow-like-btn ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px;
  margin: 0px;
  gap: 68px;
}

.contact-latest-updates {
  padding: 20px 50px 20px 20px;
}

.contact-latest-updates p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.contact-follow-like-btn ul li {
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: #fff;
  border-radius: 10px;
  padding: 15px 40px 15px 40px;
}

.contact-follow-like-btn ul li:nth-child(1) {
  background: #1877f2;
}

.contact-follow-like-btn ul li:nth-child(2) {
  background: #ee2987;
}

.contact-follow-like-btn ul li:nth-child(3) {
  background: #55acee;
}

.contact-follow-topline {
  padding: 25px 0px 0px 0px;
  border-top: 1px solid #e0e0e0;
}

footer {
  width: 100%;
}

.main-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px 30px 10px;
}

.ul-li-list-footer {
  width: 70%;
}

.ul-li-list-footer ul {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 35px;
  margin: 0px;
}

.icon-list-footer {
  display: flex;
  align-items: center;
  gap: 30px;
}

.icon-list-footer svg:hover path {
  fill: #89c400;
}

.ul-li-list-footer ul li a {
  color: var(--Gray-3, #828282) !important;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  text-decoration: none;
}

.ul-li-list-footer ul li a:hover {
  cursor: pointer;

  color: black !important;
}

/*********** Contact Us Page CSS End ***********/

/*********** Newsletter Article Page CSS Start ***********/

.newsletter-long-txt {
  padding: 0px 41px 0px 40px;
}

.newsletter-article-slider {
  border-top: 2px solid #e0e0e0;
}

.newsletter-detail-comment {
  display: flex;
  align-items: flex-start;
  gap: 19px;
  margin: 0px 0px 28px 0px;
}

.newsletter-detail-comment-para {
  padding: 18px 25px 1px 26px;
  background: #f2f2f2;
  border-radius: 0px 10px 10px 10px;
}

.newsletter-detail-comment-para h6 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.newsletter-detail-comment-para p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 26px !important;
  margin: 0px 0px 10px 0px !important;
}

.newsletter-article-sec {
  padding: 50px 0px 56px 0px;
  position: relative;
}

.newsletter-comment-reaction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px 5px 10px;
}

.newsletter-comment-reaction ul {
  display: flex;
  align-items: center;
  gap: 20px;
}

.newsletter-comment-reaction ul li a {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.newsletter-comment-box {
  padding: 30px 0px 60px 0px;
}

.newsletter-comment-box p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin: 0px 0px 28px 0px !important;
}

.newsletter-comment-input img {
  width: 48.076px;
}

.newsletter-comment-reaction p {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
}

.newsletter-comment-input {
  display: flex;
  align-items: center;
  gap: 19px;
  width: 100%;
}

.newsletter-comment-input input {
  border-radius: 10px;
  background: #f2f2f2;
  padding: 12px 20px;
  width: 100%;
  border: none;
}

.newsletter-comment-typebox {
  width: 100%;
  position: relative;
}

.newsletter-comment-input input::placeholder {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
}

.newsletter-comment-typebox button {
  position: absolute;
  top: 5px;
  right: 20px;
  background: transparent;
  border: none;
  color: #194d79;
  font-size: 25px;
}

.newsletter-comment-typebox input:focus {
  outline: none;
}

.other-newsletter-articles {
  padding: 10px 0px 40px 0px;
  border-top: 2px solid #e0e0e0;
}

.soul-witness-acc-subs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px 0px 0px;
}

.soul-witness-user-imgtxt {
  display: flex;
  gap: 21px;
}

.soul-witness-user-imgtxt img {
  width: 60.9px;
}

.more-soul-article img {
  width: 144.4px;
}

.soul-witness-txt h4 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin: 0px;
}

.soul-witness-txt h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
}

.soul-witness-acc-subs a {
  color: #194d79 !important;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  padding: 6px 54px;
  border: 2px solid #194d79;
  border-radius: 10px;
  transition: all 0.6s ease;
  cursor: pointer;
}

.soul-witness-acc-subs a:hover {
  background: #194d79;
  color: #fff !important;
}

.more-from-soul-witness-box {
  padding: 38px 42px 38px 40px;
  margin: 30px 0px 0px 0px;
  background: #f2f2f2;
  border-radius: 15px;
}

.more-from-soul-witness-box h5 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin: 0px 0px 27px 0px !important;
}

.more-soul-articletxt h4 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin: 0px 0px 20px 0px !important;
}

.more-soul-articletxt span {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
}

.more-soul-article {
  display: flex;
  align-items: center;
  gap: 32px;
  margin: 17px 0px 0px 0px;
}

.newsletter-input-search {
  margin: 10px 0px 20px 0px;
  position: relative;
}

.newsletter-input-search input {
  padding: 10px 20px;
  width: 100%;
  background: #f5f5f5;
  border: 1px solid #828282;
  border-radius: 10px;
}

.newsletter-input-search span {
  position: absolute;
  top: 6px;
  right: 12px;
  font-size: 22px;
  color: #828282;
}

.newsletter-input-search input:focus {
  outline: none;
}

/*********** Newsletter Article Page CSS End ***********/

/*********** View Job Detail Page CSS Start ***********/

.view-job-detail-sec {
  position: relative;
  padding: 50px 0px 59px 0px;
}

.view-job-detail-sec::before {
  position: absolute;
  content: "";
  background: url(../img/first-banner-img.png) no-repeat top center;
  background-size: cover;
  height: 293.082px;
  width: 100%;
  z-index: -1;
  top: 0px;
}

.job-detail-name-description {
  background: #fff;
  padding: 70px 49px 67px 59px;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  margin: 50px 0px 0px 0px;
}

.job-detailpg-txt h3 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  padding: 0px 0px 13px 0px;
}

.job-detailpg-txt h5 {
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
}

.job-detailpg-nameflex {
  display: flex;
  align-items: self-start;
  gap: 20px;
  width: 100%;
}

.job-detailpg-txt {
  width: 100%;
}

.job-select-opts a {
  background: #194d79;
  padding: 6px 38px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border: 1px solid #194d79;
  transition: all 0.6s ease;
  margin: 0px 0px 11px 0px !important;
  width: 239px;
}

.job-save-share {
  margin: 12px 0px 0px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.job-select-opts a:focus {
  outline: none;
}

.job-select-opts a:hover {
  background: transparent;
  color: #194d79;
}

.job-select-opts {
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  align-items: end;
}

.job-select-opts a svg {
  margin: 0px 13px 0px 0px;
  width: 15.635px;
}

.job-detailpg-nameflex img {
  width: 137.856px;
}

.job-opening-hr {
  padding: 12px 0px 0px 0px;
}

.job-save-share a {
  background: transparent;
  border: 1px solid #bdbdbd;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  color: #000;
  padding: 8px 24px 8px 15px;
  width: auto;
}

.job-save-share a i {
  color: #828282;
  margin: 0px 5px 0px 0px;
}

.job-desceiption-detail hr {
  margin: 25px 0px 45px 0px;
}

.job-desceiption-detail h3 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  margin: 0px 0px 32px 0px !important;
}

.job-desceiption-detail p {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  padding: 0px 0px 20px 0px;
}

.view-job-detail-description {
  background: #fff;
  padding: 32px 29px 35px 30px;
  border-radius: 20px;
  margin: 41px 0px 0px 0px;
}

.job-qualification-point {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0px 0px 0px;
}

.job-qualification-detail h4 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
}

.job-qualification-point ul {
  margin: 0px 0px 0px 30px;
}

.job-qualification-point ul li {
  list-style: disc;
  margin: 5px 0px;
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.additional-information-job {
  margin: 30px 0px 0px 0px;
}

.additional-information-job h4 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  padding: 0px 0px 20px 0px;
}

.job-qualification-detail hr {
  margin: 45px 0px 45px 0px;
}

.job-add-career h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
  margin: 0px;
}

.job-add-career h5 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.job-add-career {
  margin: 20px 0px 10px 0px;
}

.additional-information-job hr {
  margin: 35px 0px 23px 0px;
}

.addit-report-btn a {
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  color: #828282;
}

.view-job-detail-sec::after {
  position: absolute;
  content: "";
  background: #bdbdbd;
  height: 1px;
  width: 90%;
  left: 50%;
  transform: translate(-50%);
  bottom: -6px;
}

.newsletter-article-sec::before {
  position: absolute;
  content: "";
  background: #bdbdbd;
  height: 1px;
  width: 90%;
  left: 50%;
  transform: translate(-50%);
  bottom: -6px;
}

/*********** View Job Detail Page CSS End ***********/

/*********** Job Opening Page CSS Start ***********/

.job-opening-sec {
  padding: 50px 0px 0px 0px;
  position: relative;
}

.job-opening-sec::before {
  position: absolute;
  content: "";
  background: url(../img/first-banner-img.png) no-repeat top center;
  background-size: cover;
  height: 293.082px;
  width: 100%;
  z-index: -1;
  top: 0px;
}

.job-openingpg-txt h5 {
  font-family: "Public Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #828282;
  padding: 0px 0px 13px 0px;
}

.job-openingpg-txt h3 {
  font-family: "Public Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  color: #194d79;
  padding: 0px 0px 13px 0px;
}

.job-openingpg-txt h3 svg {
  color: #89c400;
  width: 20.998px;
}

.job-openingpg-txt h6 {
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: #194d79;
}

.rms-kabc-btn .themelight {
  border: 1px solid #194d79;
  background: transparent !important;
  color: #194d79 !important;
  padding: 8px 30px;
  border-radius: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  transition: all 0.6s ease;
}

.themelight {
  background: transparent !important;
  color: #194d79 !important;
}

.themelight:hover {
  background: #194d79 !important;
  color: #fff !important;
}

.newsletter-article-search h4 {
  color: #000;
  font-family: "Public Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  gap: 10px;
}

.newsletter-article-search h4 p {
  color: #828282;
  font-family: "Public Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/*********** Job Opening Page CSS End ***********/

/*********** Station Search Result Page CSS Start ***********/

.station-search-result-txt h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0px 0px 14px 0px;
}

.station-search-result-txt p {
  color: var(--Gray-2, #4f4f4f);
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.station-search-result-txt p span {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.station-search-result-txt {
  padding: 30px 0px 43px 0px;
}

.rms-profile-box {
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  background: #fff;
  padding: 39px 13px 32px 31px;
  border-radius: 20px;
  position: relative;
  margin: 0px 0px 27px 0px;
}

.rms-profile-logo-box {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rms-kabc-txt h3 {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
}

.rms-abc-logo img {
  width: 137.856px;
}

.rms-kabc-txt h6 {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px 0px 29px 0px;
}

.rms-kabc-btn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rms-kabc-btn .themedark {
  color: #fff !important;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  background: #194d79 !important;
  padding: 8px 30px;
  transition: all 0.6s ease;
  border: 1px solid #194d79;
  margin: 0px !important;
}


input#gridCheck {border: 1px solid #BDBDBD !important;padding: 14px !important;}


.rms-kabc-btn a:hover {
  background: transparent;
  color: #194d79;
}

.rms-kabc-txt h3 svg {
  color: #89c400;
}

.rms-rating-review p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px 0px 6px 0px !important;
}

.rms-rating-review p span {
  color: #000;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.rms-dots-icon {
  position: absolute;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
}

.rms-dots-icon span {
  color: #828282;
  font-size: 24px;
}

.seeprofile-box-btn a {
  color: #fff;
  text-align: center;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 40px;
  background: var(--194-d-79, #194d79);
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
  padding: 12px 35px;
  border: 1px solid #194d79;
  transition: all 0.6s ease;
}

.seeprofile-box-btn a:hover {
  background: transparent;
  color: #194d79;
}

.search-station-sec {
  padding: 0px 0px 70px 0px;
}

.find-station-looking {
  background: url(../img/findstations-img.png) no-repeat top center;
  background-size: cover;
  padding: 66px 38px 71px 38px;
  text-align: center;
  border-radius: 20px;
}

.find-station-looking h4 {
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 34px 0px !important;
}

.find-station-looking p {
  color: #fff;
  text-align: center;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.5px;
  margin: 0px 0px 55px 0px !important;
}

.find-station-looking a {
  color: var(--000000, #000);
  text-align: center;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 37.5px;
  background: #fff;
  padding: 15px 35px;
  border-radius: 10px;
  border: 1px solid #fff;
  transition: all 0.6s ease;
}

.find-station-looking a:hover {
  background: transparent;
  color: #fff;
}

.seeprofile-box-btn {
  text-align: center;
  margin: 46px 0px 56px 0px;
}

/*********** Station Search Result Page CSS End ***********/

/*********** News Director Search Result Page CSS Start ***********/

.news-director-user-card {
  display: flex;
  align-items: center;
  gap: 28px;
}

.news-director-card-txt h5 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
}

.news-director-card-txt p {
  color: #777;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.news-director-card-txt p span {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.news-director-card {
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  background: #fff;
  padding: 32px 32px 28px 29px;
  border-radius: 20px;
  margin: 0px 0px 30px 0px;
}

.news-director-user-card img {
  width: 92.72px;
}

.news-director-detail-btn a {
  color: #194d79;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}

.news-director-detail-btn a {
  color: #194d79;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border: 1px solid #194d79;
  padding: 6px 50px;
  border-radius: 10px;
}

.news-director-detail-btn {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
}

section.director-search-result {
  margin: 0px 0px 30px 0px;
}

section.director-search-result hr {
  margin: 0px 0px 0px 0px;
}

.americon-prod-card-txt h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 29px 0px !important;
}

.americon-product-card-sec {
  padding: 41px 0px 5px 0px;
}

.product-quality-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 130px 26px 0px;
  width: 54%;
}

.mycart-qualitytotal {
  display: flex;
  gap: 108px;
}

.product-quality-total h4 {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

/*********** News Director Search Result Page CSS End ***********/

/*********** My Cart Page CSS Start ***********/

.americon-prod-cart {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 32px 31px 32px 27px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  margin: 0px 0px 27px 0px;
}

.americon-prod-cart img {
  width: 142.488px;
  padding: 0px 20px 0px 0px;
}

.cart-counter {
  display: flex;
  gap: 10px;
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  padding: 12px 25px;
}

.cart-counter input {
  width: 60px;
  text-align: center;
  border: none;
  background: transparent;
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.americon-prod-cart h3 {
  color: #000;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  margin: 0px;
  width: 210px;
  text-transform: capitalize;
}

.cart-counter span {
  color: #828282;
}

.cart-counter input:focus {
  outline: none;
}

.americon-prod-cart h4 {
  color: #000;
  text-align: center;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  padding: 0px 0px 0px 10px;
}

.mycart-order-summary {
  border-radius: 20px;
  background: #fff;
  padding: 27px 24px 35px 35px;
  margin: 0px 0px 0px 0px;
}

.mycart-order-summary h4 {
  color: #000;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  margin: 0px 0px 40px 0px !important;
}

.ordersummary-subtotal h6 {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px;
}

.ordersummary-subtotal p {
  color: #000;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin: 0px;
}

.ordersummary-subtotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 21px 0px;
}

.mycart-order-summary input {
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  width: 100%;
  padding: 15px 20px;
  border: none;
}

.mycart-order-summary input:focus {
  outline: none;
}

.mycart-order-summary input::placeholder {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.prod-order-total-txt {
  margin: 33px 0px 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prod-order-total-txt h5 {
  color: #000;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.5px;
  margin: 0px;
}

.mycart-order-summary a {
  color: #194d79;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
  width: 100%;
  display: block;
  border-radius: 10px;
  border: 2px solid #194d79;
  padding: 6px 85px;
  margin: 30px 0px 0px 0px;
  transition: all 0.6s ease;
}

.mycart-order-summary a:hover {
  background: #194d79;
  color: #fff;
}

/*********** My Cart Page CSS ENd ***********/

/*********** Employer Login Page CSS Start ***********/

.rating-real-journalist {
  background: url(../img/employer-halfpg.png) no-repeat top center;
  background-size: cover;
  padding: 205px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
}

.rating-real-journalist h3 {
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.employer-login-form {
  padding: 10px 0px 147px 0px;
}

section.employer-login-pg-sec {
  padding: 66px 0px 5px 0px;
}

.rating-real-journalist img {
  width: 177.797px;
  margin: 0px 0px 10px 0px;
}

.employer-login-box {
  background: #fff;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  border-radius: 20px;
}

.employer-newroom-box {
  padding: 5px 44px 0px 42px;
}

.employer-inputfield input {
  display: block;
  width: 100%;
  padding: 15px 20px;
  background: var(--Gray-6, #f2f2f2);
  border-radius: 10px;
  border: none;
}

.employer-inputfield {
  margin: 30px 0px;
}

.employer-inputfield label {
  margin: 0px 0px 9px 0px;
  color: #000;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.employer-inputfield input::placeholder {
  color: #777;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.employer-inputfield input:focus {
  outline: none;
}

.employer-login-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.employerloginbtn {
  color: #194d79;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  border: 2px solid #194d79;
  padding: 4px 50px;
  transition: all 0.6s ease;
}

.employerloginbtn:hover {
  background: #194d79;
  color: #fff;
}

.employerforgotbtn {
  color: #777;
  text-align: right;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.employer-newroom-box button {
  color: #828282;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  background: transparent;
  border: none;
  padding: 24px 70px !important;
  border-bottom: 7px solid #f2f2f2;
}

.employer-newroom-box button.text-white {
  color: #89c400 !important;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom: 7px solid #89c400;
}

.employer-dont-have-acc a span {
  color: #89c400;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-decoration-line: underline;
}

.employer-dont-have-acc a {
  color: #777;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  display: inline-block;
}

.employer-dont-have-acc a span {
  color: #89c400;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-decoration-line: underline;
}

.employer-dont-have-acc {
  margin: 0px 0px 20px 50px;
}

.employer-newroom-box button:focus {
  outline: none;
}

/*********** Employer Login Page CSS End ***********/

/*********** Employer Register Page CSS Start ***********/

.employerregister-field {
  margin: 10px 0px;
}

.employer-register-subscription h3 {
  color: #194d79;
  font-family: "Public Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.employer-register-subscription {
  padding: 36px 20px 30px 20px;
  margin: 20px 0px 0px 0px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  text-align: center;
}

.employer-choose-subscription h6 {
  color: #000;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px;
}

.employer-subscription {
  margin: 20px 0px 0px 0px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 22px 20px;
  text-align: center;
  position: relative;
  width: 100%;
}

.employer-subscription h5 {
  color: #194d79;
  text-align: center;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin: 0px;
}

.employer-subscription h6 {
  color: #828282;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px 0px 10px 0px;
}

.free-subscribe-four {
  display: flex;
  align-items: center;
  gap: 10px;
}

.employer-subscription span {
  color: #000;
  text-align: center;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.employer-save {
  position: absolute;
  border-radius: 8px;
  background: #194d79;
  color: #fff;
  text-align: center;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  left: 50%;
  transform: translate(-50%);
  padding: 5px 4px;
  width: 80px;
  bottom: -8px;
}

.employer-register-form form:focus {
  outline: none;
}

.employer-register-btn {
  display: flex;
  gap: 35px;
  margin: 25px 0px 35px 50px;
}

.employer-register-btn a {
  color: #194d79;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  border: 2px solid #194d79;
  padding: 6px 60px;
  transition: all 0.6s ease;
}

.employer-input-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.employer-register-btn a:hover {
  background: #194d79;
  color: #fff;
}

.employer-input-checkbox label {
  color: #000;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px;
}

.employer-input-checkbox input {
  width: 18px;
  height: 18px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
}

/*********** Employer Register Page CSS End ***********/

/*********** Subscribption Page CSS Start ***********/

.subcription-page-sec {
  position: relative;
  padding: 80px 0px 6px 0px;
}

.subcription-page-sec::before {
  position: absolute;
  content: "";
  background: url(../img/first-banner-img.png) top center;
  background-size: cover;
  height: 456.9px;
  width: 100%;
  top: 0px;
  z-index: -1;
  transform: rotate(180deg);
}

.subcribe-now-box {
  padding: 35px 29px 32px 28px;
  background: #fff;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
}

.subscribe-now-txt h3 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 63px;
  padding: 0px 0px 16px 0px;
}

.subscribe-now-txt p {
  color: #000;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px 0px 27px 0px !important;
}

.subscribe-now-txt ul li {
  color: #000;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  padding: 9px 0px;
  position: relative;
}

.subscribe-now-txt ul li::before {
  position: absolute;
  content: "";
  color: #000;
}

.subscribe-now-txt ul li svg {
  position: absolute;
  left: -26px;
  top: 12px;
}

.subcription-box {
  border-radius: 10px;
  border: 2px solid var(--Gray-5, #e0e0e0);
  padding: 30px 20px 30px 20px;
  text-align: center;
  position: relative;
  transition: all 0.6s ease;
}

.subcription-box h4 {
  color: var(--194-d-79, #194d79);
  text-align: center;
  font-family: "Public Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  padding: 60px 0px 9px 0px;
}

.subcription-box h6 {
  color: var(--Gray-3, #828282);
  text-align: center;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px 0px 55px 0px;
}

.subcription-box h5 {
  color: #000;
  text-align: center;
  font-family: "Public Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  padding: 0px 0px 80px 0px;
}

.subcription-box a {
  color: #194d79 !important;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border: 2px solid #194d79;
  border-radius: 10px;
  transition: all 0.6s ease;
  padding: 12px 54px;
}

.subcription-box a:hover {
  background: #194d79;
  color: #fff;
}

.subcription-box::before {
  position: absolute;
  content: "Starter";
  background: #e0e0e0;
  width: 175.77px;
  height: 42.658px;
  top: 0px;
  left: 50%;
  transform: translate(-50%);
  border-radius: 0px 0px 15px 15px;
  color: var(--Gray-3, #828282);
  text-align: center;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  padding: 6px 0px 0px 0px;
  transition: all 0.6s ease;
}

.recommend-box::before {
  content: "Recomendation";
}

.best-value-box::before {
  content: "Best Value";
}

.subcription-box span {
  color: #fff;
  text-align: center;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translate(-50%);
  width: 150px;
  border-radius: 8px;
  background: #89c400;
  padding: 6px 5px;
}

.new-subscription-discount {
  margin: 23px 0px 0px 0px;
  border-radius: 10px;
  background: #89c400;
  padding: 15px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-subscription-discount p {
  color: #fff;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px;
}

.subscribe-now-txt ul {
  padding: 0px 0px 0px 20px;
}

.subcription-box:hover {
  border-color: #194d79;
}

.subcription-box:hover:before {
  background: #194d79;
  color: #fff;
}

.new-subscription-discount p span {
  font-weight: bold;
}

.new-subscription-discount a {
  color: #fff;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  border-radius: 5px;
  border: 1px dashed #fff;
  padding: 10px 25px;
}

.station-secure-box {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 37px 0px 0px 0px;
}

.station-secure-box {
  display: flex;
  align-items: center;
  gap: 20px;
}

.station-secure-box p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px;
  padding: 0px 60px 0px 0px;
}

.station-secure-box svg {
  width: 50px;
  height: 50px;
}

.subscribtion-credit-box {
  margin: 52px 0px 0px 0px;
  border-top: 1px solid #e0e0e0;
}

.subscription-credit p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  padding: 0px 40px 0px 0px;
}

.subscription-credit {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 18px 0px 0px 0px;
}

.subscription-credit img {
  width: 250px;
}

/*********** Subscribption Page CSS End ***********/

/*********** Submit A Station Form Page CSS Start ***********/

.submit-station-form-sec {
  padding: 50px 0px;
}

.submit-station-box {
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
}

.submit-select-svg {
  position: absolute;
  top: 44px;
  left: 15px;
}

.submit-staion-form h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 40px 0px !important;
  padding: 0px 0px 30px 0px;
  position: relative;
}

.submit-staion-form h2::before {
  position: absolute;
  content: "";
  background: #bdbdbd;
  width: 100%;
  height: 2px;
  bottom: 0px;
}

.submit-staion-field input {
  width: 100%;
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  display: block;
  border: none;
  padding: 15px 25px;
}

.submit-staion-field label {
  color: #000;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px 0px 6px 0px;
}

#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition-duration: 0.5s;
}

.rms--toggle {
  width: 27px;
  height: fit-content;
  gap: 6px;
}

.bars {
  width: 100%;
  height: 3px;
  background-color: rgb(25 77 121);
  border-radius: 4px;
}

#bar2 {
  transition-duration: 0.8s;
}

#bar1,
#bar3 {
  width: 70%;
}


/* #checkbox:checked + .toggle .bars {
  position: absolute;
  transition-duration: .5s;
}

#checkbox:checked+.toggle #bar2 {
  transform: scaleX(0);
  transition-duration: 0.5s;
}

#checkbox:checked+.toggle #bar1 {
  width: 100%;
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

#checkbox:checked+.toggle #bar3 {
  width: 100%;
  transform: rotate(-45deg);
  transition-duration: 0.5s;
}

#checkbox:checked+.toggle {
  transition-duration: 0.5s;
  transform: rotate(180deg);
} */

.submit-staion-field select {
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  width: 100%;
  display: block;
  padding: 15px 25px 15px 45px;
  border: none;
}

.submit-staion-field select option {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.submit-staion-field select:focus {
  outline: none;
}

.submit-staion-field input:focus {
  outline: none;
}

.submit-staion-field {
  margin: 0px 0px 40px 0px;
  position: relative;
}

.submit-station-btn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.submit-checkbox {
  display: flex;
  align-items: center;
}

.submit-checkbox input[type="checkbox"] {
  display: none;
}

.submit-checkbox label {
  cursor: pointer;
  padding-left: 30px;
  position: relative;
}

.submit-checkbox label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 3px;
}

.submit-checkbox input[type="checkbox"]:checked+label::before {
  border-color: #89c400;
  background: #89c400;
}

.submit-checkbox label::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 2px;
  width: 8px;
  height: 13px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  opacity: 0;
  transform: rotate(45deg);
}

.submit-checkbox input[type="checkbox"]:checked+label::after {
  opacity: 1;
}

.submit-station-btn .submitbtn-dark {
  color: #fff;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  background: #194d79;
  border: 1px solid #194d79;
  transition: all 0.6s ease;
}

.submit-station-btn .submitbtn-dark:hover {
  background: transparent;
  color: #194d79;
}


nav.helo-drop label {
  font-weight: bold;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.user--pic--header {
  width: 43%;
  border-radius: 5px;
}
.submit-station-btn {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* body {background-color : #ededed; font-family : "Open Sans", sans-serif;}

h1 {padding: 40px; text-align: center; font-size: 1.5em;}

li a {text-decoration : none; color : #2d2f31;} */

nav.helo-drop {
  position: relative;
}

.drop-out {
  padding: 10px;
  cursor: pointer;
  display: block;
  width: 100%;
}

.slide {
  clear: both;
  width: 100%;
  height: 0px;
}

.drop-out {
  font-size: 16px;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.drop-out::after {
  float: right;
  right: 10%;
  content: ">";
  transform: rotate(90deg);
  translate: 10px;
  font-family: cursive;
}

.slide {
  clear: both;
  width: max-content;
  height: 0;
  overflow: hidden;
  text-align: center;
  transition: all 0.4s ease;
  position: absolute;
  background: white;
  top: 55px;
  border-radius: 8px;
  box-shadow: 0px 4px 13px 0px #00000026;
  z-index: 2;
  right: 0px;
}

ul.slide a {
  color: black;
  font-weight: bold;
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: center;
  gap: 5px;
}


ul.slide svg {
  width: 25px;
  padding: 0px;
}

.slide hr {
  margin: 0;
}

.slide li {
  width: 100%;
  padding: 10px;
  text-align: left;
}

.slide li:hover {
  background-color: #e0e0e0;
  border-radius: 5px;
}

#touch {
  position: absolute;
  opacity: 0;
  height: 0px;
}

#touch:checked+.slide {
  height: max-content;
  padding: 10px;
}

#touch {
  position: absolute;
  opacity: 0;
  height: 0px;
}

#touch:checked+.slide {
  height: fit-content;
}

.submit-station-btn .submitbtn-dark {
  color: #fff;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  background: #194d79;
  padding: 6px 40px;
  border: 1px solid #194d79;
  transition: all 0.6s ease;
}

.submit-station-btn .submitbtn-dark:hover {
  background: transparent;
  color: #194d79;
}

.submit-station-btn .submitbtn-light {
  color: #194d79;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  border: 2px solid #194d79;
  transition: all 0.6s ease;
  padding: 6px 40px;
}

.submit-station-btn .submitbtn-light:hover {
  background: #194d79;
  color: #fff;
}

.submit-checkbox {
  display: flex;
  gap: 10px;
}

.submit-checkbox input {
  width: 20px;
  height: 25px;
}

.submit-checkbox label {
  color: #000;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

ul.rms--header--slide li a {
  color: #000;
}

/*********** Submit A Station Form Page CSS End ***********/

/*********** Product Dashboard Page CSS Start ***********/

.dashboard_product_head {
  padding: 0px 0px 33px 0px;
}

.dashboard_product_head h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dashboard_addnewprod {
  margin: 27px 0px 0px 0px;
}

.addnewprod_head a {
  color: var(--ffffff, #fff);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 43px;
  border-radius: 10px;
  background: #194d79;
  padding: 12px 24px 12px 21px;
  border: 1px solid #194d79;
  transition: all 0.6s ease;
}

.addnewprod_head a svg {
  margin: -2px 13px 0px 0px;
  width: 22.507px;
  height: 22.507px;
}

.addnewprod_head a:hover {
  background: transparent;
  color: #194d79;
}

.dashprod_filter_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 23px;
}

.dashfilter-select-box label {
  color: var(--Gray-3, #828282);
  text-align: right;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.dashfilter-select-box {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dashfilter-select-box select {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 9px 50px 11px 16px;
  border-radius: 10px;
  border: 1px solid var(--Gray-3, #828282);
  background: #f2f2f2;
}

.dashstock_box select {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 9px 50px 11px 16px;
  border-radius: 10px;
  border: 1px solid var(--Gray-3, #828282);
  background: #f2f2f2;
}

.dashfilter-stock {
  display: flex;
  align-items: center;
  gap: 18px;
}

.product_dashboard_box {
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  background: #fff;
  padding: 22px 32px 29px 31px;
  border-radius: 10px;
  width: 100%;
  overflow-x: scroll;
}

.product_dashboard_header_left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.product_dashboard_header_left input {
  width: 23px;
  height: 23px;
  border-radius: 5px;
  border: 1px solid var(--Gray-5, #e0e0e0);
}

.product_dashboard_header_left select {
  border: none;
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.product_dashboard_header_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prodash_headright_select select {
  border: none;
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.product_dashboard_header {
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 0px 17px 0px;
}

.inline_product {
  margin: 22px 0px 0px 0px;
}

.inlineprod_save {
  display: flex;
  align-items: start;
  gap: 20px;
}

.inlineprod_namebox {
  display: flex;
  align-items: start;
  gap: 10px;
}

.inlineprod_namebox img {
  width: 44px;
}

.inlineprod_name h3 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 0px 0px 7px 0px;
  width: 290px;
}

.inlineprod_name p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inlineprod_save input {
  width: 23px;
  height: 23px;
  margin: 10px 0px 0px 0px;
  cursor: pointer;
}

.inlinprod_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px 0px 0px;
}

.inlineprod_instock h6 {
  color: var(--89-c-400, #89c400);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  padding: 4px 15px;
  border-radius: 10px;
  background: rgba(137, 196, 0, 0.1);
  margin: 0px 20px 0px 0px;
}

.inlineprod_price p {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  padding: 0px 20px 0px 0px;
}

.inlineprod_category p {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 130px;
  text-align: center;
  padding: 0px 50px 0px 0px;
}

.inlineprod_dtpub p {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}

.inlineprod_action {
  margin: 0px 0px 0px 80px;
  border-radius: 10px;
  background: #f2f2f2;
  padding: 6px 10px;
  color: #828282;
}

.inlineprod_instock h5 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}

.inlineprod_amout {
  margin: 0px 50px 0px 40px;
}

.inlineprod_allowed {
  margin: 0px 80px 0px 30px;
}

/*********** Product Dashboard Page CSS End ***********/

/*********** Order Dashboard Page CSS Start ***********/

.order_dashboard_header_right {
  display: flex;
  align-items: center;
  gap: 130px;
}

.prodash_status_select select {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}

.order-header-select {
  display: flex;
  align-items: center;
  gap: 49px;
}

.order-number-name {
  display: flex;
  align-items: center;
  gap: 52px;
  margin: 5px 0px 0px 0px;
}

.order-number-name h5 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}

.order-number-name h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}

.inlineprod_completed h6 {
  color: var(--89-c-400, #89c400);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  border-radius: 10px;
  background: rgba(137, 196, 0, 0.1);
  width: 115px;
  height: 33px;
  text-align: center;
}

.inlineprod_processing h6 {
  color: var(--Yellow, #f2c94c);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  border-radius: 10px;
  background: rgba(242, 201, 76, 0.1);
  width: 115px;
  height: 33px;
  text-align: center;
}

.inlineprod_canceled h6 {
  color: var(--Red, #eb5757);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  border-radius: 10px;
  background: rgba(235, 87, 87, 0.1);
  width: 115px;
  height: 33px;
  text-align: center;
}

.inlineprod_view a {
  color: var(--ffffff, #fff);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  padding: 10px 27px;
}

.inlineprod_view {
  margin: 0px 0px 0px 60px;
}

/*********** Order Dashboard Page CSS End ***********/

/*********** Setting Dashboard Page CSS Start ***********/

.setting_dashboard_tabs .react-tabs__tab-list {
  border: none;
}

.setting_dashboard_tabs .react-tabs__tab-list .react-tabs__tab {
  color: var(--000000, #000);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  padding: 9px 36px;
  background: #fff;
}

.setting_dashboard_tabs .react-tabs__tab-list .react-tabs__tab--selected {
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  padding: 9px 36px;
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}

.navtabs .react-tabs__tab-list .react-tabs__tab--selected {

  color: var(--ffffff, #89c400) !important;


}

.setting_dashboard_setting {
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  padding: 31px 31px 37px 31px;
  background: #fff;
  margin: 32px 0px 0px 0px;
  border-radius: 15px;
}

.setting_dashboard_setting h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  padding: 0px 0px 10px 0px;
}

.setting_dashboard_setting p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 0px 4px 0px;
}

.store_input_box {
  margin: 20px 0px 0px 0px;
}

.store_input_box label {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  padding: 0px 0px 10px 0px;
}

.store_input_box input {
  background: #f2f2f2;
  border-radius: 10px;
  border: none;
  display: block;
  padding: 10px 0px 10px 21px;
  width: 100%;
}

.store_input_box input:focus {
  outline: none;
}

.store_input_box select {
  background: #f2f2f2;
  border-radius: 10px;
  border: none;
  display: block;
  padding: 10px 0px 13px 16px;
  width: 100%;
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.store_first_form_btn a {
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 53px;
  padding: 15px 48px;
}

.store_first_form_btn {
  margin: 20px 0px 0px 0px;
}

.store_checkbox input {
  width: 27.664px;
  height: 27.664px;
  border-radius: 7px;
  border: 1px solid var(--Gray-4, #bdbdbd);
}

.store_checkbox {
  display: flex;
  align-items: center;
  margin: 53px 0px 0px 0px;
  gap: 15px;
}

.store_checkbox_box_txt h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  padding: 0px;
}

.store_checkbox_box_txt h6 {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  margin: 0px;
}

.general-dashboard h4 {
  padding: 0px;
}

.general-dashboard {
  margin: 28px 0px 0px 0px;
}

.dashprod-sort {
  justify-content: end;
}

/*********** Setting Dashboard Page CSS End ***********/

/*********** Report Rating Dashboard Page CSS Start ***********/

.ignore_delete_btns .ignore_all {
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  padding: 6px 57px;
  border: 1px solid #194d79;
}

.ignore_delete_btns .delete_all {
  color: var(--194-d-79, #194d79);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  border: 2px solid var(--194-d-79, #194d79);
  padding: 6px 57px;
}

.ignore_delete_btns {
  display: flex;
  align-items: center;
  gap: 17px;
}

.dashboard_product_head p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 0px 0px 0px;
}

.reported-rating-border {
  position: relative;
}

.reported-rating-border::before {
  position: absolute;
  content: "";
  background: #e0e0e0;
  width: 100%;
  height: 1px;
  bottom: 0px;
}

.dashreport_rating_box {
  background: #e0e0e0;
  margin: 32px 0px 0px 0px;
  border-radius: 20px 20px 20px 20px;
}

.dashreport_rating_box h1 {
  padding: 18px 0px 16px 30px;
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dashreport_rating_box h1 span {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.report_ratingigdel_btn {
  display: flex;
  align-items: center;
  gap: 17px;
}

.report_ratingigdel_btn .ignore_all {
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  width: 100%;
  border: 2px solid var(--194-d-79, #194d79);
  padding: 6px 0px;
  transition: all 0.6s ease;
}

.report_ratingigdel_btn .delete_all {
  color: var(--194-d-79, #194d79);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  border: 2px solid var(--194-d-79, #194d79);
  width: 100%;
  padding: 6px 0px;
  transition: all 0.6s ease;
}

.report_ratingigdel_btn {
  display: flex;
  align-items: center;
  gap: 17px;
  margin: 24px 0px 0px 0px;
}

.report_ratingigdel_btn .ignore_all:hover {
  background: transparent;
  color: #194d79;
}

.report_ratingigdel_btn .delete_all:hover {
  background: #194d79;
  color: #fff;
}

/*********** Report Rating Dashboard Page CSS End ***********/

/*********** Reported DMS Dashboard Page CSS Start ***********/

.reported_dms_head h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.reported_dms_head {
  padding: 33px 0px 47px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.reported_dms_head::before {
  position: absolute;
  content: "";
  background: #e0e0e0;
  width: 100%;
  height: 1px;
  bottom: 25px;
}

.reported_dms_chatsuggestbox {
  background: #e0e0e0;
  border-radius: 20px 20px 20px 20px;
  margin: 0px 0px 28px 0px;
}

.reported_dms_chatsuggestbox h1 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 18px 0px 15px 30px;
}

.reported_dms_chatsuggestbox h1 span {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reported_dms_chatman img {
  width: 73.725px;
}

.reported_dms_mandata h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.reported_dms_mandata p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.reported_dms_mandata p span {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.reported_dms_chatman {
  background: #fff;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  padding: 30px 30px 30px 31px;
  border-radius: 20px;
}

.reported_dms_chatman img {
  width: 73.725px;
}

.reported_dms_mandata h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.reported_dms_mandata p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.reported_dms_mandata p span {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.reported_dms_manchat {
  display: flex;
  align-items: center;
  gap: 25px;
  margin: 0px 0px 26px 0px;
}

.reported_dms_chatman a {
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  width: 100%;
  padding: 6px 0px;
  display: inline-block;
  transition: all 0.6s ease;
  border: 1px solid #194d79;
}

.reported_dms_chatman a:hover {
  background: transparent;
  color: #194d79;
}

.reported_chat_userOne img {
  width: 39px;
}

.username_chat_flex {
  display: flex;
  align-items: center;
  gap: 26px;
}

.reported_chat_userOne {
  display: flex;
  align-items: center;
  gap: 16px;
}

.reported_chat_userOne h6 {
  margin: 0px;
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
}

.username_chat_flex span {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reported_dashboard_chatbox {
  border-radius: 15px;
  background: var(--ffffff, #fff);
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
}

.chatmain_head_flex {
  padding: 20px 24px 18px 31px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chattingbox_left img {
  width: 46px;
  height: 46px;
}

.chattingbox_left {
  display: flex;
  gap: 8px;
  margin: 0px 0px 18px 0px;
}

.chatting_mxg p {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 33px;
  border-radius: 10px 10px 10px 0px;
  background: var(--Gray-6, #f2f2f2);
  padding: 6px 17px;
}

.reported_chatting_box {
  padding: 67px 31px 28px 31px;
  border: 1px solid #e0e0e0;
}

.chattingbox_left span {
  color: var(--Gray-3, #828282);
  text-align: right;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 5px 0px 0px 0px;
}

.chattingbox_right {
  display: flex;
  gap: 8px;
  margin: 0px 0px 18px 0px;
  justify-content: end;
}

.chattingbox_right img {
  width: 46px;
  height: 46px;
}

.chatting_boxmxgme span {
  color: var(--Gray-3, #828282);
  text-align: right;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.chatting_boxmxgme {
  text-align: end;
}

.chatting_mxgme p {
  border-radius: 10px 10px 10px 0px;
  background: var(--194-d-79, #194d79);
  color: var(--ffffff, #fff);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 33px;
  padding: 6px 18px;
}

.report_chat_btn {
  padding: 20px 31px 23px 31px;
  display: flex;
  gap: 15px;
}

.report_chat_btn a {
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  width: 100%;
  padding: 6px 0px;
}

.report_chat_btn a {
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  width: 100%;
  padding: 6px 0px;
  border: 1px solid #194d79;
}

.report_chat_btn a:hover {
  background: transparent;
  color: #194d79;
}

.report_chat_btn select {
  color: var(--194-d-79, #194d79);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  border: 2px solid var(--194-d-79, #194d79);
  width: 100%;
  padding: 6px 0px;
}

/*********** Reported DMS Dashboard Page CSS End ***********/

/*********** Reported Article Dashboard Page CSS Start ***********/

.dashnewsletter_article_box {
  margin: 32px 0px 0px 0px;
  background: #e0e0e0;
  border-radius: 20px;
}

.dashnewsletter_article_box h1 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: #e0e0e0;
  padding: 17px 30px;
  border-radius: 20px 20px 0px 0px;
}

.dashnewsletter_article_box h1 span {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard_newletter_box {
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  background: #fff;
  padding: 30px 30px 24px 37px;
  position: relative;
  margin: 0px 0px 28px 0px;
  border-radius: 20px;
}

.main-box-con {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.side-box-my {
  width: 87%;
  height: 90vh;
  overflow-y: scroll;
}

header {
  width: 100%;
  position: relative;
  background: white;
}

.dashboard_newletter_box::before {
  position: absolute;
  content: "";
  background: #e0e0e0;
  height: 1px;
  width: 100%;
  left: 0px;
  bottom: 100px;
}

.dashnewsletter_box {
  display: flex;
  align-items: center;
  gap: 38px;
  padding: 0px 0px 40px 0px;
}

.dashnewsletter_box img {
  width: 191.069px;
  height: 191.069px;
}

.dashboard_newsletter_txt h6 {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.dashboard_newsletter_txt h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
}

/*********** Reported Article Dashboard Page CSS END ***********/

/*********** Pages Dashboard Page CSS Start ***********/

.dashboard_pages_head h3 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dashboard_pages_head {
  padding: 0px 0px 25px 0px;
}

.navtabs ul {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  border: none;
  justify-content: space-between !important;
}

.navtabs ul li {
  border: none;
}

.pagesdash_add_search {
  display: flex;
  align-items: center;
  gap: 30px;
}

.pagesdash_pagessearch input {
  background: #f2f2f2;
  border-radius: 10px;
  border: 1px solid var(--Gray-3, #828282);
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  width: 100%;
  display: inline-block;
  padding: 6px 18px 6px 16px;
}

.pagesdash_pagessearch {
  position: relative;
}

.pagesdash_pagessearch span {
  color: #828282;
  position: absolute;
  right: 18px;
  font-size: 24px;
  top: 3px;
}

.pagesdash_pagessearch {
  position: relative;
  width: 100%;
}

.addnewprod_head {
  width: 60%;
}

.pagesdash_filter_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagesdash_filter_flex h5 {
  color: var(--Gray-3, #828282);
  text-align: right;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.pagesdash_filter_flex select {
  border-radius: 10px;
  border: 1px solid var(--Gray-3, #828282);
  background: #f2f2f2;
  padding: 11px 35px;
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.dashpages_data_box {
  margin: 32px 0px 56px 0px;
  background: #fff;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  padding: 22px 32px 33px 31px;
  border-radius: 20px;
}

.dashpages_data_box table thead tr th {
  padding: 0px 0px 17px 0px;
}

.dashpages_data_box table thead {
  border-bottom: 1px solid #e0e0e0;
}

.dashpages_data_box table thead tr th input {
  width: 23px;
  border-radius: 5px;
  border: 1px solid var(--Gray-5, #e0e0e0);
  height: 23px;
  margin: 0px 20px 0px 0px;
}

.dashpages_data_box table thead tr th select {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}

.dashpages_data_box table tbody tr td {
  padding: 31px 0px 0px 0px;
}

.dashpages_data_box table tbody tr td input {
  border-radius: 5px;
  border: 1px solid var(--Gray-5, #e0e0e0);
  width: 23px;
  height: 23px;
}

.dashpages_data_box table tbody tr td h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  width: 297px;
  line-height: 26px;
}

.dashpages_data_box table tbody tr td h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 0px 60px 0px 0px;
}

.pagesdash_published {
  color: var(--89-c-400, #89c400);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  border-radius: 10px;
  background: rgba(137, 196, 0, 0.1);
  border: none;
  padding: 8px 27px;
  margin: 0px 50px 0px 0px;
}

.dashpages_data_box table tbody tr td h5 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  width: 200px;
  padding: 0px 90px 0px 0px;
}

.dashpages_data_box table thead tr th ul {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 0px;
  padding: 0px 60px 0px 0px;
}

.dashpages_data_box table tbody tr td ul {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 0px 60px 0px 0px;
  margin: 0px;
}

.dashpages_data_box table thead tr th h3 {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.billing_sec_head {
  padding: 0px 0px 25px 0px;
}

.billing_sec_head h3 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/*********** Pages Dashboard Page CSS End ***********/

/*********** Billing Dashboard Page CSS Start ***********/

.billing_earning_box {
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  background: #fff;
  padding: 24px 27px 36px 27px;
  border-radius: 20px;
  margin: 0px 0px 32px 0px;
}

.billing_earning_box h5 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  padding: 0px 0px 22px 0px;
}

.billing_earning_box h5 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  padding: 0px 0px 22px 0px;
}

.billing_payment_box {
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  padding: 25px 28px 24px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.billing_payment_number h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  padding: 0px 0px 12px 0px;
}

.billing_payment_number h6 {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0px;
}

.billing_earning_box a {
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  width: 100%;
  display: inline-block;
  margin: 28px 0px 0px 0px !important;
  padding: 6px 0px;
  transition: all 0.6s ease;
  border: 1px solid #194d79;
}

.billing_earning_box a:hover {
  background: transparent;
  color: #194d79;
}

.withdrawal_options_box {
  border-radius: 20px;
  background: #fff;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  padding: 24px 27px 12px 27px;
  margin: 0px 0px 32px 0px;
}

.withdrawal_options_box h5 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  padding: 0px 0px 22px 0px;
}

.blldash_billing_transfer_box {
  border-radius: 10px;
  border: 1px solid var(--Gray-5, #e0e0e0);
  padding: 21px 26px 25px 23px;
  display: flex;
  gap: 24px;
  margin: 0px 0px 16px 0px;
}

.billdash_billingtransfer_name {
  display: flex;
  gap: 16px;
  align-items: center;
  margin: 0px;
}

.billdash_billingtransfer_name h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.checkbox-wrapper:hover .check {
  stroke-dashoffset: 0;
}

.checkbox-wrapper {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.checkbox-wrapper .background {
  fill: #194d79;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper .stroke {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
  stroke-dashoffset: 100;
  stroke-dasharray: 100;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper .check {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  stroke-dashoffset: 22;
  stroke-dasharray: 22;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  opacity: 0;
  -appearance: none;
  -webkit-appearance: none;
}

.checkbox-wrapper input[type="checkbox"]:hover {
  cursor: pointer;
}

.checkbox-wrapper input[type="checkbox"]:checked+svg .background {
  fill: #194d79;
}

.checkbox-wrapper input[type="checkbox"]:checked+svg .stroke {
  stroke-dashoffset: 0;
}

.checkbox-wrapper input[type="checkbox"]:checked+svg .check {
  stroke-dashoffset: 0;
}

.billdash_payment_namewedit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.billdash_payment_namewedit a {
  color: var(--194-d-79, #194d79);
  text-align: right;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.transaction_histroy_head h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.transaction_histroy_head {
  padding: 0px 0px 25px 0px;
}

.transaction_histroy_table {
  padding: 26px 32px 31px 31px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  background: #fff;
  border-radius: 20px;
  margin: 0px 0px 36px 0px;
  overflow-x: scroll;
}

.transaction_histroy_table table thead tr th select {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  margin: 0px 65px 0px 0px;
}

.transaction_histroy_table table thead tr th {
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.transaction_histroy_table table tbody tr td {
  padding: 31px 0px 0px 0px;
}

.transaction_histroy_table table tbody tr td h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}

.transaction_histroy_table table tbody tr td h3 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}

.transaction_histroy_table table tbody tr td p {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}

.transaction_histroy_table table tbody tr td h6 {
  margin: 0px;
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}

.transaction_histroy_table table tbody tr td h5 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}

.transaction_progress_btn {
  color: var(--Yellow, #f2c94c);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  border-radius: 10px;
  background: rgba(242, 201, 76, 0.1);
  padding: 8px 25px;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 900px;
}

.transaction_paid_btn {
  color: var(--89-c-400, #89c400);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  border-radius: 10px;
  background: rgba(137, 196, 0, 0.1);
  padding: 8px 15px;
  display: block;
  width: 250px;
}

.dropdown button {
  border-radius: 10px;
}

.newsletter_filter_flex {
  justify-content: end;
  gap: 8px;
}

.email_subceriber_box {
  border-radius: 15px;
  background: var(--ffffff, #fff);
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
  padding: 22px 30px 32px 31px;
  margin: 0px 0px 45px 0px;
}

.dashboard_subscriber_head h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dashboard_subscriber_head {
  padding: 0px 0px 25px 0px;
}

.email_subcriber_flex p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}

.email_subcriber_flex h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
}

.email_subcriber_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 25px 0px;
}

.email_subscriber_table {
  width: 100%;
}

.email_subscriber_table table thead tr th h3 {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.email_subscriber_table table thead tr th {
  padding: 0px 0px 25px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.email_subscriber_table table tbody tr td h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  padding: 0px 90px 0px 0px;
}

.email_subscriber_table table tbody tr td {
  padding: 19px 0px 0px 0px;
}

.email_subscriber_table table tbody tr td h5 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  padding: 0px 50px 0px 0px;
}

.email_subscriber_table table tbody tr td h6 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  margin: 0px;
  padding: 0px 50px 0px 0px;
}

/*********** Billing Dashboard Page CSS End ***********/

/*********** Newsletter Dashboard Page CSS Start ***********/

.dashnewsletter_data_box {
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  background: #fff;
  border-radius: 20px;
  padding: 22px 32px 18px 31px;
  margin: 32px 0px 32px 0px;
  overflow-x: scroll;
}

.dashnewsletter_data_box table thead tr th select {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}

.dashnewsletter_data_box table thead tr th {
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 0px 17px 0px;
}

.flex-box-order {
  display: flex;
}

.flex-box-order .order_dashboard_header_right {
  gap: 90px;
}

.dashnewsletter_data_box table thead tr th input {
  width: 23px;
  height: 23px;
  margin: 0px 20px 0px 0px;
}

.dashnewsletter_data_box table tbody tr td input {
  width: 23px;
  height: 23px;
}

.dashnewsletter_data_box table tbody tr td h6 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 0px;
}

.dashnewsletter_data_box table tbody tr td h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 0px 0px 0px 28px;
}

.dashnewsletter_data_box table tbody tr td h5 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 0px 0px 0px 0px;
}

.progress-container {
  width: 163px;
  height: 35px;
  background-color: rgba(137, 196, 0, 0.1);
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ffffff;
  margin: 0px 34px 0px 0px;
}

.progress-bars {
  height: 100%;
  background-color: #89c400;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: bold;
  white-space: nowrap;
  padding: 0 10px;
  box-sizing: border-box;
}

.setting_icon_view {
  display: flex;
  gap: 35px;
  align-items: center;
}

.setting_icon_view a {
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  color: var(--ffffff, #fff);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  padding: 4px 27px;
  border: 1px solid #194d79;
  transition: all .6s ease;
}

.setting_icon_view a:hover {
  background: transparent;
  color: #194d79;
}

.dashnewsletter_data_box table tbody tr td {
  padding: 26px 0px 0px 0px;
}

.dashnewsletter_data_box table tbody tr td .progress-container {
  margin: 0px 0px 0px 38px;
}

.dashnewsletter_data_box table tbody tr td .setting_icon_view {
  margin: 0px 0px 0px 34px;
}

/*********** Newsletter Dashboard Page CSS ENd ***********/


.flex-box {
  display: flex;
  align-items: center;
  gap: 10px;
}


/******************* Shahdin Pages CSS End *******************/

/* ahtisham-admin-screen-new-start-codeahtisham-admin-screen-new-start-code
ahtisham-admin-screen-new-start-codeahtisham-admin-screen-new-start-code
ahtisham-admin-screen-new-start-codeahtisham-admin-screen-new-start-code
ahtisham-admin-screen-new-start-codeahtisham-admin-screen-new-start-code */

section.ah1 {
  width: 100%;
  padding: 30px 40px;
}

.ahd-box-1 {
  width: 100%;
  padding-bottom: 30px;
}

.ahd-box-1 h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ahd-box-1 p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 8px;
}

.ahd-box-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
}

.ahd-box-btn-card {
  display: flex;
  align-items: center;
  gap: 17px;
  width: 100%;
}

.ahd-box-btn-card button {
  padding: 8px 0px;
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border: 2px solid #194d79;
  width: 24%;
  transition: 0.3s;
}

.ahd-box-btn-card button:hover {
  background: transparent;
  color: #194d79;
}

.ahd-box-2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
  gap: 30px;
  border-top: 1px solid #e0e0e0;
  flex-direction: column;
}

.ahd-card {
  width: 85%;
}

.ahd-card-head {
  width: 100%;
  background: #e0e0e0;
  border-radius: 30px 30px 0px 0px;
  padding: 18px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ahd-card-head p {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ahd-card-head p span {
  font-weight: 800;
}

span.approved-s {
  display: flex;
  align-items: center;
  gap: 10px;
}

span.approved-s p {
  color: var(--Gray-3, #828282);
  text-align: right;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ahd-card-body {
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0px 0px 20px 20px;
  background: #fff;
}

.ahd-card-body-start {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 45px 28px;
  justify-content: space-between;
}

.sevenplus {
  width: 64px;
  position: relative;
}

.ahd-card-body-start-1 {
  display: flex;
  align-items: center;
  gap: 30px;
}

.sevenplus img {
  width: 100%;
  object-fit: cover;
}

.ahd-card-body-start-1 span h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
}

.ahd-card-body-start-1 span p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.ahd-card-body-start-btn {
  display: flex;
  align-items: center;
  width: 28%;
  justify-content: flex-end;
  gap: 15px;
}

.ahd-card-body-start-btn button {
  padding: 8px 0px;
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border: 2px solid #194d79;
  width: 48%;
  transition: 0.3s;
}

.ahd-card-body-start-btn button:hover {
  background: transparent;
  color: #194d79;
}

.ahd-card-body-end {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 28px;
  gap: 24px;
  border-top: 1px solid #e0e0e0;
}

.ahd-card-body-end span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ahd-card-body-end span svg {
  width: 24px;
  height: 24px;
}

.ahd-card-body-end span p {
  color: var(--000000, #000);
  text-align: right;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

span.sl-icon svg {
  width: 130px;
}

.sevenplus a {
  border-radius: 8px;
  background: var(--89-c-400, #89c400);
  color: var(--ffffff, #fff) !important;
  text-align: center;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 5px 5px;
  position: absolute;
  bottom: -5px;
  right: 5px;
}




.Reporter-dah-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 30px 30px 30px;
}

.Reporter-dah-box-dp {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dp-imger {
  width: 50px;
}

.dp-imger img {
  width: 100%;
}

.Reporter-dah-box-dp span h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.Reporter-dah-box-dp span p {
  color: var(--194-d-79, #194d79);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 5px;
}

.Reporter-dah-box-dp-2 {
  display: flex;
  gap: 10px;
  align-items: center;
}

.Reporter-dah-box-dp-2 p {
  color: var(--Gray-3, #828282);
  text-align: right;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Reporter-dah-box-dp-2 img {
  width: 45px;
  max-width: 45px;
  min-width: 45px;
}

.Reporter-dah-box-dp-2 h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.Reporter-dah-body-new {
  padding: 30px;
  border-top: 1px solid #e0e0e0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
}

.Simmons-dah-box {
  width: 100%;
  border-radius: 8px;
  background: #f2f2f2;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.Simmons-dah-dp-site {
  width: 28%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.Simmons-dah-tital-site {
  width: 60%;
}

.Simmons-dah-img-site {
  width: 65px;
}

.Simmons-dah-img-site img {
  width: 100%;
}

.Simmons-dah-id-site span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Simmons-dah-id-site span p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Simmons-dah-id-site span a {
  border-radius: 6px;
  background: var(--89-c-400, #89c400);
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 5px;
}

.Simmons-dah-id-site h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  padding-top: 5px;
}

.Simmons-dah-tital-site p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.Simmons-dah-tital-site h3 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding-top: 5px;
}

.Simmons-dah-h2 {
  width: 100%;
}

.Simmons-dah-h2 p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.Simmons-dah-h2 h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;

}

.Simmons-dah-h2 h3 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
}

.Simmons-dah-more-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Simmons-dah-more-card {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}

.Simmons-dah-more-card p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Simmons-dah-more-card h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;

}

.Simmons-dah-more-card svg {
  width: 220px;
}

.Simmons-dah-more-btn {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.Simmons-dah-more-btn h2 {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.Simmons-dah-more-btn button {
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  border: none;
  padding: 6px 18px;
  color: var(--Gray-3, #828282);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;

}

.Reporter-dah-body-new-last {
  width: 100%;
  padding: 24px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
}

.Reporter-dah-body-new-last button {
  width: 45%;
  padding: 8px 35px;
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  color: var(--ffffff, #fff);
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border: 2px solid #194d79;
  transition: 0.3s;
}

button.Deny {
  background: transparent !important;
  color: #194d79 !important;
}


button.Deny:hover {
  background-color: #194d79 !important;
  color: #ffff !important;
}

.Reporter-dah-body-new-last svg {
  width: 40px;
  height: 40px;
}

.Uploaded-documents {
  width: 100%;
  padding-top: 24px;
}

.Uploaded-documents h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

}

.ahd-card-body-end.more-pading-lost {
  padding-top: 0px;
}

.Uploaded-documents-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}

.Uploaded-documents-card {
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--Gray-5, #e0e0e0);
  padding: 14px 18px;
}

.Uploaded-documents-card span {
  display: flex;
  align-items: center;
  gap: 12px;
}

.Uploaded-documents-card span svg {
  width: 28px;
  height: 28px;
}

.Uploaded-documents-card svg {
  width: 25px;
  height: 25px;
}

.filter-box.hello-box {
  width: 30%;
}

.Employees-table {
  width: 100%;
}

.Employees-table h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Employees-table-inbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px 35px 0px;
  flex-wrap: wrap;
  gap: 20px;
}



.Employees-Search {
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.Employees-Search input {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--Gray-3, #828282);
  background: transparent;
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  outline: none;
  padding: 10px 20px;
}

.Employees-Search button {
  position: absolute;
  background: transparent;
  border: none;
  right: 20px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  min-width: 1110px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

#customers td,
#customers th {
  padding-left: 20px;
  padding-right: 20px;
}

#customers th svg {
  margin: 0px 0px 0px 10px;
}

#customers th svg {
  margin: 0px 0px 0px 10px;
}

.action_setting_icon svg {
  cursor: pointer;
  position: relative;
}

.action_setting_dropdown {
  background: #fff;
  position: absolute;
  right: 0px;
  top: 0px;
  border: 1px solid #000;
  width: 150px;
}

.action_setting_icon {
  position: relative;
}

.action_setting_dropdown ul li {
  padding: 10px 20px;
}

.action_setting_icon svg {
  cursor: pointer;
  position: relative;
}


.Employees-box-btn-icon button {
  display: flex;
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  border: 1px solid #194d79;
  align-items: center;
  gap: 14px;
  color: var(--ffffff, #fff);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  padding: 12px 22px;
  transition: all .6s ease;
}

.Employees-box-btn-icon button:hover {
  background-color: transparent;
  color: #194d79;
}



#customers td {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;

  padding-top: 25px;
}

.new-Unauthorized {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;

  padding: 8px;
}

/* #customers tr:hover {background-color: #ddd;} */

#customers th {
  padding-top: 26px;
  padding-bottom: 20px;
  text-align: left;
}

.dropdown-content {
  display: none;
}

.dropdown-content.show {
  display: block;
}

.dropbtn {
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 220px;
  z-index: 1;
  border-radius: 10px;
  background: var(--ffffff, #fff);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  padding: 10px;
  right: 0px;
}

.admin-dp {
  width: 60px;
}

button.dropbtn svg {
  width: 80px;
  height: 40px;
  border-radius: 24px;
}

td.gg-td {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 33px;

}

.new-online {
  padding: 4px 8px;
  width: 200px;
  border-radius: 10px;
  background: rgba(137, 196, 0, 0.1);
  color: var(--89-c-400, #89c400);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  border-radius: 10px;
  background: var(--Gray-6, #f2f2f2);


}

.Orders_popup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
}

.main-Orders {
  width: 666px;
  border-radius: 15px;
  background: #fff;
  padding: 30px;
}

.Orders-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 1px solid #e0e0e0;
}

.Orders-1 h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Orders-1 button {
  color: var(--89-c-400, #89c400);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;

  border-radius: 10px;
  background: rgba(137, 196, 0, 0.1);
  border: none;
  padding: 5px 15px;
}

.Orders-2 {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e0e0e0;
}

.Orders-2 h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Orders-2-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}

.Orders-2-card {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.Orders-2-card h3 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;

}

.Orders-2-card p {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

}

.Orders-2-card.Orders-card-2 {
  width: 100% !important;
  padding-top: 15px;
}

.Orders-3 {
  width: 100%;
}

.Orders-3-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px;
  border-bottom: 1px solid #e0e0e0;
}

span.Orders-2-li-1 {
  width: 44%;
}

span.Orders-2-li-2 {
  width: 12%;
  text-align: center;
}

.Orders-3-head span h2 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Orders-3-body-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px;
  border-bottom: 1px solid #e0e0e0;
}

span.Orders-2-li-1 h4 {
  color: var(--000000, #000);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  padding-top: 10px;
}

span.Orders-2-li-1 h4 span {
  font-weight: 700;
}

.Orders-4 {
  width: 100%;
  padding-top: 25px;
  display: flex;
  justify-content: flex-end;
}

.Orders-4 button {
  border-radius: 10px;
  background: var(--194-d-79, #194d79);
  color: var(--ffffff, #fff);
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  border: 1px solid #194d79;
  padding: 5px 28px;
  transition: 0.3s;
}

.Orders-4 button:hover {
  background: transparent;
  color: #194d79;
}

.moress span {
  top: 35px !important;
}

/* ahtisham-admin-screen-new-start-codeahtisham-admin-screen-new-start-code
}


.main-box-con {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.side-bar-my {
  width: 250px;
}

.side-box-my {
  width: 87%;
  height: 90vh;
  overflow-y: scroll;
}

header {width: 100%;background: white;position: relative;}
/* ahtisham-admin-screen-new-start-codeahtisham-admin-screen-new-start-code
ahtisham-admin-screen-new-start-codeahtisham-admin-screen-new-start-code
ahtisham-admin-screen-new-start-codeahtisham-admin-screen-new-start-code
ahtisham-admin-screen-new-start-codeahtisham-admin-screen-new-start-code */

.newsletter-article-search span.newsletter_search {
  top: 58px;
}

.modal-content {
  background-color: transparent !important;
  border: none !important;
}


.add_profile_ul {
  position: absolute;
  background: #fff;
  height: auto;
  z-index: 999;
  padding: 0px 0px;
  box-shadow: 0 0 6px #00000030;
  bottom: -55px;
  margin: 0px;
}

.add_profile_ul li {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all .6s ease;
}


.add_profile_ul li:hover {
  background: #194d79;
  color: #fff;
}


button.Contact-ah.more {
  background: transparent;
  color: #194d79;
  transition: all .6s ease;
}

button.Contact-ah.more:hover {
  background: #194d79;
  color: #ffff;
}

span.See select:focus {
  outline: none;
}

.Upload-show img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}


.Upload-show {
  background: #f2f2f2;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
}



/************* Rate My Station Message Page CSS Start *************/

.ratemystation_chatbox {
  padding: 60px 0px 60px 0px;
}

.messages_pg_box {
  background: #ffffff;
  border-radius: 20px;
  height: 100vh;
}

.rate_chatbox_left {
  padding: 30px 20px 30px 20px;
  border-right: 1px solid #E0E0E0;
}

.rate_chatbox_left h4 {
  padding: 0px 0px 30px 0px;
  font-family: 'Public Sans';
  font-size: 36px;
  font-weight: bold;
}

.chatbox_message_search input {
  width: 100%;
  padding: 6px 20px;
  line-height: 33px;
  font-size: 16px !important;
  font-family: 'Public Sans';
  color: #4F4F4F;
  background: #F2F2F2;
  border: none;
  border-radius: 10px;
  margin: 0px 0px 10px 0px;
}

.chatbox_message_search {
  position: relative;
}

.chatbox_message_search span {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #828282;
  font-size: 18px;
}

.chatbox_message_search input:focus {
  outline: none;
}

.chat-box-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 34px;
  border-bottom: 1px solid #E0E0E0;
}

.chat-box-headleft {
  gap: 20px;
  display: flex;
  align-items: center;
}

.chat-box-head-dp img {
  width: 58.63px;
  height: 58.63px;
}

.chat-box-head .chat-name h2 {
  font-family: 'Public Sans';
  font-size: 20px;
  color: #000;
  font-weight: bold;
  line-height: 30.5px;
}

.chat-box-right {
  display: flex;
  gap: 14px;
}

.chat-box-right span {
  color: #828282;
  font-size: 16px;
  font-family: 'Public Sans';
}

.chat-box-body-input input {
  width: 100%;
  padding: 10px 17px;
  background: #F2F2F2;
  border: none;
  border-radius: 10px;
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 300;
  line-height: 33px;
}

.chat-box-body-input {
  position: relative;
}

.message_chat_sendbox {
  position: absolute;
  right: 20px;
  top: 15px;
}

.message_chat_sendbox button {
  border: none;
}

.message_chat_sendbox button svg path {
  fill: #194D79;
}

.chat_right_txt ul li {
  border-radius: 10px 10px 10px 0px;
  color: #fff;
}

.chat_right_txt {
  padding: 20px;
}

.chat-box-body-input input:focus {
  outline: none;
}

.message-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 20px 0px 0px 0px;
}


.message-item .message-info {
  flex-grow: 1;
}

.message-item .message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.message-item .name {
  color: #000;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}


.message-item .time {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.message-item .message {
  margin: 5px 0;
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.message-item .message-count {
  background-color: #89C400;
  border-radius: 50%;
  color: #FFF;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 30px 0px 0px 0px;
  padding: 3px 6px;
}

.message-item .avatar {
  margin-right: 10px;
  width: 53.351px;
  height: 53.351px;
}

.chat-name p {
  color: var(--Gray-3, #828282);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.message_chat_sendbox button svg {
  width: 20px;
  height: 20px;
}

.chat_right_txt ul {
  height: 72vh;
}

hr.padding-hr {
  margin-top: 14px;
}

.journalism-slider h5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 12vh;
}

.message-list {
  height: 810px;
}

.chat_right_txt ul::-webkit-scrollbar {
  width: 4px;
}


.chat_right_txt ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat_right_txt ul::-webkit-scrollbar-thumb {
  background: #89c400;
}

.message-list::-webkit-scrollbar {
  width: 4px;
}


.message-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.message-list::-webkit-scrollbar-thumb {
  background: #89c400;
}

.journalism-slider h5::after {
  content: '...';
  display: inline;
}

.dropdown {
  position: absolute !important;
  background-color: #fff;
  overflow-y: auto;
  transition: all 0.4s ease;
  position: absolute;
  background: white;
  top: 80px;
  border-radius: 8px;
  box-shadow: 0px 4px 13px 0px #00000026;
  z-index: 2;
  padding: 12px;
  display: flex;
  width: 230px !important;
  height: 40vh;
  align-items: flex-start;
}

.dropdown ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
}

.dropdown ul li {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 5px;
}

.dropdown ul li::after {
  content: '...';
  display: inline;
}

section.nazar {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.main-nazar {
  width: 100%;
  max-width: 900px;
  min-width: 900px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
  border-radius: 10px;
}

.Report-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
}

.Report-bo {
  width: 100%;
  border-top: 1px solid #d9d3d3;
  border-bottom: 1px solid #d9d3d3;
  padding: 20px 30px 30px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
}

.Report-bo h3 {
  width: 100%;
}

.Report-footer {
  width: 100%;
  padding: 20px 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.Report-footer button {
  padding: 10px 30px;
  border-radius: 50px;
  border: 1px solid #0d6efd;
  color: #0d6efd;
  font-size: 18px;
  font-weight: 400;
  background: transparent;
  transition: 0.3s;
}

.Report-bo button {
  padding: 8px 15px;
  border-radius: 50px;
  background: transparent;
  border: 1px solid #afafaf;
  color: #afafaf;
  font-weight: 600;
  font-size: 18px;
  transition: 0.3s;
}

.Report-bo button:hover {
  border: 1px solid #0d6efd;
  color: #0d6efd;
}

.Report-footer button:hover {
  background-color: whitesmoke;
}

.Report-head button {
  background: transparent;
  border: none;
  font-size: 40px;
  color: #0d6efd;
  line-height: 2px;
}

.Report-bo h3 {
  font-size: 20px;
  color: #000;
}

.Report-head h2 {
  font-size: 28px;
}

.dashpages_data_box table tbody tr td svg {
  cursor: pointer;
}

.setting_icon_view svg {
  cursor: pointer;
}

.about-creator-txt {
  width: 100%;
  padding-right: 30px;
}
.Office-ah-box button {
  padding: 1px 20px;
  border-radius: 5px;
  border: 2px solid #89c400;
  background: #89c400;
  color: #fff;
  transition: 0.3s;
}



.Office-ah-box button:hover {
  background: transparent;
  color: #89c400;
}



.modal-footer {
  justify-content: center !important;
}

.modal-body input {
  /* width: 100%; */
  padding: 12px 20px;
  background: var(--Gray-6, #f2f2f2);
  border: none;
  outline: none;
}


.modal-footer button {
  border-radius: 5px;
  padding: 5px 30px;
}
 

button.btn.btn-primary {
  background: #9bdb08;
  border: 1px solid #9bdb08;
  transition: 0.3s;

}


button.btn.btn-primary:hover {
  background: transparent;
  border: 1px solid #9bdb08;
  color: #9bdb08;
}

button.btn.btn-secondary {
  background: transparent;
  transition: 0.3s;
  border: 1px solid #194d79;
  color: #194d79;
}

button.btn.btn-secondary:hover {
  background: #194d79;
  color: #FFF;
}
/************* Rate My Station Message Page CSS End *************/


@media screen and (max-width: 1600px) {
  
  .options-list li.select-option{
    padding: 10px 30px;
  }

  .ahd-card-body-start {
    padding: 30px 20px;
  }

  .Employees-Search {
    width: 30%;
  }

  .max--width {
    max-width: 1000px;
    min-width: 950px;
  }

  .ahd-card-body-start-1 span h2 {
    font-size: 20px;
  }

  .ahd-card-body-start-1 span p {
    font-size: 14px;
  }

  .ahd-card-body-start-1 {
    gap: 20px;
  }

  .ahd-card-body-end {
    padding: 15px 25px;
    gap: 20px;
  }

  .ahd-card-body-end span p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1366px) {
  .setting_icon_view a{
    font-size: 14px;
  }
  .options-list li.select-option{
    padding: 10px 30px;
  }
  .username_chat_flex {
    gap: 15px;
  }

  .chat_right_txt ul {

    overflow-y: scroll;
  }

  .message-item .name {
    font-size: 14px;
  }

  .rate_chatbox_left {
    padding: 30px 20px 30px 20px;
  }

  .message-item .message {
    font-size: 11px;
  }

  .message-item {
    margin: 20px 10px 0px 0px;
  }


  .message-list {
    height: 75vh;
    overflow-y: scroll;
  }

  .contact-follow-like-btn ul li {
    padding: 19px 41px 19px 39px;
  }


  header.header_main {
    padding: 20px 0px 12px 0px;
  }

  .header_nav ul li {
    font-size: 15px;
  }

  .header_end a:nth-child(1) span {
    font-size: 11px;
  }

  .login-btn {
    font-size: 16px;
    padding: 3px 42px;
  }

  .rating-real-journalist h3 {
    color: var(--ffffff, #fff);
    text-align: center;
    font-family: "Public Sans";
    font-size: 40px;
  }

  .employer-newroom-box button.text-white {
    font-size: 23px;
  }

  .employer-newroom-box button {
    padding: 24px 50px !important;
  }

  .employer-login-form {
    padding: 10px 30px 117px 30px;
  }

  .employer-newroom-box {
    padding: 5px 10px 0px 10px;
  }

  .first-banner-txt h3 {
    width: 70%;
  }

  .search-stations-input input::placeholder {
    font-size: 18px;
  }

  .news-director-current {
    align-items: start;
    gap: 5px;
    flex-direction: column;
    margin: 0px 0px 6px 0px;
  }

  .employer-inputfield label {
    font-size: 16px;
  }

  .director-urer img {
    width: 60px;
  }

  .director-urer {
    display: flex;
    align-items: start;
    gap: 5px;
  }

  .director-name-txt h3 {
    font-size: 16px;
  }

  .director-name-txt {
    margin: 0px 0px 0px 0px;
  }

  .prod-dark-btn {
    font-size: 16px;
    line-height: 23px;
    padding: 8px 8px;
  }


  section.About-ah {
    padding: 60px 0px 10px 0px;
  }

  .KABC-tital-ah span h2 {
    font-size: 24px;
  }

  .Schedule-ah-card-box h2 {
    font-size: 22px;
  }

  .Job-list-1-ah span h2 {
    font-size: 22px;
  }

  .About-main-box-2-tital-ah{
    width: 94%;
    margin: 0px 0px;
  }

  .About-main-box-2-tital-ah h2 {
    font-size: 24px;
  }
  .Recommend-box-ah{
    padding-top: 20px;
  }

  .prod-light-btn {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 5px;
  }

  .ratemystation-prod-txt h3 {
    font-size: 17px;
    line-height: 25.5px;
    margin: 0px 0px 10px 0px !important;
    height: 60px;
  }

  .looking-for-worktxt {
    padding: 56px 0px;
    border-radius: 10px;
  }

  .looking-for-worktxt p {
    font-size: 20px;

    line-height: 31.5px;
    margin: 20px 0px 20px 0px !important;
  }

  .employer-inputfield input {
    padding: 13px 16px;
  }

  .employer-choose-subscription h6 {
    font-size: 15px;
  }

  .free-subscribe-four {
    gap: 11px;
  }

  .employer-register-subscription {
    padding: 32px 10px;
  }

  .employer-register-subscription h3 {
    font-size: 31px;
  }

  .employer-choose-subscription h6 {
    font-size: 15px;
  }

  .employer-subscription {
    padding: 20px 10px;
  }

  .employer-subscription h5 {
    font-size: 19px;
  }

  .employer-subscription h6 {
    font-size: 15px;
  }

  .employer-subscription span {
    font-size: 15px;
  }

  .employer-save {
    font-size: 10px;
    width: 65px;
    padding: 2px 4px;
  }

  .employer-dont-have-acc {
    margin: 0px 0px 20px 25px;
  }

  .rms-kabc-btn .themedark {
    padding: 8px 15px;
  }

  .rms-kabc-btn .themelight {
    padding: 8px 15px;
  }


  .employer-dont-have-acc a {
    font-size: 12px;
  }

  .employer-register-btn {
    margin: 25px 0px 35px 25px;
  }

  .employer-dont-have-acc a span {
    font-size: 15px;
  }

  .employer-register-btn a {
    font-size: 15px;
    padding: 4px 35px;
  }

  .employer-input-checkbox input {
    width: 17px;
    height: 17px;
  }

  .employer-input-checkbox label {
    color: #000;
    font-family: "Public Sans";
    font-size: 15px;
  }

  .ul-li-list-footer ul li a {
    font-size: 16px;
  }

  .ul-li-list-footer ul {
    gap: 30px;
  }

  .about-creator-img img {
    width: 400px;
  }

  .your-journalism-txt {
    padding: 45px 50px;
  }

  .your-journalism-txt h3 {
    font-size: 28px;
    line-height: 38px;
  }

  .journal-date-written {
    padding: 15px 0px;
  }

  .icon-list-footer {
    gap: 28px;
  }

  /* .subscribe-PAGE- */
  .subscribe-now-txt h3 {
    font-size: 40px;
  }

  .subcription-box h4 {
    font-size: 28px;
    padding: 50px 0px 10px 0px;
  }

  .subcription-box h6 {
    font-size: 21px;
    margin: 0px 0px 30px 0px;
  }

  .subcription-box a {
    font-size: 16px;
    padding: 12px 25px;
  }

  /* PRODUCT-DETAIL-PAGE */
  .Sweatshirt-ah h2 {
    font-size: 32px;
    line-height: 45px;
  }

  .Sweatshirt-ah-select-box {
    padding-top: 23px;
  }

  .Sweatshirt-ah-select-card h2 {
    font-size: 20px;
  }

  .Sweatshirt-ah-select-card button {
    font-size: 12px;
  }

  .Description-box-tital {
    gap: 2px;
    padding-top: 22px;
  }

  .Description-box-tital h2 {
    font-size: 28px;
  }

  .Description-box-tital p {
    font-size: 15px;
    line-height: 25px;
  }

  .satisfied h2 {
    font-size: 75px;
  }

  span.See button {
    font-size: 14px;
    gap: 10px;
    padding: 5px 10px !important;
  }

  span.Info button {
    font-size: 14px;
    padding: 5px 15px !important;
  }

  .filter-box select {
    width: 100px;
  }







  .chat_right_txt ul {
    overflow-y: scroll;
  }

  .employer-dont-have-acc {
    margin: 0px 0px 20px 25px;
  }

  .your-journalism-txt {
    padding: 65px 40px;
  }

  .shop-new-arrival h3 {
    font-size: 45px;
    width: 420px;
  }

  .shop-new-arrival-img img {
    width: 260px;
  }

  .d-flex.faq--work--card--info.align-items-center p {
    font-size: 11px;
  }

  .newsletter-article-search span {
    top: 36px;
  }


  .contact-follow-like-btn ul li {
    padding: 19px 41px 19px 39px;
  }

  .dashprod_filter_box {
    gap: 10px;
  }

  .dashfilter-select-box select {
    font-size: 16px !important;
    padding: 10px 20px 10px 10px;
  }

  .dashstock_box select {
    font-size: 16px !important;
    padding: 9px 30px 11px 16px;
  }

  .inlineprod_name h3 {
    font-size: 14px;
    padding: 0px 0px 4px 0px;
    width: auto;
  }


  .dashpages_data_box {
    overflow: scroll;
  }

  .inlineprod_name p {
    font-size: 12px;
  }

  .ahd-box-btn-card button {
    line-height: 20px;
  }

  .inlineprod_instock h6 {
    font-size: 14px;
    padding: 4px 10px;
    margin: 0px 10px 0px 0px;
  }

  .prod-dark-btn {
    font-size: 12px;
    line-height: 24px;
    padding: 6px 14px;
  }

  .prod-light-btn {
    font-size: 12px;
    line-height: 23px;
    padding: 6px 14px;
  }

  .inlineprod_action {
    margin: 0px 0px 0px 0px;
    padding: 6px 8px;

  }

  .inlineprod_category p {
    font-size: 14px;
    width: auto;
    padding: 0px 0px 0px 0px;
  }

  .inlineprod_price p {
    font-size: 14px;
    padding: 0px 0px 0px 0px;
  }

  .inlineprod_dtpub p {
    font-size: 14px;
  }

  .newroom-coworker-txt h6 {
    font-size: 12px;
    margin: 0px 0px 15px 0px;
  }

  .newroom-coworker-txt svg {
    width: 170px;
  }

  .about-creator-txt h5 {
    font-size: 20px;
  }

  .about-us-txt h4 {
    font-size: 20px;
  }

  .about-creator-img-name h4 {
    font-size: 20px;
  }

  .creator-messages h4 {
    font-size: 20px;
  }

  .subscribe-now-txt ul li {
    font-size: 13px;
  }

  .station-secure-box p {
    font-size: 14px;
  }

  .your-journalism-txt h3 {
    font-size: 24px;
  }

  .your-journalism-txt h5 {
    font-size: 15px;
  }

  .newsletter-article-text h4 {
    font-size: 24px;
  }

  .follow-us-rate ul li a {
    font-size: 16px;
  }

  .shop-new-arrival h3 {
    font-size: 46px;
  }

  .shop-new-arrival-img img {
    width: 230px;
  }

  .themed-cap img {
    width: 194px;
  }


  .contact-follow-like-btn ul li {
    padding: 16px 45px 17px 45px;
  }


  .Job-Openings-list-2 span p {
    font-size: 12px;
  }

  .moress span {
    top: 35px !important;
  }

  .Job-Openings-list-2 span {
    gap: 5px;
  }

  .Job-Openings-list-2 {
    padding: 8px 12px;
  }

  .inlineprod_action h5 {
    width: 50px;
  }

  .inlineprod_instock h5 {
    width: 140px;
  }

  .max--width {
    max-width: 1000px;
    min-width: 900px;
  }

  .product_dashboard_header_right {
    gap: 20px;
  }

  .order_dashboard_header_right {
    gap: 110px;
    margin: 0px 0px 0px 20px;
  }

  .order-header-select {
    gap: 22px;
  }

  .inlineprod_dtpub p {
    width: 100px;
  }

  .order_dashboard_header_right {
    gap: 50px;
  }

  .order-number-name {
    gap: 22px;
  }

  .inlinprod_detail {
    padding: 2px 0px 0px 20px;
  }

  .employer-newroom-box button {
    padding: 24px 50px !important;
  }

  .employer-login-form {
    padding: 10px 0px 100px 20px;
  }

  .rms-kabc-btn .themelight {
    padding: 8px 19px;
  }

  .rms-kabc-btn .themedark {
    padding: 8px 19px;
  }

  .mycart-order-summary a {
    padding: 6px 95px;
  }

  .filter-box {
    flex-wrap: nowrap;
  }

  section.ratemystation-shop-sec {
    padding: 0px 0px 15px 0px;
  }


  .cart-counter {
    gap: 5px;
    padding: 12px 20px;
  }

  .ratemystaion-contact-form p {
    margin: 0px 0px 80px 0px !important;
  }


  .input-field textarea {
    height: 160px;
  }

  .contact-follow-like-btn ul {
    gap: 28px;
  }

  .Job-list-2-ah {
    padding: 10px 20px;
  }

  .Job-card-ah {
    width: 75%;
  }

  .row .home-sec-two-pad-top-h {
    padding-top: 20px;
  }

  .About-main-card-1-ah h2 {
    padding: 0px 0px 0px 0px;
  }

  .header--end--logo {
    padding: 0px 0px 10px 0px;
  }

  .more-class {
    padding: 0px 0px 0px 40px;
  }

  .Job-list-1-ah span h2 {
    padding: 0px;
  }

  section.ratemystation-shop-sec h4 {
    margin: 50px 0px 50px 0px !important;
  }

  .filter-box select {
    width: 130px;
    padding: 12px 10px;
  }

  .product-quality-total {
    margin: 0px 110px 26px 0px;
  }

  .mycart-qualitytotal {
    display: flex;
    gap: 80px;
  }

  .ignore_delete_btns .delete_all {
    padding: 6px 45px;
  }

  .dashboard_newsletter_txt h4 {
    font-size: 24px;
    line-height: 29px;
  }

  .dashnewsletter_box img {
    width: 170px;
    height: 170px;
  }

  .newsletter-likecomment-share {
    gap: 10px;
  }

  .dashnewsletter_box {
    gap: 18px;
    padding: 0px 0px 25px 0px;
  }


  .new-online {
    width: 130px;
  }

  #customers td, #customers th {
    padding-left: 15px;
    padding-right: 10px;
  }


  .admin-dp {
    width: 50px;
  }

  section.ah1 {
    width: 100%;
    padding: 25px 20px;
  }

  .addnewprod_head {
    width: 80%;
  }

  .addnewprod_head a {
    padding: 4px 10px 4px 10px;
    display: inline-block;
  }

  .pagesdash_add_search {
    gap: 10px;
  }

  .pagesdash_pagessearch input:focus {
    outline: none;
  }

  .pagesdash_filter_flex select {
    padding: 11px 15px;
  }

  .pagesdash_filter_flex select:focus {
    outline: none;
  }

  .email_subscriber_table table tbody tr td h5 {
    padding: 0px 30px 0px 0px;
  }

  .email_subscriber_table table tbody tr td h6 {
    padding: 0px 30px 0px 0px;
  }

  .dashnewsletter_data_box table tbody tr td h2 {
    padding: 0px 20px 0px 21px;
  }


  .Employees-table-inbox {
    flex-wrap: nowrap;
  }

  .Employees-table-inbox {
    gap: 10px;
  }

  .Employees-box-btn-icon button {
    padding: 10px 15px;
  }

  .header-logo {
    width: 100%;
    padding: 0px 44px 0px 0px;
  }

  .ratemystation-allprod-btn a {
    padding: 10px 30px;
    line-height: 18px;
  }

  .looking-for-worktxt p {
    width: 54%;
  }

  .job-ah-Filter {
    width: 60%;
  }

  .header_nav ul {
    gap: 0px;
  }

  .Hire-ah-box p {
    color: #fff;
    text-align: center;
    font-family: "Public Sans", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.5px;
    padding: 25px 15px;
  }

  .Hire-ah-box h2 {
    font-size: 20px;
  }

  .Hire-ah-box button {
    width: 70%;
    padding: 11px;
    border-radius: 14px;
    font-size: 14px;
    line-height: 25.5px;
  }

  .job-ah-Filter-card select {
    padding: 8px 5px;
  }

  .Job-logo-ah {
    width: 18%;
  }

  .journalism-img-txt {
    height: 60vh;
  }

  .your-journalism-img img {
    border-radius: 10px 0px 0px 10px;
  }

  .latest-article-box img {
    height: 65vh;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px;
  }

  .Recommend-card-ah{
    margin: 0px 20px;
  }


}

@media screen and (max-width: 1024px) {
  .options-list {
    top: 60px; 
  }
  .options-list .select-option svg {
    color: #000;
    width: 30px;
    height: 31px;
}

  .Brooklyn-Simmons-card button {
    height: 44px;
  }

  .About-main-card-1-ah h2 {
    padding: 0px 0px 0px 0px;
  }
  .Website-ah-card button {
    padding: 8px;
  }

  .Website-ah-card span {
    gap: 0px;
}
  .Job-ah {
    padding-bottom: 20px;
  }

.Website-ah-card span h2 {
    font-size: 17px;
  }
  .Recommend-card-ah {
    width: 88%;
  }
  .Website-ah-card span h3 {
    font-size: 15px;
  }
  .Website-ah-card button svg {
    width: 20px;
    height: 20px;
}
  .Employees-table-inbox {
    flex-wrap: wrap;
  }

  .KABC-part-1-ah {
    width: 48%;
  }

  .Brooklyn-Simmons-tital span p {
    font-size: 12px;
  }

  .mycart-order-summary a {
    padding: 6px 55px;
  }

  .mycart-qualitytotal {
    gap: 75px;
  }

  .product-quality-total {
    margin: 0px 100px 26px 0px;
  }

  .mycart-order-summary h4 {
    margin: 0px 0px 20px 0px !important;
  }

  .About-main-card-1-ah h2 {
    font-size: 18px;
  }

  .rms-abc-logo img {
    width: 100%;
  }

  .side-box-my {
    width: 100%;
  }



  .rms-kabc-txt h6 {
    margin: 0px 0px 18px 0px;
  }

  .KABC-tital-ah {
    gap: 8px;
  }

  .main-KABC-ah {
    padding: 40px 30px 30px 40px;
  }

  .KABC-btn-box-ah {
    width: 25%;
    gap: 10px;
  }

  span.See button {
    padding: 8px;
    font-size: 14px;
    line-height: 30px;
  }

  .Industry-ah span p {
    font-size: 14px;
  }

  .search-stations-input--rms--alt input {
    width: 100% !important;
  }

  ul.save--share--pop li {
    padding: 6px;
  }

  .station-search-result-txt h4 {
    font-size: 30px;
  }

  ul.save--share--pop {
    padding: 5px;
    border-radius: 8px;
    width: 160px;
    right: 20px;
  }

  .station-search-result-txt p {
    font-size: 16px;
  }

  .article-reactions ul li a {
    font-size: 18px;
  }

  .station-search-result-txt p span {
    font-size: 16px;
  }

  .search-stations-input--rms .select-container {
    padding: 0px 20px;
    box-shadow: none;
  }

  .side-bar-my {
    position: absolute;
    z-index: 99;
  }

  header {
    z-index: 999;
  }

  section.ah1 {
    padding-left: 80px;
  }


  .search-stations-input--rms .select-box,
  .search-stations-input--rms option {
    font-size: 16px !important;
    padding: 15px 10px 15px 10px;
    width: 120px;
  }

  .select-icon {
    width: 30px;
    height: 30px;
  }

  .search-stations-input--rms span {
    top: 2px !important;
  }

  .search-stations-input--rms input {
    padding: 10px 20px;
    width: 400px;
  }

  .rms_header_logo {
    gap: 20px;
  }

  .search-stations-input--rms {
    margin: 0px 20px;
  }

  .station-search-result-txt {
    padding: 40px 0px 20px 0px;
  }

  .search-station-sec {
    padding: 45px 0px 0px 0px;
  }

  .rms-kabc-txt h3 {
    font-size: 22px;
  }

  .rms-kabc-btn .themedark {
    font-size: 14px;
    line-height: 22px;
    border-radius: 8px;
    padding: 8px 20px;
  }

  .news-director-card {
    padding: 20px 20px;
    border-radius: 10px;
    margin: 0px 0px 25px 0px;
  }

  .news-director-card-txt h5 {
    font-size: 20px;
  }

  .news-director-detail-btn a {
    padding: 10px 30px;
    font-size: 14px;
    line-height: 20px;
  }

  .rms-kabc-btn .themelight {
    font-size: 14px;
    line-height: 24px;
    border-radius: 8px;
    padding: 8px 20px;
  }

  .rms-profile-box {
    padding: 25px 20px;
    margin: 0px 0px 24px 0px;
  }

  .seeprofile-box-btn a {
    font-size: 16px;
    padding: 10px 30px;
  }

  .ratemystation-prod-txt h3 {
    font-size: 16px;
    line-height: 20px;
    height: 100%;
  }

  .ratemystation-prod-txt {
    padding: 15px;
  }

  .ratemystation-prod-txt h6 {
    font-size: 14px;
    margin: 0px 0px 15px 0px;
  }

  .ratemystation-prod-btn {
    justify-content: space-between;
    gap: 0px;
  }

  .ratemystation-shop-prod {
    border-radius: 14px;
  }

  .ratemystation-shop-prod img {
    object-fit: cover;
  }

  .prod-abslt-ratems {
    height: 100%;
  }

  section.ratemystation-shop-sec h4 {
    font-size: 24px;
    margin: 0px 0px 34px 0px !important;
  }

  section.ratemystation-shop-sec {
    padding: 30px 0px;
  }

  .find-station-looking {
    padding: 40px 20px 40px 20px;
  }

  .find-station-looking h4 {
    font-size: 24px;
  }

  .find-station-looking p {
    font-size: 18px;
    line-height: 28.5px;
  }

  .find-station-looking a {
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 8px;
  }

  .seeprofile-box-btn {
    margin: 0px 0px 30px 0px;
  }

  .rms-abc-logo {
    width: 18%;
  }

  .rms-profile-logo-box {
    align-items: flex-start;
  }

  .rms-rating-review svg {
    width: 150px;
  }

  .looking-for-work-sec {
    padding: 35px 0px;
  }

  .looking-for-worktxt h4 {
    font-size: 32px;
  }

  .looking-for-worktxt a {
    font-size: 18px;
    line-height: 31.5px;
    padding: 8px 42px;
  }

  .looking-for-worktxt {
    padding: 50px 0px;
  }

  .Industry-ah span {
    gap: 5px;
  }

  .About-main-card-1-ah {
    padding: 40px;
  }

  .About-main-card-1-ah h2 {
    font-size: 20px;
  }

  .Recommend-card-tital-ah h2 {
    font-size: 16px;
  }

  .Job-list-1-ah span h2 {
    font-size: 24px;
  }

  .Job-list-2-ah span p {
    font-size: 12px;
  }

  .more-class {
    padding: 10px 0px 10px 15px;
  }

  .Schedule-ah-card-box h2 {
    padding: 20px 0px 20px 0px;
    font-size: 18px;
    line-height: 24px;
  }

  .Schedule-ah-list-1 span p {
    font-size: 14px;
  }

  .Schedule-ah-list-1 span {
    gap: 8px;
  }

  .Soul-icon-box {
    gap: 10px;
  }

  .Schedule-ah-list-3 a span {
    font-size: 14px;
  }

  .Schedule-ah-list-3 a {
    font-size: 14px;
  }

  .Soul-icon-box span p {
    padding: 0px;
  }

  .Soul-icon-box span svg {
    width: 15px;
    height: 15px;
  }

  .KABC-tital-ah span h2 {
    font-size: 24px;
  }

  .Award-a {
    padding-top: 20px;
  }

  .Job-list-1-ah span h2 {
    font-size: 18px;
  }

  .Job-list-2-ah {
    padding: 8px 20px;
  }

  .Job-list-3-ah {
    padding-top: 20px;
  }

  .Job-list-1-ah span p {
    line-height: 10px;
    padding: 11px 0px;
    font-size: 16px;
  }

  .Job-list-1-ah {
    padding-bottom: 0px;
  }

  .Draft-ah {
    padding: 12px 0px 5px 0px;
  }

  .About-main-box-2-tital-ah h2 {
    font-size: 24px;
  }


  section.About-ah {
    padding: 60px 0px;
  }

  .Recommend-card-tital-ah {
    padding: 15px 20px;
  }

  .Industry-ah {
    padding-top: 15px;
  }

  .Recommend-box-ah {
    gap: 20px;
  }

  .padding-ah {
    padding: 30px 0px 15px 0px;
  }

  .About-main-box-1-ah {
    gap: 20px;
  }

  .ul-li-list-footer ul {
    gap: 20px;
  }

  .ul-li-list-footer ul li a {
    font-size: 14px;
  }

  .icon-list-footer {
    gap: 15px;
  }

  .navtabs {
    gap: 12px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .navtab {
    font-size: 16px;
    padding-bottom: 15px;
  }

  section.Statistic-ah {
    padding: 80px 0px;
  }

  .Overall-Statistic-ah h2 {
    font-size: 20px;
  }

  .ratings-ah-1-TITAL span h2 {
    font-size: 80px;
  }

  .ratings-ah-1-TITAL span p {
    font-size: 20px;
  }

  .ratings-ah-1-TITAL h3 {
    font-size: 18px;
  }

  .ratings-ah-1-bar {
    gap: 8px;
  }

  span.\34 5 h2 {
    font-size: 20px;
  }

  .pogras-card-ah span h2 {
    font-size: 20px;
  }

  .bar-pogres-box-cards-ah {
    gap: 15px;
    padding-bottom: 20px;
  }

  .gol-bar-tital {
    width: 32%;
  }

  .gol-bar-tital span p {
    font-size: 18px;
  }

  .gol-bar-head h2 {
    font-size: 65px;
  }

  .gol-bar-head p {
    font-size: 18px;
  }

  .Angeles-logo-side-box {
    width: 60%;
  }

  .job-ah-post h2 {
    font-size: 24px;
  }

  .job-ah-post h2 span {
    font-size: 20px;
  }

  .Director-ah-boxing-logo span h2 {
    font-size: 18px;
  }

  .Director-ah-boxing-logo span p {
    font-size: 14px;
  }

  .Director-ah-boxing p {
    font-size: 14px;
  }

  .Director-ah-boxing-3 button {
    padding: 10px;
    line-height: 24px;
    font-size: 14px;
  }

  .Director-ah-boxing-list-logo {
    width: 50%;
  }

  .Director-ah-boxing-list-span h2 {
    font-size: 14px;
  }

  .Director-ah-boxing-list-logo span h2 {
    font-size: 16px;
  }

  .Director-ah-boxing-2 {
    padding: 14px 0px 18px 0px;
  }

  .Angeles-logo-side-box span h2 {
    font-size: 20px;
  }

  .first-banner-txt h3 {
    font-size: 48px;
    width: 70%;
    margin: 0px 0px 50px 0px !important;
  }

  .search-stations-input input {
    width: 450px;
    padding: 15px 20px;
  }

  .select-station-drop select {
    padding: 18.5px 30px 18.5px 20px;
    width: 150px;
    margin: 0.4px 0px 0px 0px;
    font-size: 18px !important;
  }

  .select-container {
    padding: 20px 10px 20px 30px;
  }

  .selected-option {
    margin-top: 10px;
  }

  .search-stations-input.full-width input {
    width: 720px;
  }

  .select-tv-icon {
    top: 14px;
  }

  .first-banner-txt a {
    font-size: 20px;
  }

  .news-director-current {
    gap: 0px;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }

  .review-director-para h6 {
    font-size: 12px;
    margin: 0px 0px 5px 0px;
  }

  .review-director-para h5 {
    font-size: 14px;
    line-height: 24px;
  }

  .review-director-para p {
    font-size: 12px;
    line-height: 20px;
  }

  .director-urer {
    gap: 10px;
    align-items: flex-start;
  }

  .news-director-current h5 {
    font-size: 12px;
    margin: 0px 0px 2px 0px !important;
  }

  .news-director-current a {
    font-size: 8px;
    margin: 3px 0px 0px 0px !important;
  }

  .review-news-director {
    padding: 20px 20px;
    margin: 30px 0px 20px 0px !important;
  }

  .director-urer img {
    width: 50px;
  }

  .director-name-txt {
    margin: 0px 0px 0px 0px;
  }

  .director-name-txt h3 {
    font-size: 14px;
  }

  .latest-rating-user-txt h5 {
    font-size: 16px;
  }

  .verified-rating-box h6 {
    font-size: 14px;
  }

  .racial-discrimination h6 {
    font-size: 12px;
  }

  .racial-discrimination p {
    font-size: 14px;
  }

  .racial-discrimination h5 {
    font-size: 14px;
  }

  .newroom-coworker-txt svg {
    width: 100%;
  }

  .newroom-coworker-txt h6 {
    font-size: 12px;
  }

  .four-select-tab ul li a {
    font-size: 14px;
    line-height: 20px;
    padding: 6px 8px;
  }

  .like-report-txt ul li {
    font-size: 14px;
    line-height: 28px;
    padding: 4px 15px;
  }

  .latest-rating-comment-box {
    margin: 30px 0px 20px 0px;
    gap: 10px;
  }

  .latest-comment-txt-box span {
    font-size: 12px;
  }

  .latest-comment-txt-box {
    padding: 10px 10px;
  }

  .latest-comment-txt-box p {
    font-size: 14px;
    line-height: 26px;
    margin: 0px 0px 5px 0px;
  }

  .newsletter-follow-box h4 {
    font-size: 22px;
    margin: 0px 0px 10px 0px !important;
  }

  .follow-us-rate ul li a {
    font-size: 16px;
  }

  .side-subscribe-box h5 {
    font-size: 16px;
    line-height: 30px;
    margin: 0px 0px 10px 0px !important;
  }

  .side-subscribe-box input {
    padding: 12px 20px;
    margin: 0px 0px 20px 0px;
    border: none;
  }

  .side-subscribe-box button {
    padding: 10px 20px;
    font-size: 16px;
  }

  .side-newsletter-txt h5 {
    font-size: 16px;
  }

  .latest-rating-loadmore-btn a {
    font-size: 16px;
    padding: 10px 40px;
  }

  section.ratemystation-shop-sec h4 {
    margin: 0px 0px 30px 0px !important;
  }

  section.latest-rating-sec {
    padding: 50px 0px;
  }

  .ratemystation-prod-txt h3 {
    font-size: 16px;
    line-height: 24.5px;
    margin: 0px 0px 10px 0px !important;
    height: auto;
  }

  .prod-abslt-ratems {
    width: 30px !important;
    height: 45px;
  }

  .prod-light-btn {
    font-size: 12px;
    line-height: 20px;
    padding: 5px 4px;
  }

  .prod-dark-btn {
    font-size: 12px;
    line-height: 20px;
    padding: 5px 8px;
  }

  .ratemystation-prod-txt h6 {
    font-size: 12px;
  }

  .ratemystation-allprod-btn a {
    font-size: 16px;
    line-height: 23px;
    padding: 12px 20px;
  }

  .looking-for-worktxt h4 {
    font-size: 26px;
  }

  .looking-for-worktxt p {
    font-size: 18px;
    line-height: 28.5px;
    margin: 15px 0px 20px 0px !important;
  }

  .looking-for-worktxt a {
    font-size: 16px;
    line-height: 17.5px;
    padding: 14px 30px;
  }

  .looking-for-worktxt {
    padding: 40px 0px;
  }

  .looking-for-work-sec {
    padding: 30px 0px;
  }

  .ratemystation-about-txt h3 {
    font-size: 38px;
    line-height: 53px;
  }

  .about-us-txt h4 {
    font-size: 20px;
    margin: 0px 0px 10px 0px !important;
  }

  .about-creator-txt h5 {
    font-size: 20px;
    margin: 0px 0px 20px 0px !important;
  }

  .about-creator-para p {
    font-size: 14px;
    margin: 0px 0px 10px 0px !important;
  }

  .about-creator-img-name {
    border-radius: 0px 15px 15px 15px;
    padding: 8px 20px 5px 30px;
    bottom: 20px;
    right: 0px;
  }

  .about-creator-img-name::before {
    height: 72px;
    left: 0px;
  }


  .about-creator-img-name h4 {
    font-size: 20px;
    line-height: 26px;
  }

  .about-creator-img-name h6 {
    font-size: 14px;
    line-height: 26px;
  }

  .about-creator-img img {
    width: 330px;
  }

  .creator-messages {
    margin: 40px 0px 0px 0px;
    padding: 30px 30px 20px 30px;
  }

  .creator-messages h4 {
    font-size: 18px;
    margin: 0px 0px 10px 0px !important;
  }

  .your-journalism-txt {
    padding: 20px 35px 50px 35px;
  }

  .your-journalism-txt h3 {
    font-size: 22px;
    line-height: 35px;
  }

  .your-journalism-txt h5 {
    font-size: 13px;
  }

  .journal-date-written {
    padding: 10px 0px;
  }

  .journalism-slider .slick-dots {
    text-align: left;
    bottom: -30px;
  }

  .newsletter-likecomment-share {
    gap: 10px;
  }

  .newsletter-writtinsoul-txt p {
    font-size: 12px;
  }

  .newsletter-article-text h4 {
    font-size: 20px;
    line-height: 34px;
  }

  .newsletter-pagination-slide .pagination {
    padding: 5px 0px;
    border-radius: 20px;
  }

  .newsletter-slide-article img {
    width: 200px;
  }

  .more-soul-article img {
    width: 100px;
  }

  .more-soul-articletxt h4 {
    font-size: 20px;
  }

  .find-job-select-box span svg {
    width: 15px;
  }

  .find-job-select-box input {
    padding: 12px 40px 12px 40px;
  }

  .find-job-select-box input::placeholder {
    font-size: 14px;
  }

  .find-job-btn button {
    font-size: 16px;
    line-height: 26px;
    padding: 9px 10px;
  }

  .filter-box p {
    font-size: 16px;
  }

  .assistant-city-dollar-time {
    padding: 8px 20px;
  }

  .assistant-city-dollar-time p {
    font-size: 12px;
  }

  .assistant-director-txt h4 {
    font-size: 20px;
    line-height: 30px;
  }

  .assistant-director-txt h6 {
    font-size: 16px;
    line-height: 26px;
  }

  .assistant-news-director-box ul li {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0px 15px 0px;
  }

  .newsletter-pagination-slide .pagination a {
    padding: 8px 20px;
    font-size: 18px;
    line-height: 33px;
  }

  .find-job-matter-txt h4 {
    font-size: 40px;
    margin: 0px 0px 30px 0px !important;
  }

  .job-select-opts a {
    padding: 8px 65px;
    font-size: 16px;
  }

  .job-save-share a {
    font-size: 16px;
    padding: 8px 20px;
  }

  .job-detail-name-description {
    padding: 40px 30px;
  }

  .job-detailpg-nameflex img {
    width: 120px;
  }

  .job-opening-btn a {
    padding: 8px 35px;
    font-size: 16px;
  }

  .shop-new-arrival h3 {
    font-size: 38px;
    line-height: 53px;
    width: 380px;
  }

  .shop-new-arrival p {
    font-size: 14px;
    line-height: 26px;
    width: 260px;
    margin: 10px 0px 50px 0px !important;
  }

  .shop-new-arrival-img img {
    width: 220px;
  }

  .themed-cap img {
    width: 150px;
  }

  .themed-cap {
    right: 0px;
    top: 90px;
  }

  .theme-mug {
    top: 70px;
  }

  .theme-mug img {
    width: 150px;
  }

  .filter-box p {
    font-size: 14px;
  }

  .filter-box select {
    padding: 6px 5px;
    font-size: 12px;
    width: 100px;
  }
  .journal-date-written p {
    font-size: 12px;
  }
  .filter-box {
    gap: 5px;
  }
  .latest-rating-txt h3 {
    font-size: 24px;
  }
  .newsletter-side-head h3 {
    font-size: 24px;
  }
  .latest-rating-box {
    margin: 25px 0px;
  }

  .side-newsletter-box {
    margin: 12px 0px;
}
.side-subscribe-box{
  margin: 10px 10px ;
}
  .login-btn {
    font-size: 14px;
    border-radius: 10px;
    padding: 0px 20px;
  }

  .header_end a:nth-child(1) svg {
    width: 35px;
  }

  .slide {
    top: 49px;
  }
  .Job-Openings-list-3 button {
    gap: 4px;
    padding: 8px 12px;
    font-size: 9px;
  }
  .slide li {
    padding: 0px;
}
ul.slide a {
  font-size: 14px;
}
  .header_end a span {
    top: -3px;
    right: -2px;
    padding: 0px 6px;
    font-size: 10px;
  }

  .header_end {
    gap: 20px;
  }

  .shop-friend-themed {
    padding: 26px 30px;
    height: auto;
  }

  .header_nav ul li {
    font-size: 14px;
  }

  .header_end a:nth-child(1) span {
    font-size: 10px;
  }

  .header_end a svg {
    height: 30px;
  }

  .login-btn {
    font-size: 15px;
    line-height: 28px;
    padding: 4px 25px;
  }

  section.employer-login-pg-sec {
    padding: 45px 0px;
  }

  .rating-real-journalist h3 {
    font-size: 37px;
  }

  .employer-newroom-box button.text-white {
    font-size: 21px;
    padding: 24px 30px !important;
  }

  .employer-login-form {
    padding: 10px 20px 100px 20px;
  }

  .employer-newroom-box button {
    color: #828282;
    font-family: "Public Sans";
    font-size: 22px;
  }

  .employer-inputfield label {
    font-size: 14px;
  }

  .employerregister-field {
    margin: 8px 0px;
  }

  .employer-newroom-box {
    padding: 20px 10px 0px 10px;
  }

  .employer-dont-have-acc {
    margin: 10px 0px 0px 20px;
  }

  .employer-register-btn {
    margin: 15px 0px 30px 20px;
  }

  .employer-inputfield input {
    padding: 9px 8px;
  }

  .employer-inputfield input::placeholder {
    font-size: 12px;
    line-height: 22px;
  }

  .free-subscribe-four {
    gap: 5px;
  }

  .employer-subscription {
    padding: 15px 11px;
  }

  .employer-register-subscription {
    padding: 28px 12px;
  }

  .employer-register-subscription h3 {
    font-size: 27px;
  }

  .employer-choose-subscription h6 {
    font-size: 13px;
  }

  .employer-subscription h5 {
    font-size: 17px;
  }

  .employer-subscription h6 {
    font-size: 13px;
  }

  .employer-subscription span {
    font-size: 14px;
  }

  .employer-save {
    font-size: 11px;
  }

  .employer-dont-have-acc a {
    font-size: 11px;
  }

  .employer-dont-have-acc a span {
    font-size: 13px;
  }

  .employer-register-btn a {
    font-size: 14px;
    padding: 4px 32px;
  }

  .employer-input-checkbox label {
    font-size: 14px;
  }

  /* .subscribe-now-txt */
  .station-secure-box p {
    font-size: 14px;
    line-height: 23px;
    padding: 0px 25px 0px 0px;
  }

  .subcription-box::before {
    width: 132px;
    line-height: 24px;
    font-size: 15px;
    padding: 6px 0px 0px 0px;
  }

  .subcription-box a {
    font-size: 15px;
    padding: 10px 12px;
  }

  .subcription-box h4 {
    font-size: 20px;
    padding: 45px 0px 5px 0px;
  }

  .subcription-box h6 {
    font-size: 19px;
  }

  .subcription-box h5 {
    font-size: 22px;
    line-height: 20px;
    padding: 0px 0px 75px 0px;
  }

  .subcription-box span {
    font-size: 11px;
    width: 122px;
    padding: 6px 5px;
  }

  .subscribe-now-txt h3 {
    font-size: 30px;
    line-height: 55px;
  }

  .subscribe-now-txt ul li {
    font-size: 16px;
    line-height: 22px;
    padding: 6px 0px;
  }

  .subscribtion-credit-box {
    margin: 20px 0px 0px 0px;
    padding: 20px 0px 0px 0px;
  }

  /* PRODUCT-DETAIL */
  .About-main-card-1-ah h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .Sweatshirt-ah-select-box {
    padding-top: 12px;
  }

  .Sweatshirt-ah-select-card {
    display: flex;
    align-items: center;
    gap: 13px;
    width: 46%;
    max-width: 50%;
  }

  .Sweatshirt-ah-select-card {
    display: flex;
    align-items: center;
    gap: 13px;
    width: 46%;
    max-width: 50%;
  }

  .Sweatshirt-ah-select-box {
    padding-top: 12px;
  }

  .Sweatshirt-ah-select-card h2 {
    font-size: 20px !important;
  }

  .Sweatshirt-ah-select-card button {
    font-size: 11px;
  }

  .Description-box-tital p {
    font-size: 14px;
    line-height: 24px;
  }

  .Cooper-ah-box-main {
    width: 100%;
    padding-top: 15px;
  }

  .Cooper-ah-tital {
    padding: 12px 20px;
  }

  .Cooper-ah-tital h3 {
    font-size: 15px;
    line-height: 21px;
    padding: 8px 0px 2px 0px;
  }

  .satisfied h2 {
    font-size: 30px;
  }

  .Applied-ah-list-1 span h2 {
    font-size: 22px;
  }

  .Applied-ah-list-1 {
    padding-bottom: 18px;
  }

  .Applied-ah-card {
    padding: 24px 34px;
    border-radius: 20px;
    align-items: flex-start;
  }

  .Applied-ah-logo {
    width: 18%;
  }

  .Applied-ah-tital {
    width: 76%;
  }

  .Applied-ah-list-2 span p {
    font-size: 14px;
  }

  .Applied-ah-list-2 {
    padding: 8px 15px;
  }

  .Applied-ah-list-3 {
    padding-top: 20px;
  }

  /* CONTACT US */
  .ratemystaion-contact-form {
    padding: 40px;
  }

  .ratemystaion-contact-form p {
    font-size: 13px;
    line-height: 23px;
    margin: 0px 0px 85px 0px !important;
  }

  .contact-form-fields {
    padding: 40px;
  }

  .ratemystaion-contact-form h3 {
    font-size: 40px;
  }

  .ratemystaion-contact-form p {
    font-size: 14px;
    line-height: 24px;
    margin: 0px 0px 90px 0px !important;
  }

  .ratemystaion-contact-form ul li {
    font-size: 15px;
    line-height: 22px;
  }

  .input-field {
    margin: 0px 0px 15px 0px;
  }

  .input-field textarea {
    height: 145px;
  }

  .contact-form-btn button {
    padding: 8px 55px;
  }

  .contact-follow-like-btn ul li {
    padding: 12px 30px;
  }

  .contact-latest-updates {
    padding: 0px 25px 20px 20px;
  }

  .americon-prod-cart img {
    width: 100px;
  }

  .americon-prod-cart h3 {
    color: #000;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0px;
  }

  .cart-counter {
    padding: 8px 12px;
    margin: 0px 6px;
  }

  .americon-prod-cart h4 {
    padding: 0px 10px;
  }

  .americon-prod-cart h6 {
    margin: 0px;
  }

  .review-news-director-second {
    margin: 40px 0px 0px 0px !important;
  }

  .job-details {
    width: 480px;
  }

  .job-detail p {
    font-size: 14px;
  }

  .job-detail span {
    font-size: 10px;
  }

  .job-detail {
    flex: auto;
  }

  .search-stations-input span {
    top: 8px;
    font-size: 32px;
  }

  .helo-drop .drop-out {
    padding: 2px;
  }

  nav.helo-drop label {
    gap: 5px;
  }

  .header-end-rmssearch {
    gap: 10px;
  }

  .header--end--logo {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0px;
  }

  .header-end-rmssearch a svg {
    height: 30px;
    padding: 5px 3px 5px 0px;
    width: 30px;
  }

  .user--pic--header {
    width: 30%;
  }

  .shop-new-arrival {
    height: auto;
    padding: 40px 40px;
  }

  .chatmain_head_flex {
    padding: 10px 20px 10px 20px;
  }

  .reported_chat_userOne {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .reported_chatting_box {
    padding: 40px 20px 25px 20px;
    border: 1px solid #e0e0e0;
  }

  .reported_chatting_box {
    padding: 40px 20px 25px 20px;
    border: 1px solid #e0e0e0;
  }

  .chatting_mxg p {
    font-size: 14px;
  }

  .chatting_mxgme p {
    font-size: 14px;
  }

  .ignore_delete_btns .ignore_all {
    padding: 6px 47px;
  }

  .ahd-box-1 h2 {
    font-size: 20px;
  }

  .ahd-box-btn {
    padding-top: 18px;
  }

  .ahd-box-btn-card button {
    padding: 5px 25px;
    font-size: 14px;
    width: 48%;
  }

  .ahd-box-1 {
    padding-bottom: 25px;
  }

  .ahd-card-head p {
    font-size: 14px;
  }

  span.approved-s p {
    font-size: 14px;
  }

  .ahd-card-head {
    border-radius: 25px 25px 0px 0px;
  }

  .Reporter-dah-box-dp span h2 {
    font-size: 16px;
  }

  .Reporter-dah-box-dp-2 h2 {
    font-size: 16px;
  }

  .Reporter-dah-box {
    padding: 30px 25px 25px 25px;
  }

  .Simmons-dah-id-site span p {
    font-size: 12px;
  }

  .Simmons-dah-dp-site {
    width: 35%;
  }

  .Simmons-dah-id-site h2 {
    font-size: 15px;
  }

  .Simmons-dah-tital-site h3 {
    font-size: 14px;
    line-height: 20px;
  }

  .Simmons-dah-h2 h2 {
    font-size: 14px;
  }

  .Simmons-dah-h2 h3 {
    font-size: 14px;
  }

  .Reporter-dah-body-new {
    gap: 20px;
  }

  .Simmons-dah-more-card p {
    font-size: 12px;
  }

  .Simmons-dah-more-card h2 {
    font-size: 14px;
  }

  .Reporter-dah-body-new-last {
    padding: 20px 24px;
  }

  .Reporter-dah-body-new-last button {
    padding: 5px 30px;
    font-size: 14px;
  }

  .ahd-box-2 {
    gap: 25px;
  }

  .Reporter-dah-body-new-last svg {
    width: 10px;
    height: 24px;
  }

  .your-journalism-sec ul.slick-dots {
    bottom: 28px;
    right: 28%;
  }

  .your-journalism-sec::before {
    height: 350px;
  }

  .contact-follow-like-btn ul {
    gap: 28px;
  }

  .Job-Openings-list-1 span h2 {
    font-size: 16px;
  }

  .Job-Openings-list-2 span p {
    font-size: 9px;
  }


  .Job-Openings-card {
    padding: 20px 14px;
  }



  .Job-Openings-list-3 button {
    padding: 8px 6px;
  }

  .navtabs ul {
    margin: 0px;
  }

  .job-ah-Filter-card select {
    padding: 10px 5px;
  }

  .find-job-select-box span {
    top: 8px;
  }


}

@media (max-width: 991px) {
  .header--2--main--cont {
    max-width: 850px !important;
  }

  .header--2--main--row {
    display: grid !important;
    grid-template-columns: auto auto auto;
  }

  .Sweatshirt--box--alt {
    flex-direction: column;
    gap: 30px;
  }

  .Sweatshirt--part--alt {
    width: 100%;
  }

  .rate--product--button--container--ah {
    width: 50%;
    margin: 0 auto;
  }

  .search-stations-input--rms--alt input {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .options-list {
    top: 50px;
  }
  .About-main-ah {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .America-Sweatshirt {
    flex-direction: column;
    text-align: center;
  }

  .header-logo {
    width: 35%;
    padding: 0px;
  }

  .Sweatshirt-ah {
    width: 100%;
  }

  .Cooper-ah-imger {
    width: 9%;
  }

  .news-director-detail-btn {
    justify-content: flex-end;
  }

  .ahd-card-body-start-btn {
    width: 35%;
  }

  .ahd-card {
    width: 100%;
  }

  .ahd-card-body-start-btn button {
    line-height: 25px;
  }

  .Uploaded-documents-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 14px;
  }

  .Uploaded-documents-card {
    width: 100%;
  }

  .About-main-box-1-ah {
    width: 100%;
  }

  .rms_header_logo a {
    display: block;
  }

  .rms_header .header_end {
    display: none;
  }

  .rms-kabc-btn .themedark {
    padding: 8px 30px;
  }

  .setting_dashboard_tabs .react-tabs__tab-list .react-tabs__tab {
    padding: 9px 15px;
    border-radius: 10px;
  }

  .setting_dashboard_tabs .react-tabs__tab-list {
    display: flex;
    gap: 20px;
  }

  .addnewprod_head {
    margin: 0px 0px 20px 0px;
  }

  .inlineprod_instock h6 {
    width: 100px;
    text-align: center;
  }

  .inlineprod_name h3 {
    width: 200px;
  }

  .product_dashboard_header .flex-box {
    gap: 160px;
  }

  .inlineprod_price p {
    width: 30px;
  }

  .inlineprod_amout {
    margin: 0px 20px 0px 40px;
  }

  .inlineprod_category p {
    padding: 0px 0px 0px 0px;
    width: 170px;
  }


  .inlineprod_dtpub p {
    width: 130px;
  }

  .inlineprod_action h5 {
    width: 50px;
  }

  .inlineprod_action {
    margin: 0px 30px 0px 0px;
  }

  .product_dashboard_header {
    width: 940px;
  }

  .product_dashboard_header_right {
    gap: 50px;
  }

  .flex-box-order {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .order-number-name h4 {
    width: 140px;
  }

  .flex-box-order .order_dashboard_header_right {
    gap: 70px;
  }

  .flex-box-order .inlineprod_price p {
    width: 150px;
    padding: 0px 0px 0px 30px;
  }

  .header_main_Mobile {
    background-color: #fff;
    padding: 20px 0px 15px 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1000;
    display: block !important;
  }

  .header_mob_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .header_main_Mobile .header-logo img {
    cursor: pointer;
    width: 140px;
  }


  .header_nav {
    display: flex;
    align-items: center;
  }

  .header_nav button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .header_nav .toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35px;
    height: 25px;
    margin: 0px 10px 0px 0px;
  }

  .header_nav .toggle .bars {
    height: 4px;
    transition: all 0.3s ease;
  }

  .sidepanel {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    background-color: #194d79;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidepanel a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #fff;
    display: block;
    transition: 0.3s;
    font-family: 'Public Sans';
  }

  .sidepanel a:hover {
    color: #f1f1f1;
  }

  .sidepanel .closebtn {
    position: absolute;
    top: 25px;
    right: 20px;
    font-size: 36px;
  }

  #mySidepanel.open {
    width: 250px;
  }

  /* #checkbox:checked + label .bars {
  background-color: #f1f1f1; 
}

#checkbox:checked + label .bars:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px); 
}

#checkbox:checked + label .bars:nth-child(2) {
  opacity: 0; 
}

#checkbox:checked + label .bars:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
} */

  header.header_main {
    display: none;
  }

  .toggle #barz1 {
    width: 100%;
    transform: rotate(45deg);
    transition-duration: 0.5s;
    top: 0px;
    position: absolute;
    background-color: #fff;
  }

  .toggle #barz3 {
    width: 100%;
    transform: rotate(-45deg);
    transition-duration: 0.5s;
    background-color: #fff;
  }


  .journalism-img-txt {
    flex-direction: column;
    width: 100%;
  }

  .your-journalism-img {
    width: 100%;
    border-radius: 20px 20px 0px 0px;
  }

  .your-journalism-txt {
    border-radius: 0px 0px 20px 20px;
    width: 100%;
    padding: 20px 35px 70px 35px;
  }

  .your-journalism-sec ul.slick-dots {
    bottom: 20px;
    right: 40%;
  }

  .filter-box select {
    width: 100px;
  }

  .filter-short-box {
    gap: 10px;
  }

  .filter-box {
    gap: 10px;
    flex-wrap: nowrap;
  }

  .newsletter-article-search input {
    width: 100%;
  }

  .newsletter-article-search span {
    top: 55px;
    right: 5%;
  }

  .newsletter-follow-box h4 {
    margin: 0px 0px 20px 0px !important;
  }


  .contact-follow-like-btn ul {
    gap: 28px;
  }

  .ratemystaion-contact-form {
    padding: 40px 40px 0px 25px;
  }


  .newsletter-input-search {
    margin: 50px 0px 20px 0px;
  }

  .newsletter-side-head {
    margin: 36px 0px 36px 12px;
  }

  .employer-login-form {
    padding: 10px 0px 30px 0px;
  }

  .employer-dont-have-acc {
    margin: 10px 0px 30px 40px;
  }

  .employer-dont-have-acc a {
    font-size: 14px;
  }

  .employer-inputfield input {
    padding: 15px 10px;
  }

  .rating-real-journalist {
    padding: 165px 50px;
    border-radius: 20px 20px 0px 0px;
  }

  .contact-latest-updates {
    padding: 0px 25px 20px 25px;
  }

  .ratemystaion-contact-form p {
    margin: 0px 0px 30px 0px !important;
  }

  .contact-form-fields {
    padding: 40px 40px 40px 25px;
  }

  .shop-new-arrival {
    margin: 0px 10px 20px 10px;
  }

  section.ratemystation-shop-sec {
    padding: 0px 0px 30px 0px;
  }

  .shop-category ul li button {
    font-size: 16px;
  }

  .about-creator-img img {
    width: auto;
  }

  .about-creator-img-name::before {
    height: 80px;
  }

  .americon-prod-cart h3 {
    width: 190px;
  }

  .mycart-qualitytotal {
    gap: 85px;
  }

  .product-quality-total {
    margin: 0px 120px 26px 0px;
  }

  section.rms_header {

    display: none;
  }


  .about-creator-para {
    padding: 30px 0px 0px 0px;
  }

  .job-save-share {
    width: 100%;
  }

  .job-select-opts a {
    width: 100%;
  }

  .job-select-opts {
    margin: 20px 0px 0px 0px;
  }


  .contact-form-station {
    padding: 20px 20px 40px 20px;
  }


  span.See button {
    display: inline-block;
  }


  span.Info button {
    font-size: 14px;
    padding: 5px 12px !important;
    display: inline-block;
  }


  .react-tabs__tab {
    padding: 6px 6px;
  }

  .main-KABC-ah {
    padding: 40px 30px 30px 30px;
  }

  .KABC-part-1-ah {
    width: 65%;
  }

  .KABC-logo-ah button {
    padding: 6px 18px;
  }

  .KABC-tital-ah p {
    font-size: 18px;
    gap: 10px;
  }

  .KABC-tital-ah p span {
    font-size: 20px;
  }

  span.See button svg {
    padding: 0px 6px 0px 0px;
  }

  .About-main-box-2-ah {
    width: 100%;
  }


  .Job-Openings-list-3 button {
    padding: 10px 15px;
  }

  .Job-Openings-card {
    padding: 30px 25px;
  }

  section.ratemystation-shop-sec h4 {
    margin: 30px 0px 30px 0px !important;
  }

  .select-container {
    padding: 15px 10px 15px 30px;
  }

  .selected-option {
    margin-top: 7px;
  }


}

@media screen and (max-width: 600px) {

  .ul-li-list-footer ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .Sweatshirt-ah-select-card button {
    width: 40%;
  }

  .About-main-box-2-ah {
    width: 100%;
  }

  .About-main-box-2-tital-ah h2 {
    font-size: 18px;
  }

  .About-main-box-2-tital-ah a {
    font-size: 15px;
  }

  .Recommend-box-ah {
    gap: 20px;
    padding-top: 25px;
  }

  .Recommend-card-tital-ah {
    padding: 10px 14px;
  }

  .Recommend-card-tital-ah h2 {
    font-size: 14px;
  }

  .ahd-card-body-start-btn {
    width: 100%;
  }

  section.ah1 {
    padding-left: 70px !important;
  }

  .ahd-card-body-start-btn button {
    line-height: 25px;
  }

  .ahd-card-body-start {
    padding: 20px;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .ahd-card-body-end {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  section.director-search-result hr {
    margin: 0px;
  }

  .Recommend-card-tital-ah h2 {
    font-size: 14px;
  }

  .Applied-ah-list-1 span h2 {
    font-size: 22px;
  }

  .news-director-card-txt h5 {
    font-size: 18px;
  }

  section.ah1 {
    padding: 20px 35px;
  }

  .ahd-box-1 h2 {
    font-size: 16px;
  }

  .ahd-box-1 p {
    font-size: 14px;
  }

  .ahd-box-btn {
    padding-top: 15px;
  }

  .ahd-box-btn-card button {
    padding: 5px 25px;
    font-size: 14px;
    line-height: 28px;
    width: 48%;
  }

  .ahd-box-1 {
    padding-bottom: 20px;
  }

  .ahd-box-2 {
    gap: 20px;
    padding: 24px 0px;
  }

  .ahd-card-head {
    border-radius: 20px 20px 0px 0px;
    padding: 20px;
  }

  .ahd-card {
    width: 100%;
  }

  .Reporter-dah-box {
    padding: 20px;
  }

  .Reporter-dah-box-dp span h2 {
    font-size: 14px;
  }

  .Reporter-dah-box-dp-2 p {
    font-size: 14px;
  }


  .dp-imger {
    width: 40px;
  }

  .Reporter-dah-body-new {
    padding: 20px;
  }

  .Simmons-dah-tital-site h3 {
    font-size: 12px;
    line-height: 18px;
  }

  .Simmons-dah-dp-site {
    align-items: flex-start;
    gap: 14px;
    flex-direction: column;
    width: 100%;
  }

  .Simmons-dah-img-site {
    width: 54px;
  }

  .Simmons-dah-id-site h2 {
    font-size: 14px;
  }

  .Simmons-dah-more-btn button {
    padding: 4px 15px;
    font-size: 14px;
  }

  .Simmons-dah-more-box {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  .Reporter-dah-body-new-last button {
    line-height: 30px;
  }

  .ahd-box-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  section.ah1 {
    padding: 20px;
  }

  .ahd-card-head {
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .Reporter-dah-box-dp {
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
  }

  .Reporter-dah-box-dp-2 {
    gap: 5px;
    align-items: flex-end;
    flex-direction: column;
  }

  .Reporter-dah-box {
    padding: 15px;
  }

  .Simmons-dah-box {
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .Simmons-dah-tital-site {
    width: 100%;
  }

  .Applied-ah-list-1 {
    padding-bottom: 18px;
  }

  .Applied-ah-card {
    padding: 24px 34px;
    border-radius: 20px;
    align-items: flex-start;
  }

  .rms-profile-logo-box {
    flex-direction: column;
  }

  .station-search-result-txt h4 {
    font-size: 18px;
  }

  .station-search-result-txt p {
    font-size: 14px;
  }

  .toggle {
    width: 30px;
    height: 33px;
    gap: 6px;
  }

  .station-search-result-txt p span {
    font-size: 14px;
  }

  .search-station-sec {
    padding: 20px 0px 0px 0px;
  }

  .Applied-ah-logo {
    width: 18%;
  }

  .job--open--dash--news--h--filter--main,
  .job--open--dash--news--h--filter--sort {
    align-items: flex-start;
    flex-direction: column;
  }

  .Recommend-card-ah {
    width: 100%;
  }

  .Applied-ah-tital {
    width: 76%;
  }

  .Applied-ah-list-2 span p {
    font-size: 14px;
  }

  .Applied-ah-list-2 {
    padding: 8px 15px;
  }

  .Applied-ah-list-3 {
    padding-top: 20px;
  }

  .Applied-ah-card {
    flex-direction: column;
    gap: 20px;
  }

  .Applied-ah-tital {
    width: 100%;
  }

  .Applied-ah-list-1 span h2 {
    font-size: 20px;
  }

  .Applied-ah-list-1 {
    padding-bottom: 15px;
  }

  .Applied-ah-card {
    padding: 15px 20px;
  }

  .Applied-ah-list-1 span p {
    font-size: 18px;
  }

  .Applied-ah-list-2 span p {
    font-size: 12px;
  }

  .Applied-ah-list-2 span {
    gap: 5px;
  }

  .Applied-ah-list-2 span svg {
    width: 14px;
    height: 14px;
  }

  .KABC-box-ah {
    flex-direction: column;
    gap: 20px;
  }

  .KABC-part-1-ah {
    width: 100%;
  }

  .Schedule-ah-card {
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
  }

  .Schedule-ah-card-box {
    width: 100%;
    padding: 0px;
  }

  .Schedule-ah-imger {
    width: 32%;
  }

  .KABC-btn-box-ah {
    width: 100%;
  }

  section.KABC-ah {
    padding-top: 40px;
  }

  .Jacob-card-ah {
    width: 100%;
    padding: 20px;
  }

  .Director-ah-boxing-3 button {
    padding: 10px;
    line-height: 22px;
    font-size: 12px;
  }

  .Director-ah-boxing-list-logo {
    gap: 20px;
  }

  section.About-ah {
    padding: 140px 0px 10px 0px;
  }

  .Director-ah-boxing-3 button {
    padding: 10px;
    font-size: 11px;
    line-height: 5px;
  }

  .About-main-card-1-ah h2 span {
    font-size: 15px;
  }

  .Sweatshirt-ah-select-box {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 65px;
    padding-top: 3px;
    justify-content: center;
  }

  .Sweatshirt-ah-select-card button {
    font-size: 11px;
    line-height: 24px;
    width: 100%;
  }

  .Sweatshirt-ah-select-card h2 {
    font-size: 11px !important;
  }

  .Sweatshirt-ah-select-card select {
    padding: 11px;
    font-size: 11px;
    line-height: 26px;
  }

  .Sweatshirt-ah-select-card p {
    font-size: 13px;
  }

  .Description-box-tital {
    gap: 1px;
    padding-top: 15px;
  }

  .Description-box-tital p {
    font-size: 12px;
    line-height: 23px;
  }

  .Description-box-tital a {
    font-size: 14px;
  }

  .Cooper-ah-imger {
    width: 8%;
  }

  .Cooper-ah-tital h3 {
    font-size: 13px;
    line-height: 20px;
  }

  .Cooper-ah-box-main {
    padding-top: 10px;
  }

  .About-main-card-1-ah {
    padding: 20px;
  }

  .Award-a a {
    font-size: 16px;
  }

  .satisfied h3 {
    font-size: 17px;
    padding: 10px 0px 10px 0px;
  }

  .ratings-ah-1-bar {
    gap: 15px;
  }

  .Award-a {
    padding-top: 10px;
  }

  .ul-li-list-footer ul {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .About-main-box-2-ah {
    width: 100%;
  }

  .About-main-box-2-tital-ah h2 {
    font-size: 18px;
  }

  .About-main-box-2-tital-ah a {
    font-size: 15px;
  }

  .Recommend-box-ah {
    gap: 20px;
    padding-top: 25px;
  }

  .Recommend-card-tital-ah {
    padding: 10px 14px;
  }

  .Recommend-card-tital-ah h2 {
    font-size: 14px;
  }

  .KABC-box-ah {
    flex-direction: column;
    gap: 20px;
  }

  .KABC-part-1-ah {
    width: 100%;
  }

  .KABC-btn-box-ah {
    width: 100%;
  }

  section.KABC-ah {
    padding-top: 40px;
  }

  .Jacob-card-ah {
    width: 100%;
    padding: 20px;
  }

  .Director-ah-boxing-3 button {
    padding: 10px;
    line-height: 22px;
    font-size: 12px;
  }

  .Director-ah-boxing-list-logo {
    gap: 20px;
  }

  .Angeles-ah-icon-side {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .Angeles-logo-side-box {
    width: 100%;
  }

  .Angeles-ah-box {
    flex-direction: column-reverse;
    gap: 10px;
  }

  .Angeles-logo-ah {
    width: 30%;
  }

  .About-main-card-1-ah p {
    padding: 15px 0px 10px 0px;
    font-size: 14px;
  }

  .bar-pogres-box-cards-ah {
    flex-direction: column;
  }

  .pogras-card-ah {
    width: 100%;
    justify-content: space-between;
  }

  .bar-pogres-box-cards-ah {
    flex-direction: column;
  }

  .pogras-card-ah {
    width: 100%;
    justify-content: space-between;
  }

  .Overall-Statistic-ah {
    flex-direction: column;
    gap: 20px;
  }

  .Overall-Statistic-ah ul {
    width: 100%;
  }

  .ratings-ah-1-box {
    width: 100%;
  }

  .gol-bar-tital {
    width: 100%;
    gap: 15px;
  }

  .gol-bar-head h2 {
    font-size: 45px;
  }

  .ratings-ah-1-bar {
    width: 100%;
  }

  section.Statistic-ah {
    padding: 160px 0px 50px 0px;
  }

  .navtabs {
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-KABC-ah {
    padding: 30px 30px 20px 30px;
  }

  .Industry-ah {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .gol-bar-box {
    width: 70%;
  }

  .Brooklyn-Simmons-card {
    flex-direction: column;
    gap: 20px;
  }

  .Brooklyn-Simmons-logo-div {
    width: 100%;
    gap: 20px;
  }

  .Brooklyn-Simmons-card button {
    line-height: 28px;
  }

  span.edite svg {
    width: 20px;
    height: 20px;
  }

  .Award-card-ah {
    align-items: flex-start;
  }

  .About-main-card-1-ah h2 span {
    font-size: 16px;
  }

  .Job-box-ah {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  .Job-card-ah {
    width: 100%;
  }

  .Job-list-1-ah span h2 {
    font-size: 18px;
  }

  .Job-list-2-ah {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  .KABC-tital-ah span h2 {
    font-size: 18px;
  }

  .ratings-ah-1-TITAL span h2 {
    font-size: 60px;
  }

  .ratings-ah-1-TITAL span p {
    font-size: 16px;
    padding-bottom: 5px;
  }

  .job-ah-post h2 {
    font-size: 20px;
  }

  .job-ah-Filter {
    width: 64%;
    gap: 25px;
  }

  .job-ah-Filter-card p {
    font-size: 16px;
  }

  .job-ah-Filter-card select {
    padding: 8px 10px;
    font-size: 15px;
    border-radius: 5px;
  }

  .director-urer img {
    width: 10%;
  }

  .director-urer {
    padding-bottom: 24px;
  }

  .Hire-ah-box h2 {
    font-size: 24px;
  }

  .Hire-ah-box p {
    font-size: 16px;
    padding: 18px 8px;
  }

  .Hire-ah-box button {
    padding: 8px;
    font-size: 14px;
  }

  .four-select-tab {
    margin: 25px 0px 0px 0px;
    padding: 0px 0px 20px 0px;
  }

  .verified-rating-box h6 {
    font-size: 14px;
  }

  .scrllo-bar p {
    padding: 0px;
    line-height: 24px;
  }

  .w3-light-grey.w3-tiny {
    height: 12px;
  }

  .Popular-ti-ah-btn-box button {
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 8px;
  }

  .Popular-ti-ah-btn-box {
    gap: 12px;
  }

  .latest-rating-user-verified {
    flex-direction: column;
    gap: 18px;
  }

  .rating-user-box {
    width: 100%;
  }

  .verified-rating-box {
    width: 100%;
    justify-content: space-between;
  }

  .job-ah-post {
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
  }

  .job-ah-Filter {
    width: 100%;
  }

  .job-ah-post h2 {
    font-size: 18px;
  }

  .job-ah-post h2 span {
    font-size: 15px;
  }

  .review-news-director {
    margin: 35px 0px 25px 0px;
  }

  .review-news-director {
    margin: 25px 0px 20px 0px;
  }

  .review-director-para p {
    font-size: 14px;
  }

  .racial-discrimination p {
    font-size: 14px;
  }

  .racial-discrimination h5 {
    font-size: 14px;
  }

  .like-report-submitted {
    margin: 12px 0px;
  }

  .latest-rating-comment-box {
    margin: 25px 0px 5px 0px;
  }

  .latest-comment-txt-input-box input {
    padding: 12px 40px 12px 18px;
    font-size: 16px;
  }

  .latest-rating-box {
    margin: 0px 0px 30px 0px;
  }

  .free-subscribe-four {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }

  .employer-register-subscription {
    padding: 28px 24px;
  }

  .employer-subscription {
    width: 47%;
    padding: 15px 11px;
  }

  .new-subscription-discount {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }

  .new-subscription-discount p {
    font-size: 13px;
  }

  .new-subscription-discount a {
    font-size: 14px;
  }

  /* .subscribe- */
  .subcription-page-sec {
    padding: 35px 0px;
  }

  .subscribe-now-txt h3 {
    font-size: 30px;
    line-height: 45px;
  }

  .subscribe-now-txt p {
    font-size: 14px;
    line-height: 24px;
  }

  .subscribe-now-txt ul li {
    font-size: 14px;
    line-height: 20px;
    padding: 4px 0px;
  }

  .subscribe-now-txt ul li svg {
    position: absolute;
    left: -26px;
    top: 8px;
  }

  .subcription-box {
    margin-bottom: 20px;
    padding: 15px;
  }

  .station-secure-box {
    display: flex;
    align-items: flex-start;
    gap: 11px;
  }

  .station-secure-box svg {
    width: 50px;
    height: 25px;
  }

  .station-secure-box p {
    font-size: 13px;
    line-height: 23px;
    padding: 0px 5px 0px 0px;
  }

  .subscription-credit p {
    font-size: 14px;
    line-height: 30px;
    padding-bottom: 10px;
  }

  .subcription-box h4 {
    font-size: 20px;
    padding: 40px 0px 5px 0px;
  }

  .subcription-box h6 {
    font-size: 19px;
    margin: 0px 0px 25px 0px;
  }

  /* PRODUCT-QUALITY */
  .Cooper-ah-box {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
  }

  .America-Sweatshirt {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .America-ah-part-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0px;
  }

  .About-main-card-1-ah p {
    padding: 14px 0px 10px 0px;
    font-size: 16px;
  }

  .Sweatshirt-ah-select-card input {
    padding: 5px;
    font-size: 14px;
    line-height: 25px;
  }

  .Sweatshirt-ah-select-box {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 3px;
    justify-content: center;
    margin: 0px 0px 10px 0px;
  }

  .Sweatshirt-ah-select-card {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 35%;
    justify-content: center;
    margin: 0;
  }

  .Sweatshirt-ah-select-card select {
    padding: 11px;
    width: 100px;
    font-size: 11px;
    line-height: 26px;
  }

  .Sweatshirt-ah-select-card input {
    padding: 6px;
    width: 100px;
  }

  .Sweatshirt-ah-select-card h2 {
    font-size: 13px !important;
  }

  .Sweatshirt-ah-select-card {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 50%;
  }

  .About-main-card-1-ah p {
    padding: 10px 0px 5px 0px;
    font-size: 14px;
    line-height: 20px;
  }

  .Sweatshirt-box {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 30px 0px 60px 0px;
    flex-direction: column;
    gap: 20px;
  }

  .Cooper-ah-imger {
    width: 17%;
  }

  .Cooper-ah-tital-head {
    width: 100%;
    display: flex;
    align-items: left;
  }

  .Brooklyn-Simmons-card {
    flex-direction: column;
    gap: 20px;
  }

  .Overall-Statistic-ah ul {
    width: 100%;
  }

  .ratings-ah-1-box {
    width: 100%;
  }

  .gol-bar-tital {
    width: 100%;
    gap: 15px;
    padding: 30px 0px 0px 0px;
  }

  .gol-bar-head h2 {
    font-size: 45px;
  }

  .ratings-ah-1-bar {
    width: 100%;
  }

  section.Statistic-ah {
    padding: 180px 0px 50px 0px;
  }

  .navtabs {
    justify-content: flex-start;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-KABC-ah {
    padding: 30px 30px 20px 30px;
  }

  .Industry-ah {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .gol-bar-box {
    width: 70%;
  }

  .Brooklyn-Simmons-card {
    flex-direction: column;
    gap: 20px;
  }

  .Brooklyn-Simmons-logo-div {
    width: 100%;
    gap: 20px;
  }

  .Brooklyn-Simmons-card button {
    line-height: 28px;
  }

  span.edite svg {
    width: 20px;
    height: 20px;
  }

  .Website-ah-card span h2 {
    font-size: 18px;
  }

  .Website-ah-card span h3 {
    font-size: 18px;
  }

  .Website-ah-card button svg {
    width: 20px;
    height: 20px;
  }

  .Website-ah-card button {
    padding: 10px;
  }

  .Website-ah-box {
    gap: 20px;
    padding-top: 35px;
  }

  .Award-card-ah {
    align-items: flex-start;
  }

  .About-main-card-1-ah h2 span {
    font-size: 16px;
  }

  .Job-box-ah {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  .Job-card-ah {
    width: 100%;
  }

  .Job-list-1-ah span h2 {
    font-size: 18px;
  }

  .Job-list-2-ah {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  .ratings-ah-1-TITAL span h2 {
    font-size: 60px;
  }

  .ratings-ah-1-TITAL span p {
    font-size: 16px;
    padding-bottom: 5px;
  }

  .job-ah-post h2 {
    font-size: 20px;
  }

  .job-ah-Filter {
    width: 64%;
    gap: 25px;
  }

  .job-ah-Filter-card p {
    font-size: 16px;
  }

  .job-ah-Filter-card select {
    padding: 8px 10px;
    font-size: 15px;
    border-radius: 5px;
  }

  .director-urer img {
    width: 10%;
  }

  .director-urer {
    padding-bottom: 14px;
  }

  .Hire-ah-box h2 {
    font-size: 24px;
  }

  .Hire-ah-box p {
    font-size: 16px;
    padding: 18px 8px;
  }

  .Draft-ah {
    flex-direction: row;
    gap: inherit;
    justify-content: space-between;
  }

  .Cooper-ah-tital-head {
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
  }

  .Hire-ah-box h2 {
    font-size: 24px;
  }

  .Hire-ah-box p {
    font-size: 16px;
    padding: 18px 8px;
  }

  .Office-ah-logo {
    width: 20%;
  }

  .Office-ah-span p {
    padding: 0px;
  }

  .Current-ah-job-card span h3 {
    font-size: 12px;
  }

  .Skills-ah-box-main {
    padding-top: 14px;
    gap: 10px;
  }

  .Skills-ah-box p {
    padding: 10px 0px 4px 0px;
    line-height: 20px;
  }

  .Current-ah-job p {
    line-height: 20px;
  }

  .Education-side-ah h4 {
    line-height: 20px;
  }

  .Education-side-ah p {
    padding: 0px;
  }

  .Education-ah-boxing {
    gap: 25px;
  }

  .Hire-ah-box button {
    padding: 8px;
    font-size: 14px;
  }

  .Hire-ah-box button {
    padding: 8px;
    font-size: 14px;
  }

  .four-select-tab {
    margin: 25px 0px 0px 0px;
    padding: 0px 0px 20px 0px;
  }

  .verified-rating-box h6 {
    font-size: 14px;
  }

  .scrllo-bar p {
    padding: 0px;
    line-height: 24px;
  }

  .w3-light-grey.w3-tiny {
    height: 12px;
  }

  .About-main-card-1-ah h2 {
    font-size: 20px;
    line-height: 30px;
    padding: 0px 0px 0px 0px;
  }

  .Cooper-ah-tital-head span {
    color: #000;
    font-family: "Public Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  img {
    width: 76%;
  }

  .America-ah {
    width: 100%;
  }

  .Sweatshirt-ah {
    width: 100%;
  }

  .Sweatshirt-part-1 {
    width: 100%;
  }

  .Award-a {
    padding-top: 10px;
  }

  .Sweatshirt-part-2 {
    width: 100%;
  }

  .satisfied h2 {
    font-size: 30px;
    padding-top: 15px;
    text-align: left;
  }

  .satisfied h3 {
    text-align: left;
  }

  .satisfied p {
    text-align: left;
  }

  .ratings-ah-1-bar {
    gap: 0px;
  }

  .my-ah-class {
    padding: 25px 0px;
  }

  .satisfied button {
    font-size: 16px;
    line-height: 30px;
    padding: 9px;
  }

  .Popular-ti-ah-btn-box button {
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 8px;
  }

  .Popular-ti-ah-btn-box {
    gap: 12px;
  }

  .latest-rating-user-verified {
    flex-direction: column;
    gap: 18px;
  }

  .rating-user-box {
    width: 100%;
  }

  .verified-rating-box {
    width: 100%;
    justify-content: space-between;
  }

  .job-ah-post {
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
  }

  .job-ah-Filter {
    width: 100%;
  }

  .job-ah-post h2 {
    font-size: 18px;
    padding: 20px 0px 20px 0px;
  }


  .job-ah-post h2 span {
    font-size: 15px;
  }

  .review-news-director {
    margin: 35px 0px 25px 0px;
  }

  .review-news-director {
    margin: 25px 0px 20px 0px;
  }

  .review-news-director {
    margin: 35px 0px 25px 0px;
  }

  section.About-ah.extara {
    padding: 50px 0px 50px 0px;
  }

  .review-news-director {
    margin: 25px 0px 20px 0px;
  }

  .review-director-para p {
    font-size: 14px;
  }

  .racial-discrimination p {
    font-size: 14px;
  }

  .racial-discrimination h5 {
    font-size: 14px;
  }

  .like-report-txt ul li {
    font-size: 12px;
    padding: 4px 8px;
  }

  .like-report-submitted {
    margin: 12px 0px;
  }

  .latest-rating-comment-box {
    margin: 25px 0px 5px 0px;
  }

  .latest-comment-txt-input-box input {
    padding: 12px 40px 12px 18px;
    font-size: 16px;
  }

  .latest-rating-box {}

  .select-station-drop select {
    width: 75px;
    padding: 14.5px 0px 14.5px 12px;
    margin: 1.2px 0px 0px 0px;
    font-size: 12px !important;
  }

  .select-tv-icon {
    top: 4px;
    left: 25px;
  }

  .select-tv-icon svg {
    width: 20px;
  }

  .search-stations-input input::placeholder {
    font-size: 14px;
  }

  .search-stations-input input {
    width: 100%;
    padding: 12px 15px;
    margin: 1px 0px 0px 0px;
  }

  .search-stations-input svg {
    width: 24px;
    position: relative;
    top: 0px;
    right: -01px;
  }

  .first-banner-txt h3 {
    font-size: 35px;
    width: 100%;
    margin: 0px 0px 30px 0px !important;
  }

  .first-banner-txt a {
    font-size: 18px;
    margin: 40px 0px 0px 0px !important;
  }

  .first-banner-sec {
    padding: 50px 0px;
  }

  .latest-rating-txt h3 {
    font-size: 30px;
  }

  .news-director-current {
    gap: 10px;
    justify-content: start;
    align-items: flex-start;
    flex-direction: row;
  }

  .latest-rating-txt h3 {
    font-size: 18px;
  }

  .director-urer img {
    width: 20%;
  }

  .prod-abslt-ratems {
    width: 50px !important;
    height: 75px;
  }

  .ratemystation-prod-txt h3 {
    font-size: 20px;
  }

  .ratemystation-prod-txt h6 {
    font-size: 16px;
  }

  .ratemystation-prod-btn {
    justify-content: start;
    gap: 10px;
  }

  .prod-light-btn {
    font-size: 14px;
    padding: 12px 30px;
  }

  .prod-dark-btn {
    font-size: 14px;
    line-height: 19px;
    padding: 12px 30px;
  }

  .newsletter-pagination-slide .pagination a {
    padding: 5px 15px;
    font-size: 14px;
  }

  .journalism-img-txt {
    flex-direction: column;
  }

  .your-journalism-img {
    width: 100%;
    display: block;
    border-radius: 20px 20px 0px 0px;
  }

  .your-journalism-txt {
    width: 100%;
    padding: 20px 25px 45px 25px;
    border-radius: 0px 0px 20px 20px;
  }

  .newsletter-article-search {
    padding: 30px 0px 0px 0px;
  }

  .newsletter-article-search span {
    right: 10px;
    top: 55px;
  }


  .newsletter-article-search input {
    width: 100%;
  }

  .filter-short-box {
    justify-content: space-between;
  }

  .filter-box select {
    padding: 8px 6px;
    margin: 0px 0px 0px 0px;
    width: 120px;
  }

  .newsletter-likecomment-share {
    gap: 15px;
  }

  .newsletter-slide-article {
    flex-direction: column;
  }

  .newsletter-slide-article img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .newsletter-slide-article .newsletter-article-text {
    border-radius: 0px 0px 20px 20px;
    filter: none;
  }

  section.ratemystation-shop-sec h4 {
    font-size: 30px;
  }

  .newsletter-comment-reaction ul {
    gap: 10px;
  }

  .newsletter-detail-comment {
    gap: 10px;
    margin: 0px 0px 20px 0px;
  }

  .newsletter-detail-comment-para p {
    font-size: 12px !important;
    line-height: 20px !important;
    margin: 0px 0px 10px 0px !important;
  }

  .newsletter-comment-reaction ul li a {
    font-size: 12px;
  }

  .soul-witness-acc-subs a {
    font-size: 14px;
    line-height: 26px;
    padding: 4px 20px;
  }

  .soul-witness-txt h4 {
    font-size: 18px;
  }

  .article-reactions ul li a {
    font-size: 16px;
  }

  .soul-witness-txt h6 {
    font-size: 14px;
    margin: 0px;
  }

  .soul-witness-user-imgtxt img {
    width: 50px;
  }

  .more-soul-article {
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
  }

  .more-soul-articletxt h4 {
    font-size: 16px;
    line-height: 26px;
    padding: 0px 0px 10px 0px;
  }

  .more-from-soul-witness-box {
    padding: 30px 20px;
  }

  .job-start-matter-sec {
    padding: 50px 0px;
  }

  .assistant-director-txt h4 {
    font-size: 18px;
    line-height: 20px;
  }

  .assistant-city-dollar-time p {
    font-size: 11px;
  }

  .assistant-city-dollar-time {
    padding: 8px 10px;
    margin: 10px 0px 0px 0px;
  }

  .assistant-news-director-box ul li {
    font-size: 12px;
    line-height: 20px;
    margin: 0px 0px 10px 0px;
  }

  .actively-hiring-updated a {
    padding: 10px 15px;
    font-size: 14px;
  }

  .job-detailpg-nameflex {
    align-items: start;
    gap: 10px;
    flex-direction: column;
  }

  .job-detailpg-nameflex img {
    width: 100px;
  }

  .job-detail-name-description {
    padding: 30px 20px;
  }

  .job-select-opts {
    flex-direction: column;
    gap: 0px;
    align-items: start;
    margin: 10px 0px 0px 0px;
  }

  .job-save-share {
    margin: 10px 0px 0px 0px;
    gap: 10px;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .job-select-opts a {
    width: 100%;
  }

  .job-save-share a {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }

  .job-qualification-point {
    flex-direction: column;
  }

  .shop-new-arrival {
    padding: 30px 40px;
    margin: 0px 10px 10px 10px;
    height: auto;
  }

  .shop-new-arrival-img img {
    width: 150px;
  }

  .shop-new-arrival h6 {
    font-size: 18px;
    line-height: 30px;
  }

  .shop-new-arrival h3 {
    font-size: 28px;
    line-height: 30px;
    width: 100%;
  }

  .shop-new-arrival p {
    margin: 10px 0px 20px 0px !important;
    padding: 0px 100px 0px 0px;
    width: 100%;
  }

  .themed-cap {
    right: 0px;
    top: 40%;
  }

  .shop-friend-themed h4 {
    color: #000;
    font-family: "Public Sans", sans-serif;
    font-size: 18px;
    margin: 0px 0px 40px 0px !important;
  }

  .themed-cap img {
    width: 150px;
  }

  .themed-abslt-img {
    top: 40px;
  }

  .themed-abslt-img img {
    width: 150px;
  }



  .Education-logo {
    width: 50%;
  }

  .contact-follow-topline {
    padding: 20px 0px 0px 0px;
    border-top: 1px solid #e0e0e0;
    margin: 20px 0px 0px 0px;
  }

  .shop-category {
    gap: 20px;
    flex-direction: column;
  }

  .ratemystation-shop-prod {
    border-radius: 20px;
  }

  .rating-real-journalist {
    border-radius: 20px 20px 0px 0px;
    padding: 100px 50px;
  }

  .employer-inputfield input {
    padding: 10px 12px;
  }

  .employer-dont-have-acc {
    margin: 10px 0px 20px 40px;
  }

  .employer-register-btn {
    margin: 15px 0px 30px 30px;
  }

  .employer-login-form {
    padding: 0px 0px 20px 0px;
  }

  .rms-rating-review p {
    margin: 10px 0px 5px 0px !important;
  }


  .americon-prod-cart img {
    margin: 0px 0px 10px 0px;
  }

  .americon-prod-cart h3 {
    padding: 0px 0px 10px 0px;
  }

  .cart-counter {
    padding: 8px 12px;
    margin: 0px 0px 10px 0px;
  }

  .americon-prod-cart h6 {
    display: none;
  }

  .product-quality-total {
    margin: 10px 30px 20px 0px;
  }

  .mycart-qualitytotal {
    gap: 30px;
  }

  .job-details {
    width: 350px;
    left: 10px;
    top: 80px;
  }

  .job-detail p {
    font-size: 10px;
  }

  .review-news-director-second {
    margin: 55px 0px 0px 0px !important;
  }

  .select-box option {
    font-size: 14px !important;
  }

  .search-stations-input svg {
    top: 0px;
    right: 0px;
  }

  .like-report-submitted p {
    font-size: 12px;
  }

  .follow-us-rate ul li a svg {
    width: 20px;
  }

  .select-station-drop select {
    padding: 18px 0px 18px 0px;
  }

  .select-icon {
    width: 30px;
    height: 30px;
  }

  .contact-latest-updates {
    padding: 0px 25px 20px 0px;
  }

  .contact-follow-like-btn ul {
    justify-content: space-between;
  }

  section.About-ah {
    padding: 150px 0px 10px 0px;
  }

  .newsletter-long-txt {
    padding: 0px 25px 0px 25px;
  }

  .newsletter-article-detail .newsletter-article-text {
    padding: 30px 20px 30px 20px;
  }

  .contact-form-fields {
    padding: 0px;
  }

  .contact-follow-like-btn ul li {
    font-size: 14px;
    padding: 6px 12px;
  }

  .contact-follow-topline {
    padding: 20px 0px 0px 0px;
    border-top: 1px solid #e0e0e0;
    margin: 20px 0px 0px 0px;
  }

  .shop-category {
    gap: 20px;
    flex-direction: column;
  }

  .ratemystation-shop-prod {
    border-radius: 20px;
  }

  .filter-box {
    gap: 15px;
  }

  .employer-newroom-box {
    padding: 20px 10px 0px 10px;
  }

  .employer-inputfield input {
    padding: 10px 12px;
  }

  .employer-dont-have-acc {
    margin: 10px 0px 20px 40px;
  }

  .employer-register-btn {
    margin: 15px 0px 30px 30px;
  }

  .employer-login-form {
    padding: 0px 0px 20px 0px;
  }

  .rms-rating-review p {
    margin: 10px 0px 5px 0px !important;
  }

  .americon-prod-cart img {
    margin: 0px 0px 10px 0px;
  }

  .americon-prod-cart h3 {
    padding: 0px 0px 10px 0px;
  }

  .cart-counter {
    padding: 8px 12px;
    margin: 0px 0px 10px 0px;
  }

  .americon-prod-cart h6 {
    display: none;
  }

  .product-quality-total {
    margin: 10px 30px 20px 0px;
  }

  .mycart-qualitytotal {
    gap: 30px;
  }

  .job-details {
    width: 350px;
    left: 10px;
    top: 80px;
  }

  .job-detail p {
    font-size: 10px;
  }

  .review-news-director-second {
    margin: 25px 0px 0px 0px !important;
  }

  .select-box option {
    font-size: 14px !important;
  }

  .search-stations-input svg {
    top: 0px;
    right: 0px;
  }

  .search-stations-input.full-width input {
    width: 350px;
    padding: 15px 15px;
    height: 52px;
  }

  .like-report-submitted p {
    font-size: 12px;
  }

  .follow-us-rate ul li a svg {
    width: 20px;
  }

  .select-station-drop select {
    padding: 18px 0px 18px 0px;
  }

  .select-icon {
    width: 30px;
    height: 30px;
  }

  .contact-latest-updates {
    padding: 0px 25px 20px 0px;
  }

  .contact-follow-like-btn ul {
    justify-content: space-between;
  }

  section.About-ah {
    padding: 180px 0px 10px 0px;
  }

  .newsletter-long-txt {
    padding: 0px 25px 0px 25px;
  }

  .newsletter-article-detail .newsletter-article-text {
    padding: 30px 20px 30px 20px;
  }

  .setting_dashboard_tabs .react-tabs__tab-list .react-tabs__tab {
    padding: 6px 22px;
    margin: 10px 10px 10px 10px;
    border-radius: 10px;
  }

  .setting_dashboard_tabs .react-tabs__tab-list {
    border: none;
    text-align: center;
    display: contents;
  }

  .setting_dashboard_setting {
    padding: 21px 21px 27px 11px;
  }

  .setting_dashboard_setting p {
    font-size: 14px;
    line-height: 20px;
    padding: 0px 0px 0px 0px;
  }

  .store_checkbox {
    margin: 25px 0px 0px 0px;
  }

  .store_checkbox_box_txt h6 br {
    display: none;
  }

  .reported_dms_head h4 {
    font-size: 20px;
  }

  .reported_dms_chatsuggestbox h1 {
    font-size: 14px;
    padding: 10px 10px 10px 20px;
  }

  .reported_dms_chatman {
    padding: 20px 20px 20px 21px;
  }

  .reported_dms_mandata h4 {
    font-size: 18px;
  }

  .reported_dms_mandata p {
    font-size: 14px;
  }

  .reported_dms_manchat {
    gap: 15px;
    margin: 0px 0px 15px 0px;
  }

  .reported_dms_chatman img {
    width: 50px;
  }

  .reported_dms_chatman a {
    font-size: 14px;
    padding: 4px 0px;
  }

  .reported_dms_chatsuggestbox {
    margin: 0px 0px 20px 0px;
  }

  .chatmain_head_flex {
    padding: 20px 20px 18px 20px;
  }

  .reported_chat_userOne {
    gap: 10px;
  }

  .reported_chat_userOne {
    gap: 10px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .reported_chat_userOne h6 {
    font-size: 12px;
  }


  .username_chat_flex {
    gap: 10px;
  }

  .chatmain_head_flex {
    padding: 15px 15px 15px 15px;
  }

  .reported_chatting_box {
    padding: 47px 20px 18px 20px;
  }

  .chatting_mxg p {
    font-size: 12px;
    padding: 6px 17px;
  }

  .chatting_mxgme p {
    font-size: 12px;
    line-height: 23px;
    text-align: left;
  }

  .report_chat_btn {
    padding: 20px 20px 20px 20px;
    gap: 10px;
  }

  .report_chat_btn a {
    font-size: 14px;
    padding: 4px 0px;
  }

  .report_chat_btn select {
    font-size: 14px;
    padding: 4px 0px;
  }

  .reported_dms_mandata p span {
    font-size: 14px;
  }

  .reported_dashboard_chatbox {
    margin: 0px 0px 30px 0px;
  }

  .ignore_delete_btns .ignore_all {
    padding: 6px 20px;
  }

  .dashfilter-stock {
    width: 100%;
  }

  .dashfilter-select-box {
    margin: 20px 0px 0px 0px;
    width: 100%;
  }

  .dashfilter-select-box select {
    width: 100%;
  }

  .dashnewsletter_box {
    align-items: start;
    gap: 28px;
    flex-direction: column;
  }

  .dashnewsletter_box img {
    width: 120px;
    height: 120px;
  }

  .dashboard_newsletter_txt h6 {
    font-size: 14px;
  }

  .dashboard_newsletter_txt h4 {
    font-size: 20px;
    line-height: 29px;
  }

  .dashboard_newletter_box {
    padding: 20px 20px 20px 20px;
  }

  .job--open--dash--news--h--filter--main {
    align-items: flex-start !important;
  }


  .billing_payment_number h2 {
    font-size: 22px;
    padding: 0px 0px 8px 0px;
  }

  .billing_payment_number h6 {
    font-size: 12px;
  }

  .billing_payment_svf svg {
    width: 40px;
  }

  .billing_earning_box h5 {
    font-size: 20px;
    padding: 0px 0px 12px 0px;
  }

  .billing_earning_box {
    padding: 20px 20px 20px 20px;
  }

  .billing_earning_box a {
    font-size: 14px;
    margin: 28px 0px 0px 0px !important;
    padding: 3px 0px;
  }


  .blldash_billing_transfer_box {
    padding: 20px 20px 20px 20px;
    margin: 0px 0px 16px 0px;
    gap: 12px;
    align-items: center;
  }


  .checkbox-wrapper {
    width: 20px;
    height: 20px;
  }

  .billdash_billingtransfer_name span svg {
    width: 30px;
  }

  .billdash_billingtransfer_name h4 {
    font-size: 14px;
  }

  .billdash_payment_namewedit a {
    font-size: 12px;
  }

  .billdash_billingtransfer_name img {
    width: 100px;
  }

  .addnewprod_head {
    width: 60%;
  }


  .newsletter_filter_flex {
    justify-content: start;
    gap: 8px;
    margin: 20px 0px 0px 0px;
  }

  .ignore_delete_btns .delete_all {
    padding: 6px 20px;
  }

  .dashnewsletter_box {
    gap: 15px;
  }

  .dashnewsletter_box .newsletter-writtinsoul-txt p {
    font-size: 14px;
  }

  .dashnewsletter_box .newsletter-likecomment-share {
    display: flex;
    gap: 5px;
  }

  .dashnewsletter_box img {
    width: 161px;
    height: 161px;
  }

  .addnewprod_head a {
    width: 100%;
    display: inline-block;
    font-size: 14px;
    padding: 2px 10px 2px 10px;
  }

  .dashprod_filter_box {
    flex-direction: column;
  }


  .dashfilter-stock {
    width: 100%;
    flex-direction: column;
  }

  .dashstock_box {
    width: 100%;
  }


  .dashstock_box select {
    width: 100%;
  }

  .about-creator-img-name {
    right: 50px;
  }

  .about-creator-img-name h6 {
    font-size: 12px;
    margin: 0px 0px 5px 0px;
  }

  .ratemystaion-contact-form p {
    margin: 0px 0px 30px 0px !important;
  }

  .ratemystaion-contact-form {
    padding: 10px;
  }

  .contact-follow-like-btn ul {
    gap: 8px;
  }

  .contact-form-btn button {
    padding: 4px 45px;
  }

  .contact-latest-updates p {
    font-size: 14px;
  }

  .main-footer {
    flex-direction: column;
    gap: 10px;
  }


  .filter-box {
    flex-wrap: nowrap;
  }

  .find-job-select-box input {
    margin: 0px 0px 10px 0px;
  }

  .find-job-select-box span {
    top: 9px;
  }

  .employer-newroom-box button {
    padding: 20px 30px !important;
    border-bottom: 5px solid #f2f2f2;
  }

  .employer-newroom-box button.text-white {
    padding: 20px 30px !important;
    border-bottom: 5px solid #89c400;
  }

  .assistant-director-txt h6 {
    margin: 0px;
  }

  .assistant-logo-txt {
    gap: 10px;
  }

  .assistant-director-logo img {
    width: 60px;
  }

  .experience--desc--h {
    max-height: 125px;
  }

  .info--close--h, .experience--close--h, .about--close--h {
    top: 5%;
    right: 7%;
  }

  .latest-rating-txt h3 {
    margin: 0px 0px 20px 0px !important;
  }

  .newroom-coworker-txt svg {
    width: 170px;
  }

  .search-stations-input span {
    top: 2px;
    right: 20px;
  }

  .latest-commentor-img img {
    width: 50px;
  }

  .select-container {
    padding: 10px 10px 10px 20px;
  }

  .selected-option {
    font-size: 12px !important;
    top: 0px !important;
  }

  span.selected-option {
    top: 8px !important;
    margin-top: 0px;
    margin-left: 4px;
  }


  .options-list {
    top: 60px;
    width: 180px;
  }

  .options-list .select-option svg {
    width: 25px;
    height: 25px;
  }

  .options-list li.select-option {
    font-size: 16px;
    padding: 15px 20px;
  }

  .looking-for-worktxt p {
    width: 80%;
  }

  .contact-form-station {
    padding: 20px 20px 42px 20px;
  }

  .faq--main--h .faq--main--search--h {
    padding: 14px 18px;
  }

  .faq--main--search--icon--alt--h {
    right: 8%;
  }


  .faq--main--h .faq--main--search--h {
    padding: 14px 28px !important;
  }

  .faq--main--search--icon--alt--h {
    right: 9% !important;
    top: 20% !important;
  }


  .articles-writer-sec .newsletter-article-search {
    padding: 51px 0px 0px 0px;
  }

  .articles-writer-sec .filter-short-box {
    padding: 40px 0px 42px 0px;
  }

  .newsletter-article-text {
    padding: 35px 27px 31px 30px;
  }

  .newsletter-side-head {
    margin: 36px 0px 36px 12px;
  }

  .about-creator-img img {
    width: 100%;
  }

  .about-creator-img-name::before {
    height: 72px;
  }

  .ratings-ah-1 {
    width: 100%;
    flex-direction: column;
    padding-top: 10px;
  }

  .ratings-ah-1-TITAL {
    width: 100%;
  }

  .Popular-ti-ah {
    padding: 10px 0px 20px 0px;
  }

  .ratings-ah-1-TITAL span {
    margin: 0px 0px 10px 0px;
  }

  .ratings-ah-1-TITAL h3 {
    padding: 0px 0px 20px 0px;
  }

  .job-ah-Filter {
    padding: 0px 0px 10px 0px;
  }

  .navtabs ul {
    flex-wrap: wrap;
  }

  .gol-bar-head {
    width: 100%;
    text-align: center;
    padding: 10px 0px 10px 0px;
  }

  .gol-bar-ah {
    width: 100%;
    flex-direction: column;
  }

  .KABC-logo-ah button {
    padding: 6px 10px;
  }


  .job-detailpg-txt h3 {
    font-size: 27px;
  }


  .americon-prod-cart {
    flex-wrap: wrap;
  }

  .newsletter-article-decided h4 {
    font-size: 20px;
  }

  .Employees-Search {
    width: 100%;
  }


}